import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { FetchError, IssueDto, queryKeys, useAccessToken } from "../../..";
import { configuration } from "../../../../configuration";

type Variables = {
  guid: string;
  issueGuid: string;
};

const useDeleteProposalRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function deleteProposalRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();

    const response = await fetch(`${configuration.apiRootUrl}/proposals/${variables.guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "delete",
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { deleteProposalRequest };
};

const useDeleteProposal = () => {
  const { deleteProposalRequest } = useDeleteProposalRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteProposalRequest,
    onMutate: async (variables) => {
      const cachedIssueDetailsData = optimisticallyUpdateProposalsData(queryClient, variables);

      return { cachedIssueDetailsData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.issues.getIssueDetail(variables.issueGuid), context?.cachedIssueDetailsData);
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.issues.getIssueDetail(variables.issueGuid));
      queryClient.invalidateQueries(queryKeys.issues.getIssueFeed(variables.issueGuid));
    },
  });
};

async function optimisticallyUpdateProposalsData(queryClient: QueryClient, variables: Variables) {
  await queryClient.cancelQueries(queryKeys.issues.getIssueDetail(variables.issueGuid));
  const cachedData = queryClient.getQueryData<IssueDto>(queryKeys.issues.getIssueDetail(variables.issueGuid));

  if (cachedData) {
    const optimisticallyUpdatedData: IssueDto = {
      ...cachedData,
      proposals: cachedData.proposals.filter((proposal) => proposal.guid !== variables.guid),
    };

    queryClient.setQueryData(queryKeys.issues.getIssueDetail(variables.issueGuid), optimisticallyUpdatedData);
    return cachedData;
  }
}

export { useDeleteProposal };
