import { configuration } from "../configuration";
import { GetUsersDto, HttpResponse } from "./types";
import { useAccessToken } from "./use-access-token";

const useHttp = () => {
  const { getAccessToken } = useAccessToken();

  async function parseResponse<T>(response: Response): Promise<HttpResponse<T>> {
    let result: HttpResponse<T> = {
      ok: response.ok,
      status: response.status,
      statusText: response.statusText,
    };

    try {
      result.data = await response.json();
    } catch {
      result.data = undefined;
    }

    return result;
  }

  async function getUsersRequest(): Promise<HttpResponse<GetUsersDto>> {
    const accessToken = await getAccessToken();

    var response = await fetch(`${configuration.apiRootUrl}/users`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return parseResponse(response);
  }

  return {
    getUsersRequest,
  };
};

export { useHttp };
