import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { FetchError, IssueDto, queryKeys, useAccessToken } from "../../..";
import { configuration } from "../../../../configuration";

type Variables = {
  guid: string;
  issueGuid: string;
  userId: string;
};

const useRemoveProposalUpvoteRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editProposalStatusRequest(variables: Variables) {
    const accessToken = await getAccessToken();

    const response = await fetch(`${configuration.apiRootUrl}/proposals/${variables.guid}/upvotes`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "DELETE",
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editProposalStatusRequest };
};

const useRemoveProposalUpvote = () => {
  const { editProposalStatusRequest } = useRemoveProposalUpvoteRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editProposalStatusRequest,
    onMutate: async (variables) => {
      const cachedIssueDetailsData = await optimisticallyUpdateIssueData(queryClient, variables);

      return {
        cachedIssueDetailsData,
      };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.issues.getIssueDetail(variables.issueGuid), context?.cachedIssueDetailsData);
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.issues.getIssueDetail(variables.issueGuid));
    },
  });
};

async function optimisticallyUpdateIssueData(queryClient: QueryClient, variables: Variables) {
  await queryClient.cancelQueries(queryKeys.issues.getIssueDetail(variables.issueGuid));
  const cachedData = queryClient.getQueryData<IssueDto>(queryKeys.issues.getIssueDetail(variables.issueGuid));

  if (cachedData) {
    const optimisticallyUpdatedData: IssueDto = {
      ...cachedData,
      proposals: cachedData.proposals.map((proposal) => {
        if (proposal.guid === variables.guid) {
          return {
            ...proposal,
            upvotes: proposal.upvotes.filter((x) => x.userId !== variables.userId),
          };
        }

        return proposal;
      }),
    };

    queryClient.setQueryData(queryKeys.issues.getIssueDetail(variables.issueGuid), optimisticallyUpdatedData);
    return cachedData;
  }
}

export { useRemoveProposalUpvote };
