import { QueryFunctionContext, useQuery, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetPlanDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetPlanRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getPlanRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.plans.getPlan>>): Promise<GetPlanDto> {
    const accessToken = await getAccessToken();
    const [{ guid, date, offsetInMinutes }] = queryKey;

    const url = new URL(`${configuration.apiRootUrl}/plans/${guid}`);

    if (date) {
      url.searchParams.append("date", date);
    }

    if (offsetInMinutes) {
      url.searchParams.append("offsetInMinutes", offsetInMinutes.toString());
    }

    const response = await fetch(url.href, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getPlanRequest };
};

const useGetPlan = (guid: string, date?: string, offsetInMinutes?: number) => {
  const { getPlanRequest } = useGetPlanRequest();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: queryKeys.plans.getPlan(guid, date, offsetInMinutes),
    queryFn: getPlanRequest,
    onSuccess: (data) => {
      data.blocks
        .flatMap((x) => x.sections.flatMap((y) => y.actionItems))
        .forEach((actionItem) => {
          queryClient.setQueryData(queryKeys.actionItems.getActionItemSummary(actionItem.guid), actionItem);
        });
    },
  });
};

export { useGetPlan };
