enum SignalRClientMethod {
  ReceiveChatMessage = "ReceiveChatMessage",
  ShouldRefetchActionItem = "ShouldRefetchActionItem",
  ShouldRefetchComments = "ShouldRefetchComments",
  ShouldRefetchTeamIssues = "ShouldRefetchTeamIssues",
}

enum SignalRServerMethod {
  JoinGroup = "JoinGroup",
  JoinGroups = "JoinGroups",
  LeaveGroup = "LeaveGroup",
  LeaveGroups = "LeaveGroups",
  SendChatMessage = "SendChatMessage",
  SendRefetchActionItem = "SendRefetchActionItem",
  SendRefetchComments = "SendRefetchComments",
  SendRefetchTeamIssues = "SendRefetchTeamIssues",
}

const SignalRGroup = {
  ActionItem: (actionItemGuid: string) => `ActionItem-${actionItemGuid}`,
  CommentThread: (threadGuid: string) => `CommentThread-${threadGuid}`,
  TeamIssues: (teamSlug: string) => `TeamIssues-${teamSlug}`,
};

export { SignalRClientMethod, SignalRGroup, SignalRServerMethod };
