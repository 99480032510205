import React from "react";

interface ISignalRCommentsContext {
  sendRefetchComments: (threadGuid: string) => void;
  onShouldRefetchComments: (handler: (threadGuid: string) => void) => void;
  offShouldRefetchComments: (handler: (threadGuid: string) => void) => void;
}

const SignalRCommentsContext = React.createContext<ISignalRCommentsContext>({
  sendRefetchComments: () => null,
  onShouldRefetchComments: () => null,
  offShouldRefetchComments: () => null,
});

export { SignalRCommentsContext };
