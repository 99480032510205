import { Groups } from "@mui/icons-material";
import { Link, TextField } from "@mui/material";
import { useState } from "react";
import { Links } from "../../constants";
import { useSession } from "../../hooks";
import { IssueDto, useEditIssue, useGetTeams } from "../../http";
import { ITeamSummary } from "../../models";
import { Autocomplete } from "../Autocomplete";
import { ItemProperty } from "../ItemProperty";

interface IProps {
  issue: IssueDto;
  onTeamChanged?: (team: ITeamSummary) => void;
}

const IssueTeamProperty = (props: IProps) => {
  const { userId } = useSession();
  const { isLoading: isLoadingTeams, data: teams } = useGetTeams();
  const { mutateAsync, isLoading: isEditingIssue } = useEditIssue();
  const [isEditing, setIsEditing] = useState(false);

  async function handleTeamChanged(teamSlug: string | null) {
    const newTeam = teams?.find((x) => x.slug === teamSlug);

    if (newTeam) {
      await mutateAsync({
        guid: props.issue.guid,
        oldTeamSlug: props.issue.assignedTeam.slug,
        newTeamSlug: newTeam.slug,
        newTeamName: newTeam.name,
        description: props.issue.description ?? "",
        createdByUserId: props.issue.createdByUser.userId,
        modifiedByUserId: userId,
      });

      setIsEditing(false);
      props.onTeamChanged?.(newTeam);
    }
  }

  return (
    <ItemProperty
      labelIcon={<Groups />}
      label="Team"
      canEdit={props.issue.userCanEdit}
      isEditing={isEditing}
      onEditClicked={() => setIsEditing(true)}
    >
      {isEditing ? (
        <Autocomplete
          isLoading={isLoadingTeams}
          disabled={isLoadingTeams || isEditingIssue}
          options={teams?.map((x) => x.slug) ?? []}
          getOptionLabel={(option) => teams?.find((x) => x.slug === option)?.name ?? ""}
          onChange={(_, option) => handleTeamChanged(option)}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select a team..." inputProps={{ ...params.inputProps }} />
          )}
          value={props.issue.assignedTeam.slug}
          disableClearable
        />
      ) : (
        <Link
          noWrap
          onClick={(e) => e.stopPropagation()}
          href={Links.TeamImplementation(props.issue.assignedTeam.slug)}
        >
          {props.issue.assignedTeam.name}
        </Link>
      )}
    </ItemProperty>
  );
};

export { IssueTeamProperty };
