import { SendRounded } from "@mui/icons-material";
import { Chip, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { Comment, HeaderLayout } from "../components";
import { CommentType } from "../constants";
import { useSession, useSignalR } from "../hooks";

interface IChatMessage {
  authorName: string;
  authorUserId: string;
  authorProfilePhotoUrl: string;
  message: string;
  timestampUtc: DateTime;
}

const ChatPage = () => {
  const { displayName, userId, profilePhotoSmallUrl } = useSession();
  const [message, setMessage] = useState("");
  const inputRef = useRef<HTMLInputElement>();
  const [chatLog, setChatLog] = useState<IChatMessage[]>([]);
  const signalR = useSignalR();

  useEffect(() => {
    inputRef.current?.focus();

    const handleReceiveChatMessage = (
      authorName: string,
      authorUserId: string,
      authorProfilePhotoUrl: string,
      message: string
    ) => {
      console.log(`Received ${message} from ${authorName}`);
      setChatLog([
        {
          authorName,
          authorUserId,
          authorProfilePhotoUrl,
          message,
          timestampUtc: DateTime.utc(),
        },
        ...chatLog,
      ]);
    };

    signalR.onReceiveChatMessage(handleReceiveChatMessage);
    return () => signalR.offReceiveChatMessage(handleReceiveChatMessage);
  }, [chatLog, signalR]);

  function handleKeyDown(e: React.KeyboardEvent) {
    if (e.key === "Enter") {
      sendMessage();
    }
  }

  function sendMessage() {
    signalR.sendChatMessage(displayName, userId, profilePhotoSmallUrl, message);
    setMessage("");
  }

  return (
    <HeaderLayout
      title={
        <Grid container alignItems="center">
          <Grid item xs={4} md={3}>
            <Stack direction="row" spacing={0.25}>
              <Typography variant="h3">Chat</Typography>
              <Chip label="Beta" />
            </Stack>
          </Grid>
          <Grid item xs={8} md={6}>
            <TextField
              inputRef={inputRef}
              placeholder="Send message"
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconButton onClick={sendMessage}>
                    <SendRounded />
                  </IconButton>
                ),
              }}
              onChange={(e) => setMessage(e.currentTarget.value)}
              onKeyDown={handleKeyDown}
              value={message}
            />
          </Grid>
        </Grid>
      }
    >
      <Grid container sx={{ pr: 1, pl: { xs: 0, md: 1 }, pb: 1 }}>
        <Grid item xs={0} md={3} />
        <Grid item xs={12} md={6}>
          <Stack spacing={1}>
            {chatLog.map((x, i) => (
              <Comment
                key={i}
                comment={{
                  type: CommentType.HistoryLedgerItem,
                  user: {
                    displayName: x.authorName,
                    userId: x.authorUserId,
                    profilePhotoSmallUrl: x.authorProfilePhotoUrl,
                    enabled: true,
                  },
                  timestampUtc: x.timestampUtc,
                  lastModifiedUtc: x.timestampUtc,
                  text: <Typography>{x.message}</Typography>,
                }}
              />
            ))}
          </Stack>
        </Grid>
      </Grid>
    </HeaderLayout>
  );
};

export { ChatPage };
