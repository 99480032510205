import { CheckCircleRounded, ErrorRounded, ExpandMoreRounded, InfoRounded, WarningRounded } from "@mui/icons-material";
import { alpha, createTheme, lighten, LinkProps } from "@mui/material";
import { LinkBehavior } from "../../components";

declare module "@mui/material/styles" {
  interface Palette {
    turquoise: SimplePaletteColorOptions;
    orange: SimplePaletteColorOptions;
    wine: SimplePaletteColorOptions;
    diff: DiffPaletteOptions;
  }

  interface PaletteOptions {
    turquoise: SimplePaletteColorOptions;
    orange: SimplePaletteColorOptions;
    wine: SimplePaletteColorOptions;
    diff: DiffPaletteOptions;
  }

  interface PaletteColor {
    opacity5?: string;
    transparent5?: string;
    opacity10?: string;
    transparent10?: string;
    transparent15?: string;
  }

  interface SimplePaletteColorOptions {
    opacity5?: string;
    transparent5?: string;
    opacity10?: string;
    transparent10?: string;
    transparent15?: string;
  }

  interface TypeBackground {
    sideMenu: string;
  }

  interface DiffPaletteOptions {
    added: string;
    removed: string;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    tertiary: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    orange: true;
    wine: true;
  }
}

declare module "@mui/material/Divider" {
  interface DividerPropsVariantOverrides {
    dashed: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonOwnProps {
    variant?: "filled";
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    placeholder: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    placeholder?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    placeholder: true;
  }
}

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    faded: true;
  }
}

const teal = {
  100: "#198590",
  5: "#1985900d",
  10: "#1985901A",
  105: "#ecf2f2",
  110: "#e2edee",
  15: "#19859026",
};

const turquoise = {
  100: "#26C9DA",
};

const wine = {
  100: "#7D1911",
  200: "#5A0C2E",
};

const orange = {
  100: "#F2994A",
};

const grey = {
  105: "#F2F2F2",
  100: "#F8F8F8",
  200: "#D9D9D9",
  300: "#828282",
  400: "#4D4D4D",
};

let theme = createTheme({
  spacing: 20,
  palette: {
    background: {
      default: grey[100],
      sideMenu: grey[105],
    },
    text: {
      primary: grey[400],
    },
    primary: {
      main: teal[100],
      opacity5: teal[105],
      transparent5: teal[5],
      opacity10: teal[110],
      transparent10: teal[10],
      transparent15: teal[15],
    },
    secondary: {
      main: wine[100],
    },
    error: {
      main: "#BB0000",
    },
    turquoise: {
      main: turquoise[100],
    },
    orange: {
      main: orange[100],
      contrastText: "#fff",
    },
    wine: {
      main: wine[100],
      dark: wine[200],
      contrastText: "#fff",
    },
    grey: {
      100: grey[100],
      200: grey[200],
      300: grey[300],
      400: grey[400],
    },
    diff: {
      added: "#e3f1de",
      removed: "#fbdfdf",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ".ql-editor ol, .ql-editor ul": {
          paddingLeft: 0,
        },
        ".ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl)": {
          paddingLeft: 0,
        },
        ".ql-editor li::before": {
          width: 15,
        },
        ".ql-editor ol li:not(.ql-direction-rtl)::before, .ql-editor ul li:not(.ql-direction-rtl)::before": {
          marginLeft: 0,
          marginRight: 5,
          textAlign: "left",
        },
        ".ql-snow .ql-editor a, .ql-editor a": {
          color: teal[100],
          textUnderlineOffset: 3,
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: teal[5],
          borderRadius: 8,
          boxShadow: "none",
          "&::before": {
            display: "none",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(0, 1),
          [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0, 0.5),
          },
        }),
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: 0,
          paddingBottom: theme.spacing(1),
        }),
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          error: <ErrorRounded />,
          warning: <WarningRounded />,
          info: <InfoRounded />,
          success: <CheckCircleRounded />,
        },
      },
      styleOverrides: {
        root: {
          alignItems: "center",
        },
        message: {
          fontWeight: 400,
        },
        standardError: ({ theme }) => ({
          backgroundColor: lighten(theme.palette.error.light, 0.8),
        }),
        standardWarning: ({ theme }) => ({
          backgroundColor: lighten(theme.palette.warning.light, 0.8),
        }),
        standardInfo: ({ theme }) => ({
          backgroundColor: lighten(theme.palette.info.light, 0.8),
        }),
        standardSuccess: ({ theme }) => ({
          backgroundColor: lighten(theme.palette.success.light, 0.8),
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          ".MuiAutocomplete-inputRoot": {
            paddingTop: "10px !important",
            paddingLeft: "10px !important",
            paddingBottom: "10px !important",
          },
          ".MuiAutocomplete-input": {
            padding: "0 !important",
          },
          ".MuiFormLabel-root": {
            top: -6,
            "&.MuiInputLabel-shrink": {
              top: 0,
            },
          },
          ".MuiAutocomplete-endAdornment": {
            right: 10,
            top: "unset",
          },
        },
        paper: {
          margin: "5px 0px",
          paddingLeft: 10,
          borderRadius: 10,
        },
        listbox: {
          padding: 0,
          ul: {
            marginBlockEnd: 0,
          },
        },
        option: {
          marginRight: 10,
          borderRadius: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          fontSize: "0.875rem",
          fontWeight: "400",
          textTransform: "uppercase",
        },
      },
      defaultProps: {
        disableElevation: true,
      },
      variants: [
        {
          props: { variant: "text" },
          style: {
            color: teal[100],
            textUnderlineOffset: "3px",
            "&:hover": {
              backgroundColor: "transparent",
              textDecoration: "underline",
            },
          },
        },
        {
          props: { variant: "tertiary" },
          style: {
            color: teal[100],
            backgroundColor: teal[5],
            padding: "3px 12px",
            "&:hover": {
              backgroundColor: teal[15],
            },
          },
        },
        {
          props: { variant: "tertiary", color: "error" },
          style: ({ theme }) => ({
            color: theme.palette.error.main,
            backgroundColor: alpha(theme.palette.error.main, 0.05),
            "&:hover": {
              backgroundColor: alpha(theme.palette.error.main, 0.15),
            },
          }),
        },
        {
          props: { variant: "tertiary", size: "small" },
          style: {
            fontSize: "0.625rem",
            borderRadius: 4,
            padding: "3px 4px",
          },
        },
        {
          props: { variant: "contained" },
          style: {},
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.1)",
        },
      },
      variants: [
        {
          props: { elevation: 0 },
          style: {
            boxShadow: "none",
            backgroundColor: teal[10],
          },
        },
        {
          props: { variant: "faded" },
          style: {
            boxShadow: "none",
            backgroundColor: "#f6f6f6",
            borderColor: "#e0e0e0",
            borderWidth: "1px",
            borderStyle: "solid",
          },
        },
      ],
    },
    MuiCardHeader: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(0.5, 1),
          [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0.5),
          },
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(1),
            ":last-child": {
              paddingBottom: theme.spacing(1),
            },
          },
          [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0.5),
            ":last-child": {
              paddingBottom: theme.spacing(0.5),
            },
          },
        }),
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "20px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: 20,
          borderRadius: 5,
          width: "fit-content",
        },
        label: {
          fontSize: ".625rem",
          fontWeight: "400",
          textTransform: "uppercase",
          paddingLeft: 10,
          paddingRight: 10,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: alpha("#000", 0.3),
        },
      },
      variants: [
        {
          props: { variant: "dashed" },
          style: {
            borderStyle: "dashed",
          },
        },
      ],
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        },
        paper: ({ theme }) => ({
          borderRadius: 10,

          [theme.breakpoints.up("md")]: {
            width: 790,
            maxWidth: "none",
          },

          overflow: "visible",
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid #c9c9c9",
          marginBottom: 20,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          gap: 20,
          padding: "0 20px 20px 20px",
          ".MuiButton-root": {
            margin: 0,
          },
        },
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: "filled" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            borderRadius: "50%",
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          }),
        },
      ],
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
        underline: "hover",
      } as LinkProps,
      styleOverrides: {
        root: {
          textUnderlineOffset: 3,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingRight: 8,
          paddingLeft: 8,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          marginTop: 10,
          borderRadius: 15,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: 5,
        },
        divider: {
          borderStyle: "dashed",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: "8px",
          borderColor: alpha(grey[400], 0.3),
          borderWidth: "0.5px",
          "&:hover:enabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "#000",
            borderWidth: "1px",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: turquoise[100],
            borderWidth: "2px",
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.error.main,
            borderWidth: "2px",
          },
        }),
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          ".MuiPaper-root": {
            borderRadius: 10,
            border: "1px solid rgba(0,0,0,0.1)",
            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: (props) => <ExpandMoreRounded fontSize="large" {...props} />,
      },
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          borderRadius: "10px",
          "&.Mui-focused .MuiSelect-filled": {
            backgroundColor: teal[110],
          },
          "&:not(.Mui-disabled):hover .MuiSelect-filled": {
            backgroundColor: teal[110],
          },
        },
        select: {
          "&.MuiSelect-filled": {
            borderRadius: "10px",
            backgroundColor: "transparent",
            padding: "4px 8px",
            paddingRight: "32px",
          },
          padding: 10,
        },
        icon: {
          color: grey[400],
        },
      },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: "wave",
        height: 40,
      },
      styleOverrides: {
        text: {
          borderRadius: 10,
        },
        rectangular: {
          borderRadius: 10,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          ".MuiSvgIcon-root": {
            fontSize: 24,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 28,
          height: 16,
          padding: 0,
          display: "flex",
          margin: "auto",
          "&:active": {
            "& .MuiSwitch-thumb": {
              width: 15,
            },
            "& .MuiSwitch-switchBase.Mui-checked": {
              transform: "translateX(9px)",
            },
          },
        },
        thumb: {
          boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
          width: 12,
          height: 12,
          borderRadius: 6,
          transition: "width 200ms",
        },
        track: {
          borderRadius: 16 / 2,
          opacity: 1,
          backgroundColor: "rgba(0,0,0,.25)",
        },
        switchBase: {
          padding: 2,
          "&.Mui-checked": {
            transform: "translateX(12px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
              opacity: 1,
              backgroundColor: teal[100],
            },
          },
          "& .Mui-checked": {
            transform: "translateX(9px)",
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "inherit",
        },
        fontSizeSmall: {
          fontSize: 14,
        },
        fontSizeMedium: {
          fontSize: 18,
        },
        fontSizeLarge: {
          fontSize: 24,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "42px",
          height: "42px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: "42px",
          height: "42px",
          padding: theme.spacing(0.75, 1),
          fontSize: "0.75rem",
          fontWeight: "400",
        }),
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          ".MuiTableCell-root": {
            borderColor: grey[400],
            paddingTop: 0,
            paddingBottom: "5px",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingTop: "10px",
          paddingBottom: "10px",
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
          borderRadius: 8,
          width: "100%",
          ".MuiInputBase-root": {
            padding: "10px",
          },
          ".MuiFormHelperText-root": {
            marginLeft: 0,
          },
          ".MuiInputBase-input": {
            padding: 0,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          color: grey[200],
          "&.Mui-disabled": {
            backgroundColor: grey[100],
            "&.Mui-selected": {
              color: grey[300],
              backgroundColor: grey[200],
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          maxWidth: "500px",
          borderRadius: 10,
          padding: 10,
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          border: "1px solid rgba(0, 0, 0, 0.1)",
          boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
        }),
        arrow: {
          color: "#fff",
          "&::before": {
            border: "1px solid rgba(0, 0, 0, 0.2)",
          },
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: ['"Helvetica Neue"', '"Open Sans"', "sans-serif"].join(","),
  },
});

theme = createTheme(theme, {
  typography: {
    h1: {
      fontSize: "3rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "2.4rem",
      },
      fontWeight: "400",
      lineHeight: "3.5rem",
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: "400",
      lineHeight: "3rem",
    },
    h3: {
      fontSize: "2rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
      fontWeight: "700",
      lineHeight: "2.5rem",
    },
    h4: {
      fontSize: "1.5rem",
      lineHeight: "1.75rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
        lineHeight: "1.5rem",
      },
      fontWeight: "700",
    },
    h5: {
      fontSize: "1.25rem",
      lineHeight: "1.75rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
        lineHeight: "1.5rem",
      },
      fontWeight: "700",
    },
    h6: {
      fontSize: "1.25rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
      color: grey[400],
      fontWeight: "400",
      lineHeight: "1.75rem",
    },
    body2: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.875rem",
      },
      fontWeight: "700",
    },
    body1: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.875rem",
      },
      fontWeight: "400",
    },
    button: {
      fontSize: "0.75rem",
      fontWeight: "600",
      textTransform: "uppercase",
      color: teal[100],
    },
    caption: {
      fontSize: "0.75rem",
      color: grey[300],
      fontWeight: "400",
      lineHeight: "1.5rem",
    },
    overline: {
      fontSize: "0.75rem",
      fontWeight: "400",
      textTransform: "uppercase",
      lineHeight: "1rem",
    },
    placeholder: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.875rem",
        lineHeight: "1rem",
      },
      color: alpha("#000000", 0.3),
      fontWeight: "400",
    },
  },
});

export { theme };
