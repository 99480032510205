import { useContext } from "react";
import { SessionContext } from "../components";

interface ISafeSessionContext {
  isSessionLoaded: boolean;
  displayName: string;
  planGuid: string;
  profilePhotoSmallUrl: string;
  profilePhotoLargeUrl: string;
  userId: string;
  endSession: () => void;
}

const useSession = () => {
  const unsafeSessionContext = useContext(SessionContext);

  if (unsafeSessionContext.isSessionLoaded && unsafeSessionContext.user?.displayName === undefined) {
    throw new Error("Session displayName not initialized.");
  }

  if (unsafeSessionContext.isSessionLoaded && unsafeSessionContext.user?.profilePhotoSmallUrl === undefined) {
    throw new Error("Session profilePhotoSmallUrl not initialized.");
  }

  if (unsafeSessionContext.isSessionLoaded && unsafeSessionContext.user?.profilePhotoLargeUrl === undefined) {
    throw new Error("Session profilePhotoLargeUrl not initialized.");
  }

  if (unsafeSessionContext.isSessionLoaded && unsafeSessionContext.user?.userId === undefined) {
    throw new Error("Session userId not initialized.");
  }

  if (unsafeSessionContext.isSessionLoaded && unsafeSessionContext.user?.planGuid === undefined) {
    unsafeSessionContext.endSession();
  }

  const safeSessionContext: ISafeSessionContext = {
    isSessionLoaded: unsafeSessionContext.isSessionLoaded,
    displayName: unsafeSessionContext.user!.displayName!,
    profilePhotoSmallUrl: unsafeSessionContext.user!.profilePhotoSmallUrl!,
    profilePhotoLargeUrl: unsafeSessionContext.user!.profilePhotoLargeUrl!,
    userId: unsafeSessionContext.user!.userId!,
    planGuid: unsafeSessionContext.user!.planGuid!,
    endSession: unsafeSessionContext.endSession,
  };

  return safeSessionContext;
};

export { useSession };
