import React, { useContext, useEffect } from "react";
import { Outlet, useParams } from "react-router";
import { HeaderLayout } from "../../../components";
import { useSession } from "../../../hooks";
import { useGetTeam } from "../../../http";
import { ITeam } from "../../../models";
import { setTitlePrefix } from "../../../utilities";
import { TeamActions, TeamNavBar, TeamTitle } from "./components";

interface IParams {
  slug: string;
}

interface ITeamPageLayoutContext {
  isLoadingTeam: boolean;
  team: ITeam | undefined;
}

const TeamPageLayoutContext = React.createContext<ITeamPageLayoutContext | undefined>(undefined);

const TeamPageLayout = () => {
  const { slug } = useParams<keyof IParams>() as IParams;
  const { userId } = useSession();
  const { data: team, isLoading } = useGetTeam(slug, userId);

  useEffect(() => {
    setTitlePrefix(team?.name);
  }, [team?.name]);

  return (
    <TeamPageLayoutContext.Provider
      value={{
        isLoadingTeam: isLoading,
        team,
      }}
    >
      <HeaderLayout
        title={<TeamTitle showAdministratorsTooltip />}
        navbar={!isLoading && <TeamNavBar />}
        actions={!isLoading && <TeamActions />}
        headerContainerProps={{
          sx: {
            alignItems: "flex-end",
            pb: 0,
          },
        }}
      >
        <Outlet />
      </HeaderLayout>
    </TeamPageLayoutContext.Provider>
  );
};

const useTeamPageLayoutContext = () => {
  const context = useContext(TeamPageLayoutContext);

  if (context === undefined) {
    throw new Error("TeamRouteContext is not initialized.");
  }

  return context;
};

export { TeamPageLayout, useTeamPageLayoutContext };
