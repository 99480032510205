import { OpenInBrowserRounded } from "@mui/icons-material";
import { Box, Chip, Divider, IconButton, Stack, Typography, styled } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { AvatarGroup, LinkBehavior } from "../../../components";
import { Links } from "../../../constants";
import { ITeamGraphNode } from "../../../models";

const ScrollBox = styled(Box)`
  scrollbar-width: thin;
  overscroll-behavior: contain;
  height: "100%";
  min-height: 0;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 50px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
`;

interface IProps {
  teamNode: ITeamGraphNode;
}

const TeamGraphNodeTooltipContent = (props: IProps) => {
  const navigate = useNavigate();

  return (
    <Stack
      spacing={0.75}
      sx={{ p: 0.75, maxHeight: "45vh" }}
      divider={<Divider sx={{ mx: 0.25, borderStyle: "dashed" }} />}
    >
      <Stack spacing={0.25}>
        {props.teamNode.parent && (
          <Chip
            label={props.teamNode.parent.name}
            component={LinkBehavior}
            href={Links.Team(props.teamNode.parent.slug)}
            clickable
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
            }}
            color="primary"
          />
        )}
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body2">{props.teamNode.label}</Typography>
          <IconButton color="primary" onClick={() => navigate(Links.Team(props.teamNode!.id))} size="small">
            <OpenInBrowserRounded />
          </IconButton>
        </Stack>
      </Stack>
      {props.teamNode.description && (
        <ScrollBox>
          <Typography>{props.teamNode.description}</Typography>
        </ScrollBox>
      )}
      {props.teamNode.members.length > 0 && <AvatarGroup users={props.teamNode.members} max={4} />}
    </Stack>
  );
};

export { TeamGraphNodeTooltipContent };
