import { DateTime } from "luxon";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { v4 as newGuid } from "uuid";
import { useSession, useSessionStorage } from "../hooks";
import { useCreatePageView } from "../http";

const ANALYTICS_SESSION_ID = "analytics_session_id";

const PageViewTracker = () => {
  const { userId } = useSession();
  const location = useLocation();
  const { getItem, setItem } = useSessionStorage();
  const { mutate: trackPageView } = useCreatePageView();

  useEffect(() => {
    setItem(ANALYTICS_SESSION_ID, newGuid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      trackPageView({
        userGuid: userId,
        sessionGuid: getItem<string>(ANALYTICS_SESSION_ID) ?? undefined,
        timestampUtc: DateTime.utc().toISO(),
        pageTitle: document.title,
        path: document.location.pathname,
        userAgent: navigator.userAgent,
      });
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return null;
};

export { PageViewTracker };
