import {
  AddTaskRounded,
  CircleOutlined,
  HowToVoteOutlined,
  KeyboardArrowDownRounded,
  Shortcut,
  ThumbUp,
  ThumbUpOutlined,
  Verified,
} from "@mui/icons-material";
import { v4 as newGuid } from "uuid";
import { Button, Link, Menu, MenuItem, Stack, SxProps, Tooltip, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import { Links } from "../../constants";
import { useSession } from "../../hooks";
import { IssueDto, useCreateProposal } from "../../http";
import { CreateActionToDoModal } from "../actionItems";

interface IProps {
  issue?: IssueDto;
  isLoading?: boolean;
  onToggleClosed: () => void;
  onUpvoteClicked: () => void;
  sx?: SxProps;
}

const IssueActions = (props: IProps) => {
  const user = useSession();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [isCreateTeamActionItemModalOpen, setIsCreateTeamActionItemModelOpen] = useState(false);
  const { mutate: createProposal } = useCreateProposal();
  const buttonsDisabled = !props.issue && props.isLoading;

  async function handleActionToDoCreateClicked() {
    setMenuAnchor(null);
    setIsCreateTeamActionItemModelOpen(true);
  }

  async function handleProposalCreateClicked() {
    setMenuAnchor(null);
    createProposal({
      guid: newGuid(),
      issueGuid: props.issue!.guid,
      description: "",
      createdByUser: {
        userId: user.userId,
        profilePhotoSmallUrl: user.profilePhotoSmallUrl,
        displayName: user.displayName,
        enabled: true,
      },
    });
  }

  return (
    <>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={0.5} sx={{ ...props.sx }}>
        <Tooltip
          title={
            props.issue?.closedOnDateTimeUtc
              ? `Closed on ${DateTime.fromISO(props.issue.closedOnDateTimeUtc, { zone: "utc" })
                  .toLocal()
                  .toLocaleString(DateTime.DATETIME_MED)}`
              : null
          }
        >
          <span>
            <Button
              variant="tertiary"
              startIcon={props.issue?.closedOnDateTimeUtc != null ? <Verified /> : <CircleOutlined />}
              disabled={buttonsDisabled || !props.issue?.userCanEdit}
              onClick={props.onToggleClosed}
            >
              {props.issue?.closedOnDateTimeUtc != null ? `Closed` : `Close`}
            </Button>
          </span>
        </Tooltip>

        <Tooltip
          title={
            props.issue && props.issue.upvotes.length > 0
              ? props.issue.upvotes
                  .sort((a, b) => a.displayName.localeCompare(b.displayName))
                  .map((u, i) => [
                    <Typography key={i} sx={{ display: "inline-block", fontSize: 13, marginRight: "2px" }}>
                      <Link href={Links.User(u.userId)}>{u.displayName}</Link>
                      {i < props.issue!.upvotes.length - 1 && ", "}
                    </Typography>,
                  ])
              : null
          }
        >
          <span>
            <Button
              variant="tertiary"
              startIcon={
                props.issue?.upvotes.findIndex((x) => x.userId === user.userId) !== -1 ? (
                  <ThumbUp />
                ) : (
                  <ThumbUpOutlined />
                )
              }
              disabled={buttonsDisabled}
              onClick={props.onUpvoteClicked}
            >
              {`Upvote (${props.issue?.upvotes.length ?? 0})`}
            </Button>
          </span>
        </Tooltip>

        <Button
          variant="tertiary"
          startIcon={<Shortcut />}
          endIcon={<KeyboardArrowDownRounded />}
          onClick={(e) => setMenuAnchor(e.currentTarget)}
          disabled={buttonsDisabled}
        >
          Next Step
          <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={(e: React.PointerEvent) => {
              setMenuAnchor(null);
              e.stopPropagation();
            }}
          >
            <MenuItem onClick={handleActionToDoCreateClicked} sx={{ px: 0.5 }}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <AddTaskRounded />
                <Typography>Create Action</Typography>
              </Stack>
            </MenuItem>
            <MenuItem onClick={handleProposalCreateClicked} sx={{ px: 0.5 }}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <HowToVoteOutlined />
                <Typography>Create Proposal</Typography>
              </Stack>
            </MenuItem>
          </Menu>
        </Button>
      </Stack>

      <CreateActionToDoModal
        visible={isCreateTeamActionItemModalOpen}
        onAdded={() => setIsCreateTeamActionItemModelOpen(false)}
        onCancelled={() => setIsCreateTeamActionItemModelOpen(false)}
      />
    </>
  );
};

export { IssueActions };
