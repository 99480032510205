import { QueryClient, QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { configuration } from "../../../configuration";
import { IUser } from "../../../models";
import { CreateIssueDto, FetchError, GetIssuesDto, IssueDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  teamName: string;
  teamSlug: string;
  createdByUser: IUser;
  description?: string;
  contextActionItemGuid?: string;
  contextActionItemHistoryId?: number;
};

const useCreateIssueRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createIssueRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();
    const body: CreateIssueDto = {
      guid: variables.guid,
      teamSlug: variables.teamSlug,
      description: variables.description,
      contextActionItemHistoryId: variables.contextActionItemHistoryId,
      createdByUserId: variables.createdByUser.userId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/issues`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { createIssueRequest };
};

const useCreateIssue = () => {
  const { createIssueRequest } = useCreateIssueRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createIssueRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.issues.getIssues(variables.teamSlug, undefined));

      let newIssue: IssueDto = {
        guid: variables.guid,
        assignedTeam: { slug: variables.teamSlug, name: variables.teamName },
        description: variables.description,
        closedOnDateTimeUtc: null,
        createdByUser: variables.createdByUser,
        createdOnDateTimeUtc: DateTime.now().toUTC().toISO({ includeOffset: false }),
        upvotes: [],
        proposals: [],
        proposalCount: 0,
        userCanEdit: true,
      };

      const cachedTeamData = optimisticallyUpdateIssuesData(
        queryClient,
        queryKeys.issues.getIssues(variables.teamSlug, undefined),
        newIssue
      );

      const cachedUserData = optimisticallyUpdateIssuesData(
        queryClient,
        queryKeys.issues.getIssues(undefined, variables.createdByUser.userId),
        newIssue
      );

      return { cachedTeamData, cachedUserData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.issues.getIssues(variables.teamSlug, undefined), context?.cachedTeamData);
      queryClient.setQueryData(
        queryKeys.issues.getIssues(undefined, variables.createdByUser.userId),
        context?.cachedUserData
      );
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.issues.getIssues(variables.teamSlug, undefined));
      queryClient.invalidateQueries(queryKeys.issues.getIssues(undefined, variables.createdByUser.userId));

      // Update supporting items list
      if (variables.contextActionItemGuid) {
        queryClient.invalidateQueries(queryKeys.actionItems.getActionItemSummary(variables.contextActionItemGuid));
        queryClient.invalidateQueries(queryKeys.actionItems.getActionItemDetail(variables.contextActionItemGuid));
      }
    },
  });
};

async function optimisticallyUpdateIssuesData(queryClient: QueryClient, queryKey: QueryKey, newIssue: IssueDto) {
  await queryClient.cancelQueries(queryKey);
  const cachedData = queryClient.getQueryData<GetIssuesDto>(queryKey);

  if (cachedData) {
    const optimisticallyUpdatedData: GetIssuesDto = {
      ...cachedData,
      issues: [...cachedData.issues, newIssue],
    };

    queryClient.setQueryData(queryKey, optimisticallyUpdatedData);
    return cachedData;
  }
}

export { useCreateIssue };
