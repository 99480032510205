import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { CreateProposalDto, FetchError, IssueDto, ProposalDto, UserDto } from "../../../types";
import { queryKeys, useAccessToken } from "../../..";
import { configuration } from "../../../../configuration";

type Variables = {
  guid: string;
  issueGuid: string;
  createdByUser: UserDto;
  description?: string;
};

const useCreateProposalRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createProposalRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();
    const body: CreateProposalDto = {
      guid: variables.guid,
      issueGuid: variables.issueGuid,
      description: variables.description,
    };

    const response = await fetch(`${configuration.apiRootUrl}/proposals`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { createProposalRequest };
};

const useCreateProposal = () => {
  const { createProposalRequest } = useCreateProposalRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createProposalRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.issues.getIssueDetail(variables.guid));

      let newProposal: ProposalDto = {
        guid: variables.guid,
        description: variables.description,
        selected: false,
        createdByUser: variables.createdByUser,
        upvotes: [],
      };

      let cachedIssueData = optimisticallyUpdateIssueData(queryClient, variables.issueGuid, newProposal);

      return { cachedIssueData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.issues.getIssueDetail(variables.issueGuid), context?.cachedIssueData);
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.issues.getIssueDetail(variables.issueGuid));
      queryClient.invalidateQueries(queryKeys.issues.getIssueFeed(variables.issueGuid));
    },
  });
};

async function optimisticallyUpdateIssueData(queryClient: QueryClient, issueGuid: string, newProposal: ProposalDto) {
  await queryClient.cancelQueries(queryKeys.issues.getIssueDetail(issueGuid));
  const cachedData = queryClient.getQueryData<IssueDto>(queryKeys.issues.getIssueDetail(issueGuid));

  if (cachedData) {
    const optimisticallyUpdatedData: IssueDto = {
      ...cachedData,
      proposals: [...cachedData.proposals, newProposal],
    };

    queryClient.setQueryData(queryKeys.issues.getIssueDetail(issueGuid), optimisticallyUpdatedData);
    return cachedData;
  }
}

export { useCreateProposal };
