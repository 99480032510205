import { AuthenticatedTemplate } from "@azure/msal-react";
import { AddRounded, BarChartRounded } from "@mui/icons-material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Box,
  Button,
  Divider,
  ListItemButton,
  Menu,
  IconButton as MuiIconButton,
  Link as MuiLink,
  ListItemIcon as MuiListItemIcon,
  MenuItem as MuiMenuItem,
  Skeleton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { CreateActionToDoModal, ExpandableDrawer, SideMenuTeamsList } from "..";
import { HomeIcon } from "../../assets/icons";
import { Links, Paths } from "../../constants";
import { useSession, useSettings } from "../../hooks";
import { Avatar } from "../Avatar";
import { CreateIssueModal } from "../issues";

const Link = styled(MuiLink)`
  color: ${({ theme }) => theme.palette.grey[400]};
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  svg {
    fill: ${({ theme }) => theme.palette.grey[400]};
  }

  &.active {
    color: ${({ theme }) => theme.palette.primary.main};

    svg {
      fill: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

const ListItemIcon = styled(MuiListItemIcon)`
  min-width: 24px;
`;

const ListItemText = styled(Typography)<{ $isExpanded: boolean }>`
  opacity: ${({ $isExpanded }) => ($isExpanded ? 1 : 0)};
  margin-left: ${({ $isExpanded }) => ($isExpanded ? "5px" : 0)};
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.875rem;
  transition: ${({ theme }) => theme.transitions.create(["opacity", "margin"])};
  text-transform: uppercase;
`;

const IconButton = styled(MuiIconButton)<{ $isExpanded: boolean }>`
  opacity: ${({ $isExpanded }) => ($isExpanded ? 1 : 0)};
  transition: ${({ theme }) => theme.transitions.create("opacity")};
`;

const MenuItem = styled(MuiMenuItem)`
  font-size: 0.875rem;
  text-align: left;
`;

const SideMenu = () => {
  const { isSessionLoaded, userId, displayName, profilePhotoSmallUrl } = useSession();
  const { sideMenuSettings, featureFlags } = useSettings();
  const [addMenuAnchor, setAddMenuAnchor] = useState<null | HTMLElement>(null);
  const [profileMenuAnchor, setProfileMenuAnchor] = useState<null | HTMLElement>(null);
  const [isCreateIssueModalOpen, setIsCreateIssueModalOpen] = useState(false);
  const [isCreateTeamActionItemModalOpen, setIsCreateTeamActionItemModelOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <AuthenticatedTemplate>
        <ExpandableDrawer>
          <Stack spacing={0.5} sx={{ height: "100%", minHeight: "0px", justifyContent: "space-between" }}>
            <Stack
              spacing={0.5}
              sx={{ pt: 0.5, height: "100%", minHeight: "0px" }}
              alignContent="start"
              divider={<Divider />}
            >
              <Stack spacing={0.5} sx={{ p: 0 }}>
                <>
                  {!isSessionLoaded && (
                    <>
                      {[...Array(3)].map((_, i) => (
                        <Stack key={i} direction="row" spacing="8px" alignItems="center">
                          <Skeleton width={24} sx={{ marginLeft: "8px !important", minWidth: "24px" }} />
                          <Skeleton width={60} sx={{ overflow: "hidden" }} />
                        </Stack>
                      ))}
                    </>
                  )}

                  {isSessionLoaded && (
                    <>
                      <Link href={Paths.Home}>
                        <ListItemButton sx={{ p: 0, borderRadius: "8px" }}>
                          <ListItemIcon>
                            <HomeIcon
                              style={{
                                height: 24,
                                width: 24,
                                margin: "8px",
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText $isExpanded={sideMenuSettings.isExpanded}>Home</ListItemText>
                        </ListItemButton>
                      </Link>
                      <Link href={Paths.Search}>
                        <ListItemButton sx={{ p: 0, borderRadius: "8px" }}>
                          <ListItemIcon>
                            <SearchRoundedIcon
                              style={{
                                height: 24,
                                width: 24,
                                margin: "8px",
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText $isExpanded={sideMenuSettings.isExpanded}>Search</ListItemText>
                        </ListItemButton>
                      </Link>
                      <Link href={Paths.Reports}>
                        <ListItemButton sx={{ p: 0, borderRadius: "8px" }}>
                          <ListItemIcon>
                            <BarChartRounded
                              style={{
                                height: 24,
                                width: 24,
                                margin: "8px",
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText $isExpanded={sideMenuSettings.isExpanded}>Reports</ListItemText>
                        </ListItemButton>
                      </Link>
                      {featureFlags.surveys && (
                        <Link href={Paths.Surveys}>
                          <ListItemButton sx={{ p: 0, borderRadius: "8px" }}>
                            <ListItemIcon>
                              <HowToVoteIcon
                                style={{
                                  height: 24,
                                  width: 24,
                                  margin: "8px",
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText $isExpanded={sideMenuSettings.isExpanded}>Surveys</ListItemText>
                          </ListItemButton>
                        </Link>
                      )}
                    </>
                  )}
                </>
              </Stack>

              {isSessionLoaded && <SideMenuTeamsList />}
            </Stack>

            <Stack spacing={1} sx={{ pt: 0.5 }} alignItems="start">
              <Stack
                component="label"
                direction="row"
                spacing={0.5}
                sx={{ alignItems: "center", cursor: "pointer", width: "100%" }}
              >
                <MuiIconButton
                  variant="filled"
                  onClick={(event) => setAddMenuAnchor(event.currentTarget)}
                  disabled={!isSessionLoaded}
                >
                  <AddRounded fontSize="large" />
                </MuiIconButton>
                <ListItemText $isExpanded={sideMenuSettings.isExpanded}>Add</ListItemText>
              </Stack>

              <Menu
                anchorEl={addMenuAnchor}
                open={Boolean(addMenuAnchor)}
                onClose={() => setAddMenuAnchor(null)}
                sx={{ m: 0.5 }}
                anchorOrigin={
                  sideMenuSettings.isExpanded
                    ? { vertical: "top", horizontal: "right" }
                    : { vertical: "bottom", horizontal: "right" }
                }
                transformOrigin={
                  sideMenuSettings.isExpanded
                    ? { vertical: "bottom", horizontal: "left" }
                    : { vertical: "bottom", horizontal: "left" }
                }
              >
                <MenuItem
                  onClick={() => {
                    setAddMenuAnchor(null);
                    setIsCreateIssueModalOpen(true);
                  }}
                >
                  Issue
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setAddMenuAnchor(null);
                    setIsCreateTeamActionItemModelOpen(true);
                  }}
                >
                  Action
                </MenuItem>
              </Menu>

              <CreateIssueModal
                visible={isCreateIssueModalOpen}
                onAdded={() => setIsCreateIssueModalOpen(false)}
                onCancelled={() => setIsCreateIssueModalOpen(false)}
              />

              <CreateActionToDoModal
                visible={isCreateTeamActionItemModalOpen}
                onAdded={() => setIsCreateTeamActionItemModelOpen(false)}
                onCancelled={() => setIsCreateTeamActionItemModelOpen(false)}
              />

              {!isSessionLoaded ? (
                <Skeleton variant="circular" width="40px" />
              ) : (
                <Stack
                  direction="row"
                  spacing={0.5}
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  {sideMenuSettings.isExpanded ? (
                    <Link href={Links.User(userId!)}>
                      <Avatar
                        user={{
                          displayName: displayName!,
                          userId: userId!,
                          profilePhotoSmallUrl: profilePhotoSmallUrl,
                          enabled: true,
                        }}
                      />
                    </Link>
                  ) : (
                    <Button
                      onClick={(event) => setProfileMenuAnchor(event.currentTarget)}
                      disableRipple
                      sx={{ p: 0, width: 40, minWidth: 40 }}
                    >
                      <Avatar
                        user={{
                          displayName: displayName!,
                          userId: userId!,
                          profilePhotoSmallUrl: profilePhotoSmallUrl,
                          enabled: true,
                        }}
                      />
                    </Button>
                  )}

                  <Link href={Links.User(userId!)} sx={{ minWidth: 0 }}>
                    <ListItemText sx={{ m: 0 }} noWrap $isExpanded={sideMenuSettings.isExpanded}>
                      {displayName?.split(" ")[0]}
                    </ListItemText>
                  </Link>

                  <Box sx={{ flexGrow: 1 }}></Box>

                  <IconButton
                    edge="end"
                    sx={{ mr: 0.5 }}
                    $isExpanded={sideMenuSettings.isExpanded}
                    onClick={(event) => setProfileMenuAnchor(event.currentTarget)}
                  >
                    <ExpandMoreRoundedIcon
                      style={{
                        height: 24,
                        width: 24,
                      }}
                    />
                  </IconButton>

                  <Menu
                    anchorEl={profileMenuAnchor}
                    open={Boolean(profileMenuAnchor)}
                    sx={{ m: 0.5 }}
                    onClose={() => setProfileMenuAnchor(null)}
                    anchorOrigin={
                      sideMenuSettings.isExpanded
                        ? { vertical: "top", horizontal: "center" }
                        : { vertical: "bottom", horizontal: "right" }
                    }
                    transformOrigin={
                      sideMenuSettings.isExpanded
                        ? { vertical: "bottom", horizontal: "center" }
                        : { vertical: "bottom", horizontal: "left" }
                    }
                  >
                    <MenuItem
                      onClick={() => {
                        setProfileMenuAnchor(null);
                        navigate(Links.User(userId!));
                      }}
                    >
                      My Profile
                    </MenuItem>
                    <MenuItem
                      divider
                      onClick={() => {
                        setProfileMenuAnchor(null);
                        navigate(Links.UserMap(userId!));
                      }}
                    >
                      My Map
                    </MenuItem>
                    <MenuItem
                      divider
                      onClick={() => {
                        setProfileMenuAnchor(null);
                        navigate(Links.UserSettings());
                      }}
                    >
                      Settings
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setProfileMenuAnchor(null);
                        navigate(Paths.SignOut);
                      }}
                    >
                      Sign Out
                    </MenuItem>
                  </Menu>
                </Stack>
              )}
            </Stack>
          </Stack>
        </ExpandableDrawer>
      </AuthenticatedTemplate>
    </>
  );
};

export { SideMenu };
