import { OpenInBrowserRounded } from "@mui/icons-material";
import { CardMedia, Chip, Divider, IconButton, Link, Stack, Typography, styled, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { BlockLink, LinkBehavior } from "../../../components";
import { ConnectObjectType, Links } from "../../../constants";
import { IPeopleGraphNode } from "../../../models";

const ScrollStack = styled(Stack)`
  scrollbar-width: thin;
  overscroll-behavior: contain;
  height: "100%";
  min-height: 0;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 50px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
`;

interface IProps {
  personNode: IPeopleGraphNode;
}

const PeopleGraphNodeTooltipContent = (props: IProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Stack sx={{ maxHeight: "45vh", minHeight: 0 }}>
      {props.personNode.profilePhotoLargeUrl && (
        <CardMedia
          image={props.personNode.profilePhotoLargeUrl ?? undefined}
          sx={{ minHeight: 150, borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
        />
      )}
      <Stack sx={{ p: 0.75 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body2">{props.personNode.label}</Typography>
          <IconButton color="primary" onClick={() => navigate(Links.User(props.personNode.id))} size="small">
            <OpenInBrowserRounded />
          </IconButton>
        </Stack>
        <Link
          href={`mailto:${props.personNode.email.toLowerCase()}`}
          sx={{ textTransform: "lowercase" }}
          underline="none"
        >
          {props.personNode.email}
        </Link>
      </Stack>
      <Divider sx={{ mx: 0.75, borderStyle: "dashed" }} />
      <ScrollStack divider={<Divider sx={{ mx: 0.75, borderStyle: "dashed" }} />}>
        {props.personNode.roles
          .sort((a, b) => (a.isPrimaryRole ? -1 : b.isPrimaryRole ? 1 : 0) || a.name.localeCompare(b.name))
          .map((role) => (
            <BlockLink
              key={role.guid}
              href={Links.Role(role.guid)}
              backgroundColorOnHover={theme.palette.primary.transparent5}
            >
              <Stack spacing={0.5} sx={{ p: 0.75 }}>
                <Stack spacing={0.5} direction="row" sx={{ width: "100%", minWidth: 0 }}>
                  <Chip
                    label={role.parent!.name}
                    component={LinkBehavior}
                    href={Links.Team(role.parent!.slug)}
                    clickable
                    onClick={(event: React.MouseEvent) => {
                      event.stopPropagation();
                    }}
                    sx={{ textOverflow: "ellipsis", minWidth: 0 }}
                    color="primary"
                  />
                  <Chip
                    label={role.isPrimaryRole ? ConnectObjectType.PrimaryRole : ConnectObjectType.Role}
                    color="orange"
                  />
                </Stack>
                <Typography>{role.name}</Typography>
              </Stack>
            </BlockLink>
          ))}
      </ScrollStack>
    </Stack>
  );
};

export { PeopleGraphNodeTooltipContent };
