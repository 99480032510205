const useSessionStorage = () => {
  function getItem<T>(key: string) {
    const serializedItem = sessionStorage.getItem(key);

    if (serializedItem == null) {
      return null;
    }

    try {
      return JSON.parse(serializedItem) as T;
    } catch {
      return null;
    }
  }

  function setItem<T>(key: string, item: T) {
    const serializedItem = JSON.stringify(item);
    sessionStorage.setItem(key, serializedItem);
  }

  return {
    getItem,
    setItem,
  };
};

export { useSessionStorage };
