import { Groups } from "@mui/icons-material";
import { Divider, Link, Stack, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { ItemProperty } from "..";
import { Links } from "../../constants";
import { GetActionItemDto } from "../../http";
import { ITeamSummary } from "../../models";
import { EditActionItemSectionModal, ITeamSection } from "../EditActionItemSectionModal";

interface IProps {
  actionItem: GetActionItemDto;
  onTeamChanged?: (selectedTeam: ITeamSummary | null, selectedSection: ITeamSection | null) => void;
}

const ActionItemTeamProperty = (props: IProps) => {
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);

  function handleTeamSectionChanged(selectedTeam: ITeamSummary | null, selectedSection: ITeamSection | null) {
    setIsEditModalVisible(false);
    props.onTeamChanged?.(selectedTeam, selectedSection);
  }

  return (
    <>
      <ItemProperty
        labelIcon={<Groups />}
        label="Team"
        canEdit={props.actionItem.userCanEdit}
        onEditClicked={() => setIsEditModalVisible(true)}
      >
        {!props.actionItem.teamPlanSection && !props.actionItem.team ? (
          <Typography variant="placeholder" sx={{ flexGrow: 1, minWidth: "0px" }}>
            Team | Section
          </Typography>
        ) : (
          <>
            {props.actionItem.teamPlanSection && (
              <ActionItemTeamName
                teamName={props.actionItem.teamPlanSection.teamName}
                teamSlug={props.actionItem.teamPlanSection.teamSlug}
                sectionName={props.actionItem.teamPlanSection.sectionName}
                href={Links.TeamStrategicMap(props.actionItem.teamPlanSection.teamSlug)}
              />
            )}
            {props.actionItem.team && (
              <ActionItemTeamName
                teamName={props.actionItem.team.teamName}
                teamSlug={props.actionItem.team.teamSlug}
                sectionName="Actions To Do"
                href={Links.TeamImplementation(props.actionItem.team.teamSlug)}
              />
            )}
          </>
        )}
      </ItemProperty>

      {props.actionItem.userCanEdit && (
        <EditActionItemSectionModal
          open={isEditModalVisible}
          actionItem={props.actionItem}
          onCancelled={() => setIsEditModalVisible(false)}
          onUpdated={handleTeamSectionChanged}
        />
      )}
    </>
  );
};

interface IActionItemTeamNameProps {
  teamName: string;
  teamSlug: string;
  sectionName: string;
  href: string;
}

const ActionItemTeamName = (props: IActionItemTeamNameProps) => {
  return (
    <Tooltip title={`${props.teamName} | ${props.sectionName}`} placement="top-start">
      <Stack
        direction="row"
        spacing={0.5}
        divider={<Divider orientation="vertical" sx={{ height: 24 }} />}
        sx={{ alignSelf: "center", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
      >
        <Link variant="body2" noWrap onClick={(e) => e.stopPropagation()} href={props.href}>
          {props.teamName}
        </Link>
        <Typography noWrap>{props.sectionName}</Typography>
      </Stack>
    </Tooltip>
  );
};

export { ActionItemTeamProperty };
