import { DeleteOutlineRounded } from "@mui/icons-material";
import { Button, Chip, Divider, Skeleton, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import {
  ActionItemFeed,
  ActionItemProperties,
  ContextProperty,
  ItemDescriptionField,
  LinkBehavior,
} from "../../components";
import { ActionItemPropertyType, Links, SupportingItemType } from "../../constants";
import { useSession } from "../../hooks";
import { useDeleteActionItem, useEditActionItem, useGetActionItemDetail } from "../../http";
import { setTitlePrefix, useDestructiveConfirm } from "../../utilities";
import { DetailPageLayout } from "./DetailPageLayout";

interface UrlParams {
  actionItemGuid: string;
}

const ActionItemDetailsPage = () => {
  const { userId } = useSession();
  const { actionItemGuid } = useParams<keyof UrlParams>() as UrlParams;
  const { isLoading, data: actionItem } = useGetActionItemDetail(actionItemGuid);
  const { mutate: editActionItem } = useEditActionItem();
  const { mutate: deleteActionItem } = useDeleteActionItem();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const confirm = useDestructiveConfirm();

  useEffect(() => {
    if (actionItem?.teamPlanSection) {
      setTitlePrefix(`${actionItem.teamPlanSection.teamName} ${actionItem.teamPlanSection.sectionName}`);
    } else {
      setTitlePrefix("Card");
    }
  }, [actionItem?.teamPlanSection]);

  function handleDescriptionChanged(value: string) {
    if (actionItem === undefined) {
      return;
    }

    editActionItem({
      guid: actionItem.guid,
      description: value,
      modifiedByUserId: userId,
    });
  }

  async function handleDeleteClicked(e: React.MouseEvent) {
    try {
      await confirm({
        title: "Delete Card",
        content: "Are you sure you want to delete this card?",
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    deleteActionItem(
      {
        guid: actionItemGuid,
        modifiedByUserId: userId,
        planGuid: actionItem?.teamPlanSection?.planGuid,
        teamSlug: actionItem?.team?.teamSlug,
      },
      {
        onSuccess: () => {
          if (actionItem?.teamPlanSection) {
            navigate(Links.TeamStrategicMap(actionItem.teamPlanSection.teamSlug));
          } else if (actionItem?.team) {
            navigate(Links.TeamImplementation(actionItem.team.teamSlug));
          } else {
            navigate(Links.UserMap(userId));
          }
          enqueueSnackbar(`Card has been deleted`);
        },
      }
    );
  }

  return (
    <DetailPageLayout
      actions={
        actionItem?.userCanEdit && (
          <Button variant="outlined" color="error" startIcon={<DeleteOutlineRounded />} onClick={handleDeleteClicked}>
            Delete
          </Button>
        )
      }
    >
      <Stack divider={<Divider />}>
        {isLoading && <Skeleton sx={{ mx: 1, mt: 0.5 }} width={"10%"} />}

        {!isLoading && actionItem?.teamPlanSection && (
          <Stack direction="row" gap={0.5} flexWrap="wrap" sx={{ p: { xs: 0.5, sm: 1 } }}>
            <Chip
              color="primary"
              component={LinkBehavior}
              label={actionItem.teamPlanSection.teamName}
              href={Links.TeamStrategicMap(actionItem.teamPlanSection.teamSlug)}
              clickable
            />
            <Chip label={actionItem.teamPlanSection.sectionName} />
          </Stack>
        )}

        {!isLoading && actionItem?.team && (
          <Stack direction="row" gap={0.5} flexWrap="wrap" sx={{ p: { xs: 0.5, sm: 1 } }}>
            <Chip
              color="primary"
              component={LinkBehavior}
              label={actionItem.team.teamName}
              href={Links.TeamImplementation(actionItem.team.teamSlug)}
              clickable
            />
            <Chip label="Actions To Do" />
          </Stack>
        )}

        <Stack sx={{ px: { xs: 0.5, sm: 1 } }} divider={<Divider flexItem variant="dashed" />}>
          {!isLoading && actionItem && (
            <ContextProperty
              itemGuid={actionItem.guid}
              itemType={SupportingItemType.ActionItem}
              sx={{ py: 0.25 }}
              context={actionItem.context}
              isEditable={actionItem.userCanEdit}
            />
          )}

          <ItemDescriptionField
            isLoading={isLoading}
            isEditable={actionItem?.userCanEdit ?? false}
            value={actionItem?.description}
            onChange={handleDescriptionChanged}
            variant="h4"
            sx={{ py: 0.5 }}
          />
        </Stack>

        <ActionItemProperties
          isLoading={isLoading}
          actionItem={actionItem}
          propertyVisibility={{
            [ActionItemPropertyType.Notes]: true,
            [ActionItemPropertyType.Ownership]: true,
            [ActionItemPropertyType.Status]: true,
            [ActionItemPropertyType.SupportingItems]: actionItem?.team != null || actionItem?.teamPlanSection != null,
          }}
        />

        <ActionItemFeed actionItemGuid={actionItemGuid} />
      </Stack>
    </DetailPageLayout>
  );
};

export { ActionItemDetailsPage };
