import { Button, Link, Stack, Typography } from "@mui/material";
import { AddRounded, LogoutRounded, SettingsOutlined, VideoCallOutlined } from "@mui/icons-material";
import { DateTime } from "luxon";
import { MeetingTimer } from "./MeetingTimer";
import { Links } from "../../../constants";
import { MeetingInstanceDto } from "../../../http";
import { Avatar, AvatarGroup } from "../../../components";

interface IProps {
  title: string;
  meetingInstance: MeetingInstanceDto;
  onLeaveMeetingClicked: () => void;
}

const MeetingHeader = (props: IProps) => {

  const meetingStartedAtDateTime = DateTime.fromISO(props.meetingInstance.startedAtDateTimeUtc, { zone: "utc" })
    .toLocal()
    .toLocaleString(DateTime.DATETIME_FULL);

  return (
    <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
      <Stack spacing={0.5}>
        <Typography variant="h3">{props.title}</Typography>
        <Typography>
          {`Started by `}
          <Link href={Links.User(props.meetingInstance.startedByUser.userId)}>
            {props.meetingInstance.startedByUser.displayName}
          </Link>
          {` on ${meetingStartedAtDateTime} | Running Time `}
          <MeetingTimer startDateTimeUtc={DateTime.fromISO(props.meetingInstance.startedAtDateTimeUtc)} />
        </Typography>
        <Stack direction="row" spacing={{ xs: 0.5, sm: 1 }} alignItems="center">
          <AvatarGroup
            users={props.meetingInstance.currentAttendees}
            avatarComponent={(user) => <Avatar user={user} tooltip />}
            max={2}
          />
          <Button variant="tertiary" startIcon={<LogoutRounded />} onClick={props.onLeaveMeetingClicked}>
            Leave Meeting
          </Button>
          <Button variant="tertiary" startIcon={<VideoCallOutlined />}>
            Join Call
          </Button>
        </Stack>
      </Stack>
      <Stack spacing={1} alignItems="end">
        <Stack direction="row" spacing={{ xs: 0.5, sm: 1 }}>
          <Button variant="contained" startIcon={<AddRounded />}>
            Add Issue/Action
          </Button>
          <Button variant="outlined" startIcon={<SettingsOutlined />}>
            Settings
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export { MeetingHeader };
