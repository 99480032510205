import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { Outlet } from "react-router";
import { PageViewTracker } from "../analytics";
import { DialogProvider } from "./DialogProvider";
import { SessionProvider } from "./session";
import { SignalRProvider } from "./signalR";

const PrivateRoute = () => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <SessionProvider>
        <PageViewTracker />
        <SignalRProvider>
          <DialogProvider>
            <Outlet />
          </DialogProvider>
        </SignalRProvider>
      </SessionProvider>
    </MsalAuthenticationTemplate>
  );
};

export { PrivateRoute };
