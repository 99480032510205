import { Box, Divider, Skeleton, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { CommentSubject, FeedItemType } from "../../constants";
import { useSignalR } from "../../hooks";
import { FeedItemDto } from "../../http";
import { SignalRGroup } from "../signalR";
import { CommentForm } from "./../CommentForm";
import { FeedComment } from "./FeedComment";
import { FeedPropertyChange } from "./FeedPropertyChange";

interface IProps {
  subjectType: CommentSubject;
  subjectGuid: string;
  isLoading: boolean;
  items: FeedItemDto[];
  onCommentCreated?: () => void;
  onCommentEdited?: () => void;
  onCommentDeleted?: () => void;
}

const Feed = (props: IProps) => {
  const signalR = useSignalR();

  useEffect(() => {
    signalR.joinGroup(SignalRGroup.CommentThread(props.subjectGuid));
    return () => signalR.leaveGroup(SignalRGroup.CommentThread(props.subjectGuid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subjectGuid]);

  function handleCommentCreated() {
    signalR.sendRefetchComments(props.subjectGuid);
    props.onCommentCreated?.();
  }

  function handleCommentEdited() {
    signalR.sendRefetchComments(props.subjectGuid);
    props.onCommentEdited?.();
  }

  function handleCommentDeleted() {
    signalR.sendRefetchComments(props.subjectGuid);
    props.onCommentDeleted?.();
  }

  return (
    <Stack>
      <CommentForm
        subjectType={props.subjectType}
        subjectGuid={props.subjectGuid}
        disabled={props.isLoading}
        onCommentCreated={handleCommentCreated}
      />

      <Divider />

      {props.isLoading && (
        <Stack divider={<Divider variant="dashed" sx={{ mx: 1 }} />}>
          {[...new Array(2)].map((_, i) => (
            <Stack key={i} direction="row" spacing={1} sx={{ alignItems: "center", p: 1 }}>
              <Skeleton variant="circular" width={36} height={36} />
              <Box>
                <Skeleton width={220} height={24} />
                <Skeleton width={50} height={24} />
              </Box>
            </Stack>
          ))}

          <Stack sx={{ backgroundColor: "rgba(0, 0, 0, 0.03)", p: 1 }}>
            <Skeleton width={50} height={24} />
            <Skeleton width={220} height={24} />
          </Stack>
        </Stack>
      )}

      {!props.isLoading && (
        <>
          {props.items.length === 0 && <Typography>Nothing here yet.</Typography>}
          {props.items.length > 0 && (
            <Stack divider={<Divider variant="dashed" sx={{ mx: { xs: 0.5, sm: 1 } }} />}>
              {props.items.map((feedItem, i) => {
                switch (feedItem.type) {
                  case FeedItemType.Comment:
                    return (
                      <FeedComment
                        key={i}
                        subjectType={props.subjectType}
                        subjectGuid={props.subjectGuid}
                        feedItem={feedItem}
                        onCommentEdited={handleCommentEdited}
                        onCommentDeleted={handleCommentDeleted}
                      />
                    );
                  case FeedItemType.PropertyChange:
                    return <FeedPropertyChange key={i} feedItem={feedItem} />;
                  default:
                    return null;
                }
              })}
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};

export { Feed };
