import { Link, Typography } from "@mui/material";
import { Links } from "../../constants";
import { FeedItemDto } from "../../http";

interface IProps {
  feedItem: FeedItemDto;
}

const SelectedProposalPropertyChange = (props: IProps) => {
  if (props.feedItem.propertyChange!.value != null) {
    return (
      <Typography variant="caption">
        <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
        <span> selected proposal </span>
        <strong>{props.feedItem.propertyChange!.value}</strong>
      </Typography>
    );
  }

  return (
    <Typography variant="caption">
      <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
      <span> unselected the proposal.</span>
    </Typography>
  );
};

export { SelectedProposalPropertyChange };
