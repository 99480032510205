import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { configuration } from "../../../configuration";
import { CommentType } from "../../../constants";
import { IComment } from "../../../models";
import { FetchError, GetCommentsDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetUserCommentsRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getUserCommentsRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.comments.getComments>>): Promise<GetCommentsDto> {
    const accessToken = await getAccessToken();
    const [{ threadGuid }] = queryKey;
    const url = new URL(`${configuration.apiRootUrl}/threads/${threadGuid}/comments`);

    const response = await fetch(url.href, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getUserCommentsRequest };
};

const useGetUserComments = (threadGuid: string | undefined) => {
  const { getUserCommentsRequest } = useGetUserCommentsRequest();

  return useQuery({
    queryKey: queryKeys.comments.getComments(threadGuid),
    queryFn: getUserCommentsRequest,
    select: (data): IComment[] => {
      return data.comments.map((comment) => ({
        type: CommentType.Comment,
        commentGuid: comment.guid,
        timestampUtc: DateTime.fromISO(comment.dateCreatedUtc, { zone: "utc" }),
        lastModifiedUtc: DateTime.fromISO(comment.lastModifiedUtc, { zone: "utc" }),
        user: comment.createdBy,
        text: comment.text,
      }));
    },
    enabled: threadGuid !== undefined,
  });
};

export { useGetUserComments };
