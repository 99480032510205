import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { IPlan } from "../../../models";
import { CreateEditBlockResultDto, EditBlockDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  planGuid: string;
  guid: string;
  name: string;
  hintText: string;
  sortOrder: string;
  modifiedByUserId: string;
};

const useEditBlockRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editBlockRequest(variables: Variables): Promise<CreateEditBlockResultDto> {
    const accessToken = await getAccessToken();
    const body: EditBlockDto = {
      name: variables.name,
      hintText: variables.hintText,
      sortOrder: variables.sortOrder,
      modifiedByUserId: variables.modifiedByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/blocks/${variables.guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { editBlockRequest };
};

const useEditBlock = () => {
  const { editBlockRequest } = useEditBlockRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editBlockRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.plans.getPlan(variables.planGuid));

      //snapshot the current cache value
      const cachedData = queryClient.getQueryData<IPlan>(queryKeys.plans.getPlan(variables.planGuid));

      if (cachedData) {
        //optimistically update the cache
        let optimisticallyUpdatedData: IPlan = {
          ...cachedData,
          blocks: cachedData.blocks.map((value) => {
            if (value.guid === variables.guid) {
              return {
                ...value,
                name: variables.name,
                hintText: variables.hintText,
                sortOrder: variables.sortOrder,
              };
            }
            return value;
          }),
        };
        queryClient.setQueryData(queryKeys.plans.getPlan(variables.planGuid), optimisticallyUpdatedData);
      }
      return { cachedData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.plans.getPlan(variables.planGuid), context?.cachedData);
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.plans.getPlan(variables.planGuid));
    },
  });
};

export { useEditBlock };
