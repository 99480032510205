import React from "react";

interface ISessionContext {
  isSessionLoaded: boolean;
  user?:
    | {
        displayName?: string | undefined;
        userId?: string | undefined;
        profilePhotoSmallUrl?: string | undefined;
        profilePhotoLargeUrl?: string | undefined;
        planGuid?: string | undefined;
      }
    | undefined;
  endSession: () => void;
}

const SessionContext = React.createContext<ISessionContext>({
  isSessionLoaded: false,
  endSession: () => null,
});

export { SessionContext };
export type { ISessionContext };
