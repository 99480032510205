import { Chip, Stack, SxProps, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { BlockLink, LinkBehavior, Typography } from "../../../../components";
import { ConnectObjectType, Links } from "../../../../constants";
import { IUserRole } from "../../../../models";

interface IProps {
  role: IUserRole;
  faded?: boolean;
  sx?: SxProps;
}

const RoleItem = (props: IProps) => {
  const theme = useTheme();

  return (
    <BlockLink href={Links.Role(props.role.guid)} backgroundColorOnHover={theme.palette.primary.transparent5}>
      <Stack spacing={0} sx={{ ...props.sx, px: { xs: 0.5, sm: 1 } }}>
        <Stack spacing={0.5} direction="row">
          <Chip
            label={props.role.parentTeam.name}
            component={LinkBehavior}
            href={Links.Team(props.role.parentTeam.slug)}
            clickable
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
            }}
            color="primary"
          />

          <Chip
            label={props.role.isPrimaryRole ? ConnectObjectType.PrimaryRole : ConnectObjectType.Role}
            color="orange"
          />

          {props.role.isOpen && (
            <Chip
              label={`OPEN as of ${props.role.isOpenLastModifiedUtc
                .toLocal()
                .toLocaleString(DateTime.DATE_MED)
                .toLocaleUpperCase()}`}
              color="orange"
              variant="outlined"
              sx={{
                ".MuiChip-label": {
                  textTransform: "none",
                },
              }}
            />
          )}
        </Stack>

        <Typography variant="h5" faded={props.faded}>
          {props.role.name}
        </Typography>
        <Typography variant="body1" faded={props.faded} sx={{ wordWrap: "break-word" }}>
          {props.role.description}
        </Typography>
      </Stack>
    </BlockLink>
  );
};

export { RoleItem };
