import { Circle } from "@mui/icons-material";
import {
  ListItemButton as MuiListItemButton,
  Link as MuiLink,
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";
import { Links } from "../../constants";
import { ITeamSummary } from "../../models";
import { useBreakpoints } from "../../hooks";

const Link = styled(MuiLink)`
  color: ${({ theme }) => theme.palette.text.primary};
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  svg {
    color: ${({ theme }) => theme.palette.grey[200]};
  }

  &.active {
    svg {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

const ListItemIcon = styled(MuiListItemIcon)`
  min-width: 24px;
`;

const ListItemButton = styled(MuiListItemButton)`
  padding: 6px 16px;
  border-radius: 5px;
  font-size: 0.875rem;
`;

const ListItemText = styled(Typography)<{ $isExpanded: boolean }>`
  opacity: ${({ $isExpanded }) => ($isExpanded ? 1 : 0)};
  margin-left: ${({ $isExpanded }) => ($isExpanded ? "5px" : 0)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.875rem;
  transition: ${({ theme }) => theme.transitions.create(["opacity", "margin"])};
  text-transform: uppercase;
`;

interface IProps {
  team: ITeamSummary;
  isExpanded: boolean;
}

const SideMenuTeamButton = (props: IProps) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const { isMdUp } = useBreakpoints();

  function handleMouseEnter() {
    setIsHovering(true);
  }

  function handleMouseLeave() {
    setIsHovering(false);
  }

  return (
    <Tooltip
      componentsProps={{ tooltip: { sx: { p: "8px" } } }}
      open={isMdUp && isHovering}
      PopperProps={{ onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave }}
      title={
        <>
          <Typography variant="body2" sx={{ fontSize: "0.875rem", px: "16px", py: "6px" }}>
            {props.team.name}
          </Typography>
          <Stack>
            <Link href={Links.Team(props.team.slug)}>
              <ListItemButton>Overview</ListItemButton>
            </Link>
            <Link href={Links.TeamStrategicMap(props.team.slug)}>
              <ListItemButton>Strategic Map</ListItemButton>
            </Link>
            <Link href={Links.TeamImplementation(props.team.slug)}>
              <ListItemButton>Implementation</ListItemButton>
            </Link>
            <Link href={Links.TeamMetrics(props.team.slug)}>
              <ListItemButton>Metrics</ListItemButton>
            </Link>
            <Link href={Links.TeamResources(props.team.slug)}>
              <ListItemButton>Resources</ListItemButton>
            </Link>
          </Stack>
        </>
      }
      placement="right"
    >
      <Link href={Links.Team(props.team.slug)} onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <MuiListItem sx={{ py: 0.2, px: "13px" }}>
          <ListItemIcon>
            <Circle fontSize="small" />
          </ListItemIcon>
          <ListItemText $isExpanded={props.isExpanded}>{props.team.name}</ListItemText>
        </MuiListItem>
      </Link>
    </Tooltip>
  );
};

export { SideMenuTeamButton };
