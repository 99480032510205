import { useContext } from "react";
import { SignalRClientMethod, SignalRServerMethod } from "../constants";
import { SignalRContext } from "../SignalRContext";
import { SignalRIssuesContext } from "./SignalRIssuesContext";

interface IProps {
  children?: React.ReactNode;
}

const SignalRIssuesProvider = (props: IProps) => {
  const context = useContext(SignalRContext);

  return (
    <SignalRIssuesContext.Provider
      value={{
        sendRefetchTeamIssues: (...args) => context.invoke(SignalRServerMethod.SendRefetchTeamIssues, args),
        onShouldRefetchTeamIssues: (handler) =>
          context.registerHandler(SignalRClientMethod.ShouldRefetchTeamIssues, handler),
        offShouldRefetchTeamIssues: (handler) =>
          context.unregisterHandler(SignalRClientMethod.ShouldRefetchTeamIssues, handler),
      }}
    >
      {props.children}
    </SignalRIssuesContext.Provider>
  );
};

export { SignalRIssuesProvider };
