import React from "react";

interface ISignalRActionItemsContext {
  sendRefetchActionItem: (actionItemGuid: string) => void;
  onShouldRefetchActionItem: (handler: (actionItemGuid: string) => void) => void;
  offShouldRefetchActionItem: (handler: (actionItemGuid: string) => void) => void;
}

const SignalRActionItemsContext = React.createContext<ISignalRActionItemsContext>({
  sendRefetchActionItem: () => null,
  onShouldRefetchActionItem: () => null,
  offShouldRefetchActionItem: () => null,
});

export { SignalRActionItemsContext };
