import { Grid, Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useSearchParams } from "react-router-dom";
import { Block, CreateBlockModal, PlanHeader, PlanVersionsDrawerContent } from ".";
import { SignalRGroup, Typography, useDrawer } from "..";
import { usePlanToCsv, useSession, useSignalR } from "../../hooks";
import { queryKeys, useEditActionItemSection, useEditActionItemSortOrder, useGetPlan } from "../../http";
import { IPlan } from "../../models";
import { lexorank } from "../../utilities";

interface IPlanContext {
  planGuid: string;
  focusedActionItemGuid: string | undefined;
  selectedActionItemGuid: string | undefined;
  setFocusedActionItemGuid: (guid: string | undefined) => void;
  setSelectedActionItemGuid: (guid: string | undefined) => void;
}

interface IProps {
  planGuid: string;
  headerContent: (args: {
    isLoading: boolean;
    isPastView: boolean;
    handleVersionsClicked: () => void;
    handleExportClicked: (filename: string) => void;
    plan?: IPlan;
    dateTimeLocal?: string | null;
    dateTimeLocalUtcOffsetInMinutes?: number | null;
  }) => React.ReactNode;
  canEdit?: boolean;
}

const PlanContext = React.createContext<IPlanContext | undefined>(undefined);

const Plan = (props: IProps) => {
  const [queryParams] = useSearchParams();
  const { userId } = useSession();
  const [dateTimeLocal, setDateTimeLocal] = useState<string>();
  const [dateTimeLocalUtcOffsetInMinutes, setDateTimeLocalUtcOffsetInMinutes] = useState<number>();
  const { mutate: editActionItemSortOrder, isLoading: isUpdatingSortOrder } = useEditActionItemSortOrder();
  const { mutate: editActionItemSection, isLoading: isUpdatingSection } = useEditActionItemSection();
  const { data, isLoading, isFetching } = useGetPlan(props.planGuid, dateTimeLocal, dateTimeLocalUtcOffsetInMinutes);
  const { convertAndDownloadPlanAsCsv } = usePlanToCsv(props.planGuid);
  const [plan, setPlan] = useState<IPlan>();
  const [isCreateBlockModalVisible, setIsCreateBlockModalVisible] = useState(false);
  const [selectedActionItemGuid, setSelectedActionItemGuid] = useState<string | undefined>(undefined);
  const [focusedActionItemGuid, setFocusedActionItemGuid] = useState<string | undefined>(undefined);
  const [isRowView, setRowView] = useState(false);
  const [isPastView, setIsPastView] = useState(false);
  const container = React.useRef<HTMLDivElement>(null);
  const { showDrawer } = useDrawer();
  const queryClient = useQueryClient();
  const signalR = useSignalR();

  const hasEditPermission = (props.canEdit && (!isUpdatingSection || !isUpdatingSortOrder || !isFetching)) ?? false;

  useEffect(() => {
    const date = queryParams.get("date");
    const offset = queryParams.get("offset");

    setDateTimeLocal(date ?? undefined);
    setDateTimeLocalUtcOffsetInMinutes(offset ? Number(offset) : undefined);
    setIsPastView(date != null && offset != null);
  }, [queryParams, plan]);

  useEffect(() => {
    if (data !== undefined) {
      setPlan(data);
    }
  }, [data]);

  useEffect(() => {
    if (plan !== undefined) {
      const actionItemGuids =
        plan?.blocks.flatMap((x) => x.sections.flatMap((y) => y.actionItems.map((z) => z.guid))) ?? [];

      signalR.joinGroups(actionItemGuids.map((x) => SignalRGroup.ActionItem(x)));

      return () => {
        signalR.leaveGroups(actionItemGuids.map((x) => SignalRGroup.ActionItem(x)));
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);

  useEffect(() => {
    const handleShouldRefetchActionItem = (actionItemGuid: string) => {
      queryClient.invalidateQueries(queryKeys.actionItems.getActionItemSummary(actionItemGuid));
    };

    signalR.onShouldRefetchActionItem(handleShouldRefetchActionItem);
    return () => signalR.offShouldRefetchActionItem(handleShouldRefetchActionItem);
  }, [queryClient, signalR, userId]);

  function handleDragEnd(result: DropResult) {
    const { draggableId: actionItemGuid, destination, source } = result;

    // User dragged the item outside of the container
    if (!destination || !plan) {
      return;
    }

    // User dragged the item back to where it was
    if (destination?.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    let toSection = plan.blocks
      .flatMap((block) => block.sections)
      .find((section) => section.guid === destination.droppableId);

    let fromSection = plan.blocks
      .flatMap((block) => block.sections)
      .find((section) => section.guid === source.droppableId);

    if (toSection === undefined || fromSection === undefined) {
      return;
    }

    let actionItem = fromSection.actionItems.find((x) => x.guid === actionItemGuid);

    if (actionItem === undefined) {
      return;
    }

    let newSortOrder = "";

    if (fromSection.guid === toSection.guid) {
      const updatedPlan: IPlan = {
        ...plan,
        blocks: plan.blocks.map((block) => ({
          ...block,
          sections: block.sections.map((section) => ({
            ...section,
            actionItems: section.actionItems.map((actionItem) => {
              if (actionItem.guid === actionItemGuid) {
                newSortOrder = lexorank.getRank(section.actionItems, source.index, destination.index);

                return {
                  ...actionItem,
                  sortOrder: newSortOrder,
                };
              }
              return actionItem;
            }),
          })),
        })),
      };

      setPlan(updatedPlan);

      editActionItemSortOrder({
        actionItemGuid,
        sortOrder: newSortOrder,
      });
    } else {
      const updatedPlan: IPlan = {
        ...plan,
        blocks: plan.blocks.map((block) => ({
          ...block,
          sections: block.sections.map((section) => {
            if (section.guid === fromSection!.guid) {
              return {
                ...section,
                actionItems: section.actionItems.filter((actionItem) => actionItem.guid !== actionItemGuid),
              };
            } else if (section.guid === toSection!.guid) {
              newSortOrder = lexorank.getRankAtIndex(section.actionItems, destination.index);

              return {
                ...section,
                actionItems: [
                  ...section.actionItems,
                  {
                    ...actionItem!,
                    sortOrder: newSortOrder,
                  },
                ],
              };
            } else {
              return section;
            }
          }),
        })),
      };

      setPlan(updatedPlan);

      editActionItemSection({
        fromPlanGuid: props.planGuid,
        guid: result.draggableId,
        fromSectionGuid: source.droppableId,
        toSectionGuid: destination.droppableId,
        sortOrder: newSortOrder,
      });
    }
  }

  return (
    <PlanContext.Provider
      value={{
        planGuid: props.planGuid,
        focusedActionItemGuid,
        selectedActionItemGuid,
        setFocusedActionItemGuid,
        setSelectedActionItemGuid,
      }}
    >
      <Stack ref={container} spacing={{ xs: 0.75, md: 1 }}>
        <PlanHeader
          content={props.headerContent({
            isLoading: isLoading,
            isPastView: isPastView,
            handleVersionsClicked: () =>
              showDrawer({
                title: <Typography variant="h6">Version History</Typography>,
                content: (
                  <PlanVersionsDrawerContent planGuid={props.planGuid} planLastModified={plan?.lastModifiedUtc} />
                ),
              }),
            handleExportClicked: (filename) => convertAndDownloadPlanAsCsv(filename),
            plan: plan,
            dateTimeLocal: dateTimeLocal,
            dateTimeLocalUtcOffsetInMinutes: dateTimeLocalUtcOffsetInMinutes,
          })}
          isRowView={isRowView}
          isPastView={isPastView}
          onViewToggled={(value) => setRowView(value)}
        />

        {!isLoading && plan && (
          <>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Grid container sx={{ justifyContent: "center" }}>
                <Grid item sx={{ width: "100%" }} md={isRowView ? 8 : false} sm={12}>
                  <Stack
                    direction={{ xs: "column", md: isRowView ? "column" : "row" }}
                    spacing={{ xs: 0.75, md: 1 }}
                    alignItems="flex-start"
                  >
                    {plan?.blocks
                      .sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))
                      .map((block, _) => (
                        <Block
                          key={block.guid}
                          block={block}
                          blocks={plan.blocks}
                          isRowView={isRowView}
                          isPastView={isPastView}
                          canEditPlan={hasEditPermission && !isPastView}
                          onBlockAddClick={() => setIsCreateBlockModalVisible(true)}
                        />
                      ))}
                  </Stack>
                </Grid>
              </Grid>
            </DragDropContext>
            <CreateBlockModal
              visible={isCreateBlockModalVisible}
              blocks={plan.blocks}
              onAdded={() => setIsCreateBlockModalVisible(false)}
              onCancelled={() => setIsCreateBlockModalVisible(false)}
            />
          </>
        )}
      </Stack>
    </PlanContext.Provider>
  );
};

const usePlanContext = () => {
  const context = useContext(PlanContext);

  if (context === undefined) {
    throw new Error("PlanContext is not initialized.");
  }

  return context;
};

export { Plan, PlanContext, usePlanContext };
