import { DateTime } from "luxon";
import { useEffect, useState } from "react";

interface IProps {
  startDateTimeUtc: DateTime;
}

const MeetingTimer = (props: IProps) => {
  const [timerDiff, setTimerDiff] = useState(DateTime.now().toUTC().diff(props.startDateTimeUtc));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimerDiff(DateTime.now().toUTC().diff(props.startDateTimeUtc));
    }, 1000);

    return () => clearInterval(interval);
  }, [props.startDateTimeUtc]);

  return <span>{timerDiff.toFormat("hh:mm:ss")}</span>;
};

export { MeetingTimer };
