import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { IOrganizationNetworkGraph } from "../../../models";
import { FetchError, GetOrganizationNetworkGraphDto, getNodeImage, getNodeUrl } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetOrganizationNetworkGraphRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getOrganizationNetworkGraphRequest({
    signal,
  }: QueryFunctionContext<
    ReturnType<typeof queryKeys.organization.getOrganizationNetworkGraph>
  >): Promise<GetOrganizationNetworkGraphDto> {
    const accessToken = await getAccessToken();

    const response = await fetch(`${configuration.apiRootUrl}/graphs/organization`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      signal,
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getOrganizationNetworkGraphRequest };
};

const useGetOrganizationNetworkGraph = () => {
  const { getOrganizationNetworkGraphRequest } = useGetOrganizationNetworkGraphRequest();

  return useQuery({
    queryKey: queryKeys.organization.getOrganizationNetworkGraph(),
    queryFn: getOrganizationNetworkGraphRequest,
    select: (data): IOrganizationNetworkGraph => {
      return {
        teamNodes: data.teamNodes.map((node) => ({
          ...node,
          url: getNodeUrl(node),
        })),
        roleNodes: data.roleNodes.map((node) => ({
          ...node,
          url: getNodeUrl(node),
        })),
        peopleNodes: data.peopleNodes.map((node) => ({
          ...node,
          url: getNodeUrl(node),
          profilePhotoSmall: getNodeImage(node.profilePhotoSmallUrl),
          profilePhotoSmallUrl: node.profilePhotoSmallUrl,
          profilePhotoLarge: getNodeImage(node.profilePhotoLargeUrl),
          profilePhotoLargeUrl: node.profilePhotoLargeUrl,
        })),
        teamLinks: data.teamLinks,
        roleLinks: data.roleLinks,
        peopleLinks: data.peopleLinks,
      };
    },
  });
};

export { useGetOrganizationNetworkGraph };
