import { EditOutlined, LinkRounded } from "@mui/icons-material";
import { IconButton, Link, Stack, SxProps, Tooltip, useTheme } from "@mui/material";
import { useState } from "react";
import { Links, SupportingItemType } from "../../constants";
import { IContextActionItem } from "../../models";
import { Typography } from "../Typography";
import { ContextSyncAlert } from "./ContextSyncAlert";
import { EditContextModal } from ".";
import { useEditActionItemContext, useEditIssueContext } from "../../http";
import { useSession } from "../../hooks";

interface IProps {
  itemGuid: string;
  itemType: SupportingItemType;
  context?: IContextActionItem;
  isEditable?: boolean;
  isPastView?: boolean;
  condensed?: boolean;
  sx?: SxProps;
  onUpdated?: () => void;
}

const ContextProperty = (props: IProps) => {
  const { userId } = useSession();
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const { mutate: editActionItemContext } = useEditActionItemContext();
  const { mutate: editIssueContext } = useEditIssueContext();
  const { context } = props;
  const theme = useTheme();

  const isConnectionChanged = context?.updatedVersion && context.description !== context.updatedVersion.description;
  const isConnectionDeleted = context?.updatedVersion && context.updatedVersion.isDeleted;

  const showSyncAlert = (isConnectionChanged || isConnectionDeleted) && !props.isPastView;

  if (!context && !props.isEditable) {
    return null;
  }

  async function handleUpdateClicked(selectedContextActionItemHistoryId?: number): Promise<void> {
    switch (props.itemType) {
      case SupportingItemType.ActionItem: {
        editActionItemContext(
          {
            guid: props.itemGuid,
            contextActionItemHistoryId: selectedContextActionItemHistoryId,
            modifiedByUserId: userId,
          },
          {
            onSuccess: () => {
              setIsEditModalVisible(false);
            },
          }
        );
        return;
      }
      case SupportingItemType.Issue: {
        editIssueContext(
          {
            guid: props.itemGuid,
            contextActionItemHistoryId: selectedContextActionItemHistoryId,
            modifiedByUserId: userId,
          },
          {
            onSuccess: () => {
              setIsEditModalVisible(false);
              props.onUpdated?.();
            },
          }
        );
        return;
      }
      default:
        return;
    }
  }

  return (
    <>
      <Stack direction="row" spacing={0.5} alignItems="center" sx={{ ...props.sx }}>
        <Stack direction="row" spacing={0.25} alignItems="center" sx={{ flexGrow: 1, minWidth: "0px", width: "100%" }}>
          <LinkRounded />
          {context ? (
            <Tooltip
              placement="top-start"
              enterDelay={1000}
              enterNextDelay={1000}
              leaveDelay={500}
              slotProps={{
                tooltip: {
                  sx: {
                    backgroundColor: props.isPastView ? "#f6f6f6" : undefined,
                  },
                },
              }}
              title={
                <Stack direction="row">
                  <LinkRounded sx={{ alignSelf: "start", mt: "3px", mr: "5px" }} />
                  <Typography variant="body1">
                    {!props.isPastView ? (
                      <>
                        {context.teamPlanSection && (
                          <>
                            <Link
                              href={Links.TeamStrategicMap(context.teamPlanSection.teamSlug)}
                              sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                            >
                              {context.teamPlanSection.teamName}
                            </Link>
                            {" : "}
                          </>
                        )}

                        {isConnectionDeleted ? (
                          <>{context.description}</>
                        ) : (
                          <Link href={Links.ActionItemDetail(context.actionItemGuid)}>{context.description}</Link>
                        )}
                      </>
                    ) : (
                      context.description
                    )}
                  </Typography>
                </Stack>
              }
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <>
                  {!props.isPastView && context.teamPlanSection && (
                    <>
                      <Link
                        noWrap
                        href={Links.Team(context.teamPlanSection.teamSlug)}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Typography
                          variant="body2"
                          noWrap
                          sx={{ fontSize: props.condensed ? 12 : "unset", textTransform: "uppercase" }}
                        >
                          {context.teamPlanSection.teamName}
                        </Typography>
                      </Link>

                      <Typography
                        variant="body2"
                        sx={{ pb: 0.1, fontSize: props.condensed ? 12 : "unset", mx: 0.25 }}
                        color={theme.palette.grey[300]}
                      >
                        :
                      </Typography>
                    </>
                  )}

                  {!props.isPastView && !isConnectionDeleted ? (
                    <Link
                      noWrap
                      href={Links.ActionItemDetail(context.actionItemGuid)}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Typography variant="body1" noWrap sx={{ fontSize: props.condensed ? 12 : "unset" }}>
                        {context.description}
                      </Typography>
                    </Link>
                  ) : (
                    <Typography variant="body1" noWrap faded sx={{ fontSize: props.condensed ? 12 : "unset" }}>
                      {context.description}
                    </Typography>
                  )}
                </>
              </Stack>
            </Tooltip>
          ) : (
            <Typography variant="placeholder" sx={{ flexGrow: 1, minWidth: "0px" }}>
              Connect to Context
            </Typography>
          )}

          {showSyncAlert && context.updatedVersion && (
            <ContextSyncAlert previousDescription={context.description} updatedContext={context.updatedVersion} />
          )}
        </Stack>

        {props.isEditable && (
          <IconButton size="small" sx={{ color: "rgba(0, 0, 0, 0.3)" }} onClick={() => setIsEditModalVisible(true)}>
            <EditOutlined />
          </IconButton>
        )}
      </Stack>

      {props.isEditable && (
        <EditContextModal
          open={isEditModalVisible}
          context={context}
          onUpdateClicked={handleUpdateClicked}
          onCancelled={() => setIsEditModalVisible(false)}
        />
      )}
    </>
  );
};

export { ContextProperty };
