import { MoreVertRounded } from "@mui/icons-material";
import { Box, Button, IconButton, Link, Menu, MenuItem, Stack, styled, SxProps, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useConfirm } from "material-ui-confirm";
import { useState } from "react";
import { CommentType, Links } from "../constants";
import { useComments, useError, useSession } from "../hooks";
import { IComment } from "../models";
import { Avatar } from "./Avatar";
import { isWhitespace, TextEditor, TextViewer } from "./textEditor";

const EditRow = styled(Stack)<{ $isReadonly: boolean; $isHovering: boolean; $isEditing: boolean }>`
  background-color: ${({ $isReadonly, $isHovering, $isEditing, theme }) =>
    ($isHovering || $isEditing) && !$isReadonly ? theme.palette.primary.transparent5 : "transparent"};
  transition: ${({ theme }) => theme.transitions.create("background-color")};
`;

interface IProps {
  comment: IComment;
  onCommentEditing?: (commentGuid: string, lastModifiedUtc: string, text: string) => void;
  onCommentEdited?: () => void;
  onCommentDeleted?: (commentGuid: string) => void;
  sx?: SxProps;
}

const Comment = (props: IProps) => {
  const { userId } = useSession();
  const { editComment, deleteComment } = useComments();
  const { setError } = useError();
  const confirm = useConfirm();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [editedCommentText, setEditedCommentText] = useState("");
  const isReadonly =
    props.comment.user.userId !== userId ||
    props.comment.commentGuid === undefined ||
    props.comment.type === CommentType.HistoryLedgerItem;

  async function handleEditClicked() {
    setMenuAnchor(null);
    setIsEditing(true);
    setEditedCommentText(props.comment.text as string);
  }

  async function handleDeleteClicked() {
    setMenuAnchor(null);

    if (props.comment.commentGuid === undefined) {
      return;
    }

    try {
      await confirm({
        title: "Delete Comment",
        content: "Are you sure you want to delete this comment?",
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    setIsLoading(true);

    (await deleteComment(props.comment.commentGuid)).match(
      () => props.onCommentDeleted?.(props.comment.commentGuid!),
      (error) => setError(error)
    );

    setIsLoading(false);
  }

  function handleCancelEditClicked() {
    setIsEditing(false);
    setEditedCommentText("");
  }

  async function handleSaveEditClicked() {
    if (props.comment.commentGuid === undefined) {
      return;
    }

    setIsLoading(true);
    props.onCommentEditing?.(props.comment.commentGuid!, DateTime.utc().toISO(), editedCommentText);

    (await editComment(props.comment.commentGuid, editedCommentText)).match(
      () => props.onCommentEdited?.(),
      (error) => setError(error)
    );

    setIsLoading(false);
    setIsEditing(false);
    setEditedCommentText("");
  }

  return (
    <EditRow
      $isReadonly={isReadonly}
      $isHovering={isHovering}
      $isEditing={isEditing}
      direction="row"
      spacing={1}
      sx={{
        px: { xs: 0.5, sm: 1 },
        py: isEditing ? 0.75 : 0.5,
        alignItems: "flex-start",
        ...props.sx,
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Link href={Links.User(props.comment.user.userId)} underline="none">
        <Avatar sx={{ mt: "4px" }} user={props.comment.user} border />
      </Link>

      <Stack spacing={0.25} sx={{ minWidth: 0, width: "100%" }}>
        {!isEditing && (
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Stack
              direction="column"
              spacing={-0.25}
              sx={{
                minWidth: 0,
                alignItems: "baseline",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Link variant="body2" href={Links.User(props.comment.user.userId)} underline="none">
                {props.comment.user.displayName}
                {!props.comment.user.enabled && props.comment.showDeactivatedUserLabel && " (deactivated)"}
              </Link>
              <Typography variant="caption" sx={{ minWidth: 0 }} noWrap>
                {props.comment.timestampUtc.toLocal().toLocaleString(DateTime.DATETIME_FULL)}
              </Typography>
            </Stack>

            {!isReadonly && isHovering && (
              <>
                <IconButton
                  size="small"
                  sx={{ alignSelf: "center" }}
                  onClick={(e) => {
                    setMenuAnchor(e.currentTarget);
                  }}
                  disabled={isLoading}
                >
                  <MoreVertRounded />
                </IconButton>
                <Menu
                  anchorEl={menuAnchor}
                  open={Boolean(menuAnchor)}
                  onClose={() => {
                    setMenuAnchor(null);
                    setIsHovering(false);
                  }}
                >
                  <MenuItem onClick={handleEditClicked}>Edit</MenuItem>
                  <MenuItem onClick={handleDeleteClicked} sx={{ color: "error.main" }}>
                    Delete
                  </MenuItem>
                </Menu>
              </>
            )}
          </Stack>
        )}

        <Box>
          {props.comment.type === CommentType.HistoryLedgerItem && props.comment.text}
          {props.comment.type === CommentType.Comment && (
            <>
              {isEditing ? (
                <TextEditor
                  readonly={isLoading}
                  initialValue={editedCommentText}
                  onChange={(value) => setEditedCommentText(value)}
                />
              ) : (
                <TextViewer value={props.comment.text as string} />
              )}
            </>
          )}
          {!isEditing && props.comment.timestampUtc.toMillis() !== props.comment.lastModifiedUtc.toMillis() && (
            <Typography variant="caption">Edited</Typography>
          )}
        </Box>

        {isEditing && (
          <Stack direction="row" spacing={0.5} sx={{ justifyContent: "flex-end", pt: 0.25 }}>
            <Button variant="text" disabled={isLoading} onClick={handleCancelEditClicked}>
              Cancel
            </Button>
            <Button
              variant="tertiary"
              disabled={isLoading || isWhitespace(editedCommentText)}
              onClick={handleSaveEditClicked}
            >
              Save
            </Button>
          </Stack>
        )}
      </Stack>
    </EditRow>
  );
};

export { Comment };
