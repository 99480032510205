import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { EditProposalDto, FetchError, IssueDto } from "../../../types";
import { queryKeys, useAccessToken } from "../../..";
import { configuration } from "../../../../configuration";

type Variables = {
  guid: string;
  issueGuid: string;
  description: string;
};

const useEditProposalRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editProposalRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditProposalDto = {
      description: variables.description,
    };

    const response = await fetch(`${configuration.apiRootUrl}/proposals/${variables.guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editProposalRequest };
};

const useEditProposal = () => {
  const { editProposalRequest } = useEditProposalRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editProposalRequest,
    onMutate: async (variables) => {
      const cachedIssueDetailsData = optimisticallyUpdateIssueData(queryClient, variables);

      return { cachedIssueDetailsData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.issues.getIssueDetail(variables.issueGuid), context?.cachedIssueDetailsData);
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.issues.getIssueDetail(variables.issueGuid));
      queryClient.invalidateQueries(queryKeys.issues.getIssueFeed(variables.issueGuid));
    },
  });
};

async function optimisticallyUpdateIssueData(queryClient: QueryClient, variables: Variables) {
  await queryClient.cancelQueries(queryKeys.issues.getIssueDetail(variables.issueGuid));
  const cachedData = queryClient.getQueryData<IssueDto>(queryKeys.issues.getIssueDetail(variables.issueGuid));

  if (cachedData) {
    const optimisticallyUpdatedData: IssueDto = {
      ...cachedData,
      proposals: cachedData.proposals.map((proposal) => {
        if (proposal.guid === variables.guid) {
          return {
            ...proposal,
            description: variables.description,
          };
        }

        return proposal;
      }),
    };

    queryClient.setQueryData(queryKeys.issues.getIssueDetail(variables.issueGuid), optimisticallyUpdatedData);
    return cachedData;
  }
}

export { useEditProposal };
