import { Box, Link, SxProps, css, styled } from "@mui/material";
import { useState } from "react";

const Container = styled(Box)<{ $isHovering: boolean; $backgroundColor?: string; $backgroundColorOnHover?: string }>`
  // Set background colour based on whether the block is being hovered over
  ${({ $isHovering, $backgroundColor, $backgroundColorOnHover }) => {
    if ($isHovering && $backgroundColorOnHover) {
      return css`
        background-color: ${$backgroundColorOnHover};
      `;
    } else if ($backgroundColor) {
      return css`
        background-color: ${$backgroundColor};
      `;
    }
  }};

  transition: ${({ theme }) => theme.transitions.create("background-color")};
  position: relative;
  width: 100%;
`;

const Content = styled(Box)`
  height: 100%;

  a,
  button {
    position: relative;
    z-index: 2;
  }
`;

const LinkSpan = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`;

LinkSpan.defaultProps = {
  component: "span",
};

interface IBlockLinkProps {
  href: string;
  children?: React.ReactNode;
  backgroundColor?: string;
  backgroundColorOnHover?: string;
  sx?: SxProps;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const BlockLink = (props: IBlockLinkProps) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Container
      $isHovering={isHovering}
      $backgroundColor={props.backgroundColor}
      $backgroundColorOnHover={props.backgroundColorOnHover}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      sx={props.sx}
    >
      <Link href={props.href} onClick={props.onClick}>
        <LinkSpan></LinkSpan>
      </Link>
      <Content>{props.children}</Content>
    </Container>
  );
};

export { BlockLink };
export type { IBlockLinkProps };
