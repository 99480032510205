import React from "react";

interface ISignalRChatContext {
  sendChatMessage: (authorName: string, authorUserId: string, authorProfilePhoto: string, message: string) => void;
  onReceiveChatMessage: (
    handler: (authorName: string, authorUserId: string, authorProfilePhoto: string, message: string) => void
  ) => void;
  offReceiveChatMessage: (
    handler: (authorName: string, authorUserId: string, authorProfilePhoto: string, message: string) => void
  ) => void;
}

const SignalRChatContext = React.createContext<ISignalRChatContext>({
  sendChatMessage: () => null,
  onReceiveChatMessage: () => null,
  offReceiveChatMessage: () => null,
});

export { SignalRChatContext };
