import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { CommentSubject } from "../../constants";
import { useSignalR } from "../../hooks";
import { queryKeys, useGetActionItemFeed } from "../../http";
import { Feed } from "./Feed";

interface IProps {
  actionItemGuid: string;
}

const ActionItemFeed = (props: IProps) => {
  const { isLoading, data } = useGetActionItemFeed(props.actionItemGuid);
  const queryClient = useQueryClient();
  const signalR = useSignalR();

  useEffect(() => {
    const handleShouldRefetchComments = (actionItemGuid: string) => {
      queryClient.invalidateQueries(queryKeys.actionItems.getActionItemFeed(actionItemGuid));
    };

    signalR.onShouldRefetchComments(handleShouldRefetchComments);
    return () => signalR.offShouldRefetchComments(handleShouldRefetchComments);
  }, [props.actionItemGuid, queryClient, signalR]);

  return (
    <Feed
      subjectType={CommentSubject.ActionItem}
      subjectGuid={props.actionItemGuid}
      isLoading={isLoading}
      items={data?.feed ?? []}
      onCommentCreated={() => signalR.sendRefetchActionItem(props.actionItemGuid)}
      onCommentDeleted={() => signalR.sendRefetchActionItem(props.actionItemGuid)}
    />
  );
};

export { ActionItemFeed };
