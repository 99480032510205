import { useContext } from "react";
import { SignalRContext } from "../SignalRContext";
import { SignalRClientMethod, SignalRServerMethod } from "../constants";
import { SignalRActionItemsContext } from "./SignalRActionItemsContext";

interface IProps {
  children?: React.ReactNode;
}

const SignalRActionItemsProvider = (props: IProps) => {
  const context = useContext(SignalRContext);

  return (
    <SignalRActionItemsContext.Provider
      value={{
        sendRefetchActionItem: (...args) => context.invoke(SignalRServerMethod.SendRefetchActionItem, args),
        onShouldRefetchActionItem: (handler) =>
          context.registerHandler(SignalRClientMethod.ShouldRefetchActionItem, handler),
        offShouldRefetchActionItem: (handler) =>
          context.unregisterHandler(SignalRClientMethod.ShouldRefetchActionItem, handler),
      }}
    >
      {props.children}
    </SignalRActionItemsContext.Provider>
  );
};

export { SignalRActionItemsProvider };
