import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { matchPath, useLocation, useNavigate, useParams } from "react-router";
import { Links, Paths, TeamTab } from "../../../../constants";

interface IParams {
  slug: string;
}

const TeamNavBar = () => {
  const { slug } = useParams<keyof IParams>() as IParams;
  const navigate = useNavigate();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState<TeamTab>(TeamTab.Overview);

  useEffect(() => {
    setCurrentTab(getTabForPath(location.pathname));
  }, [location.pathname]);

  function getTabForPath(path: string) {
    if (!!matchPath(Paths.TeamStrategicMap, path)) {
      return TeamTab.StrategicMap;
    }

    if (!!matchPath(Paths.TeamImplementation, path)) {
      return TeamTab.Implementation;
    }

    if (!!matchPath(Paths.TeamMetrics, path)) {
      return TeamTab.Metrics;
    }

    if (!!matchPath(Paths.TeamResources, path)) {
      return TeamTab.Resources;
    }

    return TeamTab.Overview;
  }

  function handleTabChanged(_: React.SyntheticEvent, value: TeamTab) {
    setCurrentTab(value);

    switch (value) {
      case TeamTab.Overview:
        navigate(Links.Team(slug));
        break;
      case TeamTab.StrategicMap:
        navigate(Links.TeamStrategicMap(slug));
        break;
      case TeamTab.Implementation:
        navigate(Links.TeamImplementation(slug));
        break;
      case TeamTab.Metrics:
        navigate(Links.TeamMetrics(slug));
        break;
      case TeamTab.Resources:
        navigate(Links.TeamResources(slug));
        break;
    }
  }

  return (
    <Tabs value={currentTab} variant="scrollable" onChange={handleTabChanged}>
      <Tab label="Overview" value={TeamTab.Overview} disableRipple />
      <Tab label="Strategic Map" value={TeamTab.StrategicMap} disableRipple />
      <Tab label="Implementation" value={TeamTab.Implementation} disableRipple />
      <Tab label="Metrics" value={TeamTab.Metrics} disableRipple />
      <Tab label="Resources" value={TeamTab.Resources} disableRipple />
    </Tabs>
  );
};

export { TeamNavBar };
