import { Button, CardActions, CardContent, CardHeader, Divider, MenuItem, Stack, TextField } from "@mui/material";
import { v4 as newGuid } from "uuid";
import { Autocomplete, SmallInputLabel, Typography } from "../../../../components";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { ILocation } from "../../../../models";
import { LocationPicker } from "../../../UserPages/UserSettingsPage/components";
import { DateTime } from "luxon";
import { useSession } from "../../../../hooks";
import { useGetUser, useGetTeams, useGetRolesForUser, useCreateSurveyInstanceResponse } from "../../../../http";

interface IProfileVerificationFormState {
  location: ILocation | null;
  joinedDate: DateTime | null;
  team: { slug: string; name: string } | null;
}

interface IProps {
  surveyName: string;
  instanceGuid: string;
}

const SurveyProfileVerificationForm = (props: IProps) => {
  const { userId: sessionUserId } = useSession();
  const { data: userDetails, isLoading: isLoadingUserDetails } = useGetUser(sessionUserId);
  const { data: teams, isLoading: isLoadingTeams } = useGetTeams(sessionUserId);
  const { data: roles, isLoading: isLoadingRoles } = useGetRolesForUser(sessionUserId);
  const { mutate: createSurveyInstanceResponse } = useCreateSurveyInstanceResponse();

  const { formState, control, handleSubmit, reset, getValues } = useForm<IProfileVerificationFormState>({
    defaultValues: {
      location: {
        countryCode: null,
        stateCode: null,
        cityName: null,
      },
      joinedDate: null,
      team: null,
    },
  });

  useEffect(() => {
    if (userDetails) {
      reset({
        ...getValues(),
        joinedDate: userDetails.joinedDate,
        location: {
          countryCode: userDetails.countryCode,
          stateCode: userDetails.stateCode,
          cityName: userDetails.cityName,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUserDetails]);

  useEffect(() => {
    if (roles) {
      reset({
        ...getValues(),
        team: roles.find((x) => x.isPrimaryRole)?.parentTeam,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingRoles]);

  function handleContinueClicked() {
    handleSubmit((data) => {
      createSurveyInstanceResponse({
        guid: newGuid(),
        userId: sessionUserId,
        cityName: data.location?.cityName ?? undefined,
        stateCode: data.location?.stateCode ?? undefined,
        countryCode: data.location?.countryCode ?? undefined,
        joinedDate: data.joinedDate!.toISODate(),
        surveyInstanceGuid: props.instanceGuid,
        teamSlug: data.team!.slug,
      });
    })();
  }

  return (
    <>
      <CardHeader title={<Typography variant="h6">{props.surveyName}</Typography>} />
      <Divider />
      <CardContent sx={{ flex: 1, overflow: "auto", p: 0 }}>
        <Stack spacing={0.5} sx={{ p: 1 }}>
          <Typography sx={{ marginBottom: 1 }}>
            This survey is <strong>confidential</strong>.
          </Typography>
          <Typography>Login to CODE and Profile Verification is required for:</Typography>
          <ul style={{ marginTop: 0, paddingLeft: "30px" }}>
            <li>Ensuring one response per person</li>
            <li>Enabling your personal, private history of previous survey responses</li>
            <li>Automating the recurring capture of team, tenure, and location per response</li>
          </ul>
          <Typography>
            Only survey administrators have access to the raw response data. Outside of your private profile, your
            response (in combination with tenure and location) will only ever be used in aggregate.
          </Typography>
          <Typography>
            Your text responses are shared publically and associated with your team's name to ensure your feedback
            actionable and contextual. These are only displayed if your team has at least three responses; otherwise,
            they roll up to the nearest parent team with three or more responses.
          </Typography>
        </Stack>
        <Divider />
        <Stack spacing={1} sx={{ p: 1 }}>
          <Typography>
            Please complete or update your personal profile with current information before you proceed with the survey.
          </Typography>
          <Stack spacing={0.25}>
            <SmallInputLabel>Location</SmallInputLabel>
            <Controller
              name="location"
              control={control}
              rules={{
                validate: (value) =>
                  (value != null && value.countryCode != null && value.cityName != null) || "Location is required",
              }}
              render={({ field }) => (
                <LocationPicker
                  value={field.value}
                  onChange={field.onChange}
                  error={formState.errors["location"] !== undefined}
                  helperText={formState.errors["location"]?.message}
                />
              )}
            />
          </Stack>
          <Stack>
            <SmallInputLabel>Date Joined</SmallInputLabel>
            <Controller
              name="joinedDate"
              control={control}
              rules={{ required: { message: "Date joined is required", value: true } }}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DesktopDatePicker
                    format="MMMM d, yyyy"
                    value={field.value}
                    onChange={field.onChange}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: formState.errors["joinedDate"] !== undefined,
                        helperText: formState.errors["joinedDate"]?.message,
                      },
                      openPickerButton: {
                        size: "small",
                      },
                    }}
                  />
                </LocalizationProvider>
              )}
            />
          </Stack>
          <Stack>
            <SmallInputLabel>Home Team</SmallInputLabel>
            <Controller
              name="team"
              control={control}
              rules={{ required: { message: "Team is required", value: true } }}
              render={({ field }) => (
                <Autocomplete
                  isLoading={isLoadingTeams}
                  disabled={isLoadingTeams}
                  options={teams?.filter((x) => x.isMember) || []}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option: any) => (
                    <MenuItem key={option.slug} component="li" {...props}>
                      <Typography>{option.name}</Typography>
                    </MenuItem>
                  )}
                  onChange={(_, option) => field.onChange(option)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select a team..."
                      inputProps={{ ...params.inputProps }}
                      error={formState.errors["team"] !== undefined}
                      helperText={formState.errors["team"]?.message}
                    />
                  )}
                  value={field.value}
                  isOptionEqualToValue={(option, value) => option.slug === value.slug}
                />
              )}
            />
            <Typography variant="caption">Home Team is the team where you have your Primary Role.</Typography>
          </Stack>
        </Stack>
      </CardContent>
      <Divider />
      <CardActions>
        <Stack direction="row-reverse" sx={{ width: "100%" }}>
          <Button variant="contained" onClick={handleContinueClicked}>
            Continue to survey
          </Button>
        </Stack>
      </CardActions>
    </>
  );
};

export { SurveyProfileVerificationForm };
