import { useHttp } from "../http";
import { ApplicationError, IUser, Result } from "../models";

const useUsers = () => {
  const { getUsersRequest } = useHttp();

  async function getUsers(): Promise<Result<IUser[], ApplicationError>> {
    try {
      var response = await getUsersRequest();

      if (response.ok && response.data) {
        return Result.success(response.data.users);
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  return {
    getUsers,
  };
};

export { useUsers };
