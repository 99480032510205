interface ISettingsData {
  homeSettings: IHomeSettings;
  sideMenuSettings: ISideMenuSettings;
  drawerSettings: IDrawerSettings;
  teamSettings: ITeamSettings;
  viewPlanSettings: {
    [planGuid: string]: IViewPlanSettings | undefined;
  };
  editOpspSettings: IEditOpspSettings;
  searchSettings: ISearchSettings;
  featureFlags: {
    [featureFlag in FeatureFlag]: boolean;
  };
}

type FeatureFlag = "surveys" | "meetings";

interface ITeamSettings {
  networkListView: boolean | undefined;
}

interface IDrawerSettings {
  width: number;
}

interface IViewPlanSettings {
  rowView?: boolean | undefined;
}

interface ISideMenuSettings {
  isExpanded: boolean;
  expandedWidth: number;
}

interface IEditOpspSettings {
  showHints: boolean | undefined;
}

interface IHomeSettings {
  visualizationMode: HomeVisualizationMode;
}

interface ISearchSettings {
  searchHistory: string[];
}

enum HomeVisualizationMode {
  Teams = "teams",
  TeamsAndPeople = "teams and people",
}

const useLocalStorage = () => {
  function getItem<T>(key: string) {
    const serializedItem = localStorage.getItem(key);

    if (serializedItem === null) {
      return null;
    }

    try {
      return JSON.parse(serializedItem) as T;
    } catch {
      return null;
    }
  }

  function setItem<T>(key: string, item: T) {
    const serializedItem = JSON.stringify(item);
    localStorage.setItem(key, serializedItem);
  }

  function getSettingsData() {
    return getItem<ISettingsData>("settings");
  }

  function setSettingsData(settings: ISettingsData) {
    setItem("settings", settings);
  }

  return {
    getSettingsData,
    setSettingsData,
  };
};

export { HomeVisualizationMode, useLocalStorage };
export type {
  IEditOpspSettings,
  IHomeSettings,
  ISearchSettings,
  ISettingsData,
  ISideMenuSettings,
  IDrawerSettings,
  ITeamSettings,
  IViewPlanSettings,
  FeatureFlag,
};
