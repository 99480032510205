import { useContext } from "react";
import { SignalRContext } from "../SignalRContext";
import { SignalRClientMethod, SignalRServerMethod } from "../constants";
import { SignalRCommentsContext } from "./SignalRCommentsContext";

interface IProps {
  children?: React.ReactNode;
}

const SignalRCommentsProvider = (props: IProps) => {
  const context = useContext(SignalRContext);

  return (
    <SignalRCommentsContext.Provider
      value={{
        sendRefetchComments: (...args) => context.invoke(SignalRServerMethod.SendRefetchComments, args),
        onShouldRefetchComments: (handler) =>
          context.registerHandler(SignalRClientMethod.ShouldRefetchComments, handler),
        offShouldRefetchComments: (handler) =>
          context.unregisterHandler(SignalRClientMethod.ShouldRefetchComments, handler),
      }}
    >
      {props.children}
    </SignalRCommentsContext.Provider>
  );
};

export { SignalRCommentsProvider };
