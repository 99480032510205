import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { IPlanConnectionPoint } from "../../../models";
import { FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";
import { GetConnectionPointsDtoV2 } from "../../types/plans";

const useGetPlanConnectionPointsRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getPlanConnectionPointsRequest({
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof queryKeys.plans.getConnectionPoints>>): Promise<GetConnectionPointsDtoV2> {
    const accessToken = await getAccessToken();
    const [{ slug }] = queryKey;

    const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/plan/connection-points`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getPlanConnectionPointsRequest };
};

const useGetPlanConnectionPoints = (slug?: string) => {
  const { getPlanConnectionPointsRequest } = useGetPlanConnectionPointsRequest();

  return useQuery({
    queryKey: queryKeys.plans.getConnectionPoints(slug),
    queryFn: getPlanConnectionPointsRequest,
    select: (data): IPlanConnectionPoint[] => {
      return data.connectionPoints;
    },
    enabled: slug !== undefined,
  });
};

export { useGetPlanConnectionPoints };
