import { HubConnection } from "@microsoft/signalr";
import React from "react";

interface ISignalRContext {
  connection: HubConnection | undefined;
  invoke: (methodName: string, args: any[]) => void;
  registerHandler: (methodName: string, handler: (...args: any[]) => any) => void;
  unregisterHandler: (methodName: string, handler: (...args: any[]) => any) => void;
  joinGroup: (groupName: string) => void;
  joinGroups: (groupNames: string[]) => void;
  leaveGroup: (groupName: string) => void;
  leaveGroups: (groupNames: string[]) => void;
}

const SignalRContext = React.createContext<ISignalRContext>({
  connection: undefined,
  invoke: () => null,
  registerHandler: () => null,
  unregisterHandler: () => null,
  joinGroup: () => null,
  joinGroups: () => null,
  leaveGroup: () => null,
  leaveGroups: () => null,
});

export { SignalRContext };
