import { MenuOpenRounded, MenuRounded } from "@mui/icons-material";
import { Button, Divider, GlobalStyles, IconButton, Drawer as MuiDrawer, Stack, styled } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { ReactComponent as Logo } from "../assets/svg/logo.svg";
import { theme } from "../assets/theme";
import { useBreakpoints, useSettings } from "../hooks";

const minDrawerWidth = 140;
const maxDrawerWidth = 500;

const Dragger = styled(`div`)<{ $isDragging: boolean; $isVisible: boolean }>`
  width: 8px;
  padding: 0px 3px;
  cursor: ew-resize;
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  z-index: 100;

  > div {
    opacity: ${({ $isDragging, $isVisible }) => ($isDragging || $isVisible ? 1 : 0)};
  }

  &:hover {
    > div {
      opacity: 1;
    }
  }
`;

const DragIndicator = styled(`div`)`
  width: 3px;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.turquoise.main};

  transition: ${({ theme }) => theme.transitions.create("opacity")};
`;

const MobileNavButton = styled(Button)<{ $isNavigationDrawerOpen: boolean }>`
  /* Hide the menu button on larger screen sizes */
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: none;
  }

  display: ${({ $isNavigationDrawerOpen }) => ($isNavigationDrawerOpen ? "none" : "flex")};
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 50%;
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 60px;
  min-width: 60px;
  height: 60px;
  padding: 0;
`;

const Drawer = styled(MuiDrawer)<{ $isExpanded: boolean; $isDragging: boolean }>`
  transition: ${({ theme, $isExpanded, $isDragging }) =>
    $isDragging
      ? "none"
      : theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: $isExpanded ? theme.transitions.duration.enteringScreen : theme.transitions.duration.shorter,
        })};

  & .MuiDrawer-paper {
    background-color: ${({ theme }) => theme.palette.background.sideMenu};
    border: none;
    padding: 20px;
    padding-top: 12px;
    padding-right: ${({ $isExpanded }) => ($isExpanded ? "20px" : "20px")};
    overflow: hidden;
    justify-content: space-between;
    width: inherit;
    box-shadow: inset rgba(0, 0, 0, 0.05) -3px 0px 5px;
  }
`;

interface IProps {
  children: React.ReactNode;
}

const ExpandableDrawer = (props: IProps) => {
  const { sideMenuSettings, updateSideMenuSettings } = useSettings();
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggerVisible, setDraggerVisible] = useState(false);
  const { isMdUp } = useBreakpoints();
  const location = useLocation();
  const drawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sideMenuSettings.isExpanded) {
      setTimeout(() => {
        setDraggerVisible(true);
        setTimeout(() => {
          setDraggerVisible(false);
        }, 1000);
      }, 500);
    }
  }, [sideMenuSettings.isExpanded]);

  useEffect(() => {
    if (drawerRef.current) {
      drawerRef.current.style.width = sideMenuSettings.isExpanded ? `${sideMenuSettings.expandedWidth}px` : "80px";
    }
  }, [sideMenuSettings.expandedWidth, sideMenuSettings.isExpanded, isMdUp]);

  useEffect(() => {
    // Close the nav drawer when navigating on mobile
    if (!isMdUp) {
      updateSideMenuSettings({ ...sideMenuSettings, isExpanded: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  function handleMouseDown() {
    setIsDragging(true);
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  }

  function handleMouseUp() {
    setIsDragging(false);
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);

    if (drawerRef.current) {
      const drawerWidth = parseInt(drawerRef.current.style.width.replace("px", ""));
      updateSideMenuSettings({ ...sideMenuSettings, expandedWidth: drawerWidth });
    }
  }

  function handleMouseMove(event: MouseEvent) {
    event.preventDefault();

    if (drawerRef.current) {
      const newWidth = Math.min(Math.max(event.clientX, minDrawerWidth), maxDrawerWidth);
      drawerRef.current.style.width = `${newWidth}px`;
    }
  }

  function handleExpandClicked() {
    updateSideMenuSettings({ ...sideMenuSettings, isExpanded: !sideMenuSettings.isExpanded });
  }

  return (
    <>
      <Drawer
        variant={isMdUp ? "permanent" : "temporary"}
        ref={drawerRef}
        open={sideMenuSettings.isExpanded}
        onClose={() => {
          handleExpandClicked();
        }}
        $isExpanded={sideMenuSettings.isExpanded}
        $isDragging={isDragging}
        sx={{
          width: isMdUp ? undefined : "80vw",
        }}
      >
        {isDragging && <GlobalStyles styles={{ body: { userSelect: "none" } }} />}

        {sideMenuSettings.isExpanded && isMdUp && (
          <Dragger $isDragging={isDragging} $isVisible={isDraggerVisible} onMouseDown={handleMouseDown}>
            <DragIndicator />
          </Dragger>
        )}

        <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between", mb: 0.5, height: "40px" }}>
          <Logo />
          {isMdUp && (
            <IconButton onClick={handleExpandClicked}>
              {sideMenuSettings.isExpanded ? (
                <MenuOpenRounded style={{ fill: theme.palette.grey[400], height: 24, width: 24 }} />
              ) : (
                <MenuRounded style={{ fill: theme.palette.grey[400], height: 24, width: 24 }} />
              )}
            </IconButton>
          )}
        </Stack>

        <Divider />
        {props.children}
      </Drawer>
      {!isMdUp && (
        <MobileNavButton
          variant="contained"
          $isNavigationDrawerOpen={false}
          sx={{ zIndex: 2 }}
          onClick={(e) => {
            e.stopPropagation();
            handleExpandClicked();
          }}
        >
          {sideMenuSettings.isExpanded ? (
            <MenuOpenRounded style={{ height: 24, width: 24 }} />
          ) : (
            <MenuRounded style={{ height: 24, width: 24 }} />
          )}
        </MobileNavButton>
      )}
    </>
  );
};

export { ExpandableDrawer };
