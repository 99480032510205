import { Box, Button, Chip, Link, Stack, styled, SxProps, Typography } from "@mui/material";
import { MeetingListItemDto } from "../../../http";
import { Links } from "../../../constants";
import { AvatarGroup } from "../../../components";
import { DateTime } from "luxon";
import { useSession } from "../../../hooks";

const StyledStack = styled(Box)<{ $isMeetingInProgress: boolean }>`
  transition: ${({ theme }) => theme.transitions.create("background-color")};

  background-color: ${({ theme, $isMeetingInProgress }) =>
    $isMeetingInProgress ? theme.palette.primary.opacity5 : "unset"};
`;

interface IProps {
  meeting: MeetingListItemDto;
  sx?: SxProps;
  onClick?: (e: React.MouseEvent) => void;
}

const MeetingListItem = (props: IProps) => {
  const { userId } = useSession();

  const isMeetingInProgress = props.meeting.activeMeetingInstance != null;
  const meetingStartedAtDateTime = isMeetingInProgress
    ? DateTime.fromISO(props.meeting.activeMeetingInstance!.startedAtDateTimeUtc, { zone: "utc" })
        .toLocal()
        .toLocaleString(DateTime.DATETIME_FULL)
    : "";

  return (
    <StyledStack $isMeetingInProgress={isMeetingInProgress}>
      <Stack spacing={0.5} sx={{ ...props.sx }} alignItems="flex-start">
        <Stack>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="body2">{props.meeting.title}</Typography>
            {isMeetingInProgress && <Chip color="primary" label="In Progress" size="small" />}
          </Stack>

          <Typography>{props.meeting.description}</Typography>
        </Stack>
        {isMeetingInProgress ? (
          <>
            <Typography variant="caption">
              {`Started by `}
              <Link href={Links.User(props.meeting.activeMeetingInstance!.startedByUser.userId)}>
                {props.meeting.activeMeetingInstance!.startedByUser.displayName}
              </Link>
              {` on ${meetingStartedAtDateTime}`}
            </Typography>
            <AvatarGroup users={props.meeting.activeMeetingInstance!.currentAttendees} max={3} />
            {props.meeting.participantList.some((x) => x.userId === userId) && (
              <Button
                variant="contained"
                href={Links.Meeting(props.meeting.guid)}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  window.open(Links.Meeting(props.meeting.guid), "_blank", "width=1600,height=900");
                  return;
                }}
              >
                Join Meeting
              </Button>
            )}
          </>
        ) : (
          <>
            {props.meeting.lastMeetingDateTimeUtc != null && (
              <Typography variant="caption">{`${
                props.meeting.occurrencesCount
              } Occurences | Last Meeting ${DateTime.fromISO(props.meeting.lastMeetingDateTimeUtc, {
                zone: "utc",
              })
                .toLocal()
                .toLocaleString(DateTime.DATE_MED)}`}</Typography>
            )}

            {props.meeting.participantList.some((x) => x.userId === userId) && (
              <Button
                variant="tertiary"
                href={Links.Meeting(props.meeting.guid)}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  window.open(Links.Meeting(props.meeting.guid), "_blank", "width=1600,height=900");
                  return;
                }}
              >
                Start Meeting
              </Button>
            )}
          </>
        )}
      </Stack>
    </StyledStack>
  );
};

export { MeetingListItem };
