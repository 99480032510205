import { createFilterOptions, MenuItem, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Autocomplete } from ".";
import { TagType } from "../constants";
import { useGetTags } from "../http";

interface ITagOption {
  value: string;
  inputValue?: string;
}

interface IProps {
  value: string[];
  tagType: TagType;
  onChange?: (value: string[]) => void;
}

const filter = createFilterOptions<ITagOption>();

const TagPicker = (props: IProps) => {
  const { isLoading, data: tags } = useGetTags(props.tagType);
  const [userDefinedTags, setUserDefinedTags] = useState<ITagOption[]>([]);

  return (
    <Autocomplete
      isLoading={isLoading}
      disabled={isLoading}
      options={(tags ?? [])
        .map((x): ITagOption => ({ value: x }))
        .concat(userDefinedTags)
        .sort((a, b) => a.value.localeCompare(b.value))}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }

        if (option.inputValue) {
          return option.inputValue;
        }

        return option.value;
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;

        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.value || inputValue === option.inputValue);

        if (inputValue !== "" && !isExisting) {
          filtered.push({
            value: `Add "${inputValue.toUpperCase()}"`,
            inputValue: inputValue.toUpperCase(),
          });
        }

        return filtered;
      }}
      renderOption={(props, option) => (
        <MenuItem component="li" {...props} key={option.value}>
          <Typography>{option.value}</Typography>
        </MenuItem>
      )}
      onChange={(_, options) => {
        const mappedOptions = options.map((option) => {
          if (typeof option === "string") {
            const newTagOption = option;
            setUserDefinedTags([...userDefinedTags, { value: newTagOption }]);
            return newTagOption;
          } else if (option && option.inputValue) {
            const newTagOption = option.inputValue;
            setUserDefinedTags([...userDefinedTags, { value: newTagOption }]);
            return newTagOption;
          } else {
            return option.value;
          }
        });

        props.onChange && props.onChange(mappedOptions);
      }}
      renderInput={(params) => <TextField {...params} placeholder="" inputProps={{ ...params.inputProps }} />}
      value={props.value.map((x): ITagOption => ({ value: x }))}
      isOptionEqualToValue={(option, value) => {
        return option.value === value.value;
      }}
      multiple
      freeSolo
    />
  );
};

export { TagPicker };
