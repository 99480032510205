import { KeyboardArrowDownRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  Grid,
  Menu,
  MenuItem,
  CardHeader as MuiCardHeader,
  Skeleton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router";
import { QuestionType } from "../../../constants";
import { useCreateSurveyQuestion, useGetSurveyQuestions, useReorderSurveyQuestion } from "../../../http";
import { lexorank } from "../../../utilities";
import { QuestionItem, SurveyQuestionDrawerContent } from "./components";
import { SurveyQuestion } from "../../../models";
import { useDrawer } from "../../../components";

const CardHeader = styled(MuiCardHeader)`
  border-bottom-color: #c9c9c9;
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

const DroppableZone = styled(Stack)<{ $isDraggingOver: boolean }>`
  transition: ${({ theme }) => theme.transitions.create("background-color")};

  background-color: ${({ $isDraggingOver, theme }) =>
    $isDraggingOver ? theme.palette.primary.opacity5 : theme.palette.common.white};
  pointer-events: ${({ $isDraggingOver }) => ($isDraggingOver ? "none" : "auto")};
`;

interface IParams {
  guid: string;
}

interface ISurveyQuestionsPageContext {
  focusedQuestionItemGuid: string | undefined;
  selectedQuestionItemGuid: string | undefined;
  setFocusedQuestionItemGuid: (guid: string | undefined) => void;
  setSelectedQuestionItemGuid: (guid: string | undefined) => void;
}

const SurveyQuestionsPageContext = React.createContext<ISurveyQuestionsPageContext | undefined>(undefined);

const SurveyQuestionsPage = () => {
  const { showDrawer } = useDrawer();
  const { guid: surveyGuid } = useParams<keyof IParams>() as IParams;
  const [addMenuAnchor, setAddMenuAnchor] = useState<HTMLElement | null>(null);
  const [selectedQuestionItemGuid, setSelectedQuestionItemGuid] = useState<string | undefined>(undefined);
  const [focusedQuestionItemGuid, setFocusedQuestionItemGuid] = useState<string | undefined>(undefined);
  const { data, isLoading: isLoadingQuestions } = useGetSurveyQuestions(surveyGuid);
  const { mutate: reorderSurveyQuestion } = useReorderSurveyQuestion();
  const { mutate: createSurveyQuestion } = useCreateSurveyQuestion();
  const [questions, setQuestions] = useState<SurveyQuestion[]>([]);
  const container = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data !== undefined) {
      setQuestions(data);
    }
  }, [data]);

  function handleDragEnd(result: DropResult) {
    const { destination, source, draggableId } = result;

    if (
      questions === undefined ||
      !destination ||
      (destination.droppableId === source.droppableId && destination.index === source.index)
    ) {
      return;
    }

    let questionItem = questions?.find((x) => x.guid === draggableId);

    if (questionItem === undefined) {
      return;
    }

    const newSortOrder = lexorank.getRank(questions, source.index, destination.index);

    setQuestions([
      ...questions.map((question) => {
        if (question.guid === draggableId) {
          return {
            ...question,
            sortOrder: newSortOrder,
          };
        }

        return question;
      }),
    ]);

    reorderSurveyQuestion({
      surveyGuid: surveyGuid,
      guid: draggableId,
      sortOrder: newSortOrder,
    });
  }

  function handleAddTitleAndDescriptionClicked() {
    setAddMenuAnchor(null);
    createSurveyQuestion({
      surveyGuid: surveyGuid,
      sortOrder: lexorank.getNextRank(questions),
      questionTypeId: QuestionType.TitleAndDescription,
    });
  }

  function handleAddQuestionClicked() {
    setAddMenuAnchor(null);
    createSurveyQuestion({
      surveyGuid: surveyGuid,
      sortOrder: lexorank.getNextRank(questions),
      questionTypeId: QuestionType.LinearScale,
    });
  }

  function handleQuestionClicked(question: SurveyQuestion) {
    showDrawer({
      title: (
        <Typography variant="h6">
          {question.questionTypeId === QuestionType.TitleAndDescription ? "Title & Description" : "Question Details"}
        </Typography>
      ),
      content: <SurveyQuestionDrawerContent questionGuid={question.guid} surveyGuid={surveyGuid} />,
    });
  }

  return (
    <SurveyQuestionsPageContext.Provider
      value={{
        focusedQuestionItemGuid,
        selectedQuestionItemGuid,
        setSelectedQuestionItemGuid,
        setFocusedQuestionItemGuid,
      }}
    >
      <Grid ref={container} container spacing={1} sx={{ justifyContent: "center", flexWrap: "wrap" }}>
        <Grid item xs={12} sm={10} md={8}>
          <Stack direction="column" spacing={1.5}>
            <Card>
              <CardHeader title={<Typography variant="h6">Questions</Typography>} />
              <Box sx={{ p: 0 }}>
                {isLoadingQuestions
                  ? [...Array(3)].map((_, i) => (
                      <Stack key={i} px={1.25} my={1} spacing={1}>
                        <Box>
                          <Skeleton variant="rectangular" height={30} width={100} sx={{ mb: 0.75 }} />
                          <Skeleton variant="rectangular" height={90} />
                        </Box>
                      </Stack>
                    ))
                  : questions && (
                      <DragDropContext onDragEnd={handleDragEnd}>
                        {questions.length === 0 ? (
                          <Typography sx={{ p: 1 }}>No content yet...</Typography>
                        ) : (
                          <Droppable key={surveyGuid} droppableId={surveyGuid}>
                            {(provided, snapshot) => (
                              <DroppableZone
                                $isDraggingOver={snapshot.isDraggingOver}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {questions
                                  .sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))
                                  .map((question, index) => (
                                    <QuestionItem
                                      key={question.guid}
                                      index={index}
                                      question={question}
                                      onClick={() => handleQuestionClicked(question)}
                                    />
                                  ))}
                                {provided.placeholder}
                              </DroppableZone>
                            )}
                          </Droppable>
                        )}
                      </DragDropContext>
                    )}
              </Box>
              <CardActions>
                <Button
                  disabled={isLoadingQuestions}
                  variant="tertiary"
                  onClick={(e) => setAddMenuAnchor(e.currentTarget)}
                  endIcon={<KeyboardArrowDownRounded />}
                >
                  Add
                </Button>
                <Menu
                  anchorEl={addMenuAnchor}
                  open={Boolean(addMenuAnchor)}
                  onClose={() => setAddMenuAnchor(null)}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  transformOrigin={{ vertical: "top", horizontal: "left" }}
                >
                  <MenuItem onClick={handleAddTitleAndDescriptionClicked}>Title and Description</MenuItem>
                  <MenuItem onClick={handleAddQuestionClicked}>Question</MenuItem>
                </Menu>
              </CardActions>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </SurveyQuestionsPageContext.Provider>
  );
};

const useSurveyQuestionsPageContext = () => {
  const context = useContext(SurveyQuestionsPageContext);

  if (context === undefined) {
    throw new Error("SurveyQuestionsPageContext is not initialized.");
  }

  return context;
};

export { SurveyQuestionsPage, useSurveyQuestionsPageContext };
