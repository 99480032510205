import { Card, CardHeader, Divider, Grid, Stack, Typography } from "@mui/material";
import { useTeamPageLayoutContext } from "..";
import { TeamActionItemsList, TeamIssuesList, TeamMeetingsList } from "./components";
import { useSettings } from "../../../hooks";

const TeamImplementationPage = () => {
  const { team } = useTeamPageLayoutContext();
  const { featureFlags } = useSettings();

  return (
    <Grid container spacing={1} sx={{ justifyContent: "center" }}>
      <Grid item xs={12} md={8}>
        <Card sx={{ width: "100%" }}>
          <CardHeader title={<Typography variant="h6">Processing</Typography>} />
          <Divider />
          <Stack spacing={{ xs: 0.5, sm: 1 }} sx={{ p: { xs: 0.5, sm: 1 } }}>
            <TeamActionItemsList teamSlug={team?.slug} />
            <TeamIssuesList teamSlug={team?.slug} teamName={team?.name} />
          </Stack>
        </Card>
      </Grid>
      {featureFlags.meetings && (
        <Grid item xs={12} md={4}>
          <Card sx={{ width: "100%" }}>
            <CardHeader title={<Typography variant="h6">Meetings</Typography>} />
            <Divider />
            <TeamMeetingsList teamSlug={team?.slug} />
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export { TeamImplementationPage };
