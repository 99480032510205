import { Button, CardActions, Divider } from "@mui/material";
import { DateTime } from "luxon";
import { ViewList } from "../../../../components";
import { useSession } from "../../../../hooks";
import { MeetingListItem } from "../../../MeetingsPages/components/MeetingListItem";
import { GetMeetingsListDto } from "../../../../http/types/meetings/GetMeetingsListDto";

interface IProps {
  teamSlug: string | undefined;
}

const TeamMeetingsList = (props: IProps) => {
  const user = useSession();
  //ToDo: Retrieve meeting list for team slug from endpoint to replace mocked data
  // const { data, isLoading } = useGetMeetingsForTeam(props.teamSlug);

  //ToDo: SignalR for Meeting Start/End, User Join/Leave, Meeting Settings Changed.

  let currentDateTime = DateTime.now().toUTC();

  let data: GetMeetingsListDto = {
    meetings: [
      {
        guid: "meetingGuid1",
        title: "Implementation Weekly",
        description: "Talk about implementation",
        administrators: [],
        participantList: [
          {
            userId: user.userId,
            displayName: user.displayName,
            profilePhotoSmallUrl: user.profilePhotoSmallUrl,
            enabled: true,
          },
        ],
        activeMeetingInstance: {
          guid: "",
          startedByUser: {
            userId: user.userId,
            displayName: user.displayName,
            profilePhotoSmallUrl: user.profilePhotoSmallUrl,
            enabled: true,
          },
          startedAtDateTimeUtc: currentDateTime.toISO(),
          currentAttendees: [
            {
              userId: user.userId,
              displayName: user.displayName,
              profilePhotoSmallUrl: user.profilePhotoSmallUrl,
              enabled: true,
            },
          ],
        },
      },
      {
        guid: "meetingGuid2",
        title: "Strategic Annual Planning",
        description: "Team meets on weekly basis to review metrics, actions, and process issues and proposals",
        administrators: [],
        participantList: [],
        occurrencesCount: 32,
        lastMeetingDateTimeUtc: currentDateTime.minus({ days: 2 }).toISO(),
      },
      {
        guid: "meetingGuid3",
        title: "Meeting We Never Have",
        description: "Why does this meeting even exist?",
        administrators: [],
        participantList: [],
      },
    ],
  };

  function handleNewMeetingClicked() {
    //ToDo: Create new meeting implementation
  }

  return (
    <>
      <ViewList
        dataSource={data.meetings}
        divider={<Divider variant="dashed" sx={{ mx: 1 }} />}
        renderItem={(meeting) => (
          <MeetingListItem key={meeting.guid} meeting={meeting} sx={{ p: { xs: 0.5, sm: 1 } }} />
        )}
      />
      <Divider />
      <CardActions>
        <Button variant="outlined" onClick={handleNewMeetingClicked}>
          New Meeting Format
        </Button>
      </CardActions>
    </>
  );
};

export { TeamMeetingsList };
