import React from "react";

interface ISignalRIssuesContext {
  sendRefetchTeamIssues: (teamSlug: string) => void;
  onShouldRefetchTeamIssues: (handler: (teamSlug: string) => void) => void;
  offShouldRefetchTeamIssues: (handler: (teamSlug: string) => void) => void;
}

const SignalRIssuesContext = React.createContext<ISignalRIssuesContext>({
  sendRefetchTeamIssues: () => null,
  onShouldRefetchTeamIssues: () => null,
  offShouldRefetchTeamIssues: () => null,
});

export { SignalRIssuesContext };
