import { useMutation } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { CreatePageViewDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";

type Variables = {
  userGuid: string;
  sessionGuid?: string;
  timestampUtc: string;
  pageTitle: string;
  path: string;
  userAgent: string;
};

const useCreatePageViewRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createPageViewRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();
    const body: CreatePageViewDto = {
      userGuid: variables.userGuid,
      sessionGuid: variables.sessionGuid,
      timestampUtc: variables.timestampUtc,
      pageTitle: variables.pageTitle,
      path: variables.path,
      userAgent: variables.userAgent,
    };

    const response = await fetch(`${configuration.apiRootUrl}/analytics/page-view`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { createPageViewRequest };
};

const useCreatePageView = () => {
  const { createPageViewRequest } = useCreatePageViewRequest();

  return useMutation({
    mutationFn: createPageViewRequest,
  });
};

export { useCreatePageView };
