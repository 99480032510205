import { Link, Avatar as MuiAvatar, SxProps, Tooltip, Typography, css, styled, useTheme } from "@mui/material";
import { Links } from "../constants";
import { IUser } from "../models";

const SmallPhotoSizeInPx = 32;
const LargePhotoSizeInPx = 54;

const SmallBorderSizeInPx = 2;
const LargeBorderSizeInPx = 3;

const SmallStringAvatarSizeInPx = SmallPhotoSizeInPx + 2 * SmallBorderSizeInPx;
const LargeStringAvatarSizeInPx = LargePhotoSizeInPx + 2 * LargeBorderSizeInPx;

const StyledMuiAvatar = styled(MuiAvatar)<{ $border: boolean | undefined; $size: "small" | "large" | undefined }>`
  box-sizing: content-box;
  height: ${({ $size }) => ($size === "small" ? `${SmallPhotoSizeInPx}px` : `${LargePhotoSizeInPx}px`)};
  width: ${({ $size }) => ($size === "small" ? `${SmallPhotoSizeInPx}px` : `${LargePhotoSizeInPx}px`)};

  ${({ $border, $size }) =>
    $border &&
    css`
      background: ${({ theme }) =>
        `linear-gradient(white, white) padding-box, linear-gradient(315deg, ${theme.palette.wine.main}, ${theme.palette.error.main}) border-box`};

      border-color: transparent;
      border-style: solid;
      border-width: ${$size === "small" ? `${SmallBorderSizeInPx}px` : `${LargeBorderSizeInPx}px`};

      &:before {
        border-color: #fff;
        border-radius: 50%;
        border-style: solid;
        border-width: ${$size === "small" ? `${SmallBorderSizeInPx}px` : `${LargeBorderSizeInPx}px`};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
      }
    `}
`;

interface IProps {
  user: IUser;
  tooltip?: boolean;
  border?: boolean;
  size?: "small" | "large";
  sx?: SxProps;
}

const Avatar = (props: IProps) => {
  const { size = "small" } = props; // Define a default value of "small" for the variant prop
  const theme = useTheme();

  const profilePhotoUrl =
    size === "large"
      ? props.user?.profilePhotoLargeUrl ?? props.user?.profilePhotoSmallUrl
      : props.user?.profilePhotoSmallUrl;

  function stringAvatar(name: string) {
    const firstName = name.split(" ")[0][0];
    const lastName = name.split(" ")[1]?.[0] ?? "";

    return {
      sx: {
        ...props.sx,
        backgroundColor: props.user!.enabled ? theme.palette.wine.main : theme.palette.grey[500],
        height: `${size === "small" ? SmallStringAvatarSizeInPx : LargeStringAvatarSizeInPx}px`,
        width: `${size === "small" ? SmallStringAvatarSizeInPx : LargeStringAvatarSizeInPx}px`,
      } as SxProps,
      children: (
        <Typography>
          {firstName}
          {lastName}
        </Typography>
      ),
    };
  }

  function drawAvatar() {
    return profilePhotoUrl ? (
      <StyledMuiAvatar
        $border={props.border}
        $size={size}
        sx={{ ...props.sx }}
        style={!props.user.enabled ? { filter: "grayscale(100%)" } : {}}
        alt={props.user.displayName}
        src={profilePhotoUrl}
      />
    ) : (
      <StyledMuiAvatar
        $border={undefined}
        $size={size}
        alt={props.user.displayName}
        {...stringAvatar(props.user.displayName)}
      />
    );
  }

  return props.tooltip ? (
    <Tooltip title={<Link href={Links.User(props.user.userId)}>{props.user.displayName}</Link>}>{drawAvatar()}</Tooltip>
  ) : (
    drawAvatar()
  );
};

export { Avatar };
