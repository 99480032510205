import { AddCircle, ExpandMoreRounded } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { CreateIssueModal } from "../issues";
import { CreateActionToDoModal } from "./CreateActionToDoModal";

interface IProps {
  context: {
    teamName: string;
    teamSlug: string;
    actionItemGuid: string;
    actionItemDescription: string;
  };
}

const ActionItemSupportingItemAddButton = (props: IProps) => {
  const [addMenuAnchor, setAddMenuAnchor] = useState<HTMLElement | null>(null);
  const [isIssueModelVisible, setIsIssueModalVisible] = useState(false);
  const [isActionModelVisible, setIsActionModalVisible] = useState(false);

  function handleAddIssueClicked() {
    setAddMenuAnchor(null);
    setIsIssueModalVisible(true);
  }

  function handleAddActionClicked() {
    setAddMenuAnchor(null);
    setIsActionModalVisible(true);
  }

  function handleCreateIssueSettled() {
    setIsIssueModalVisible(false);
  }

  function handleCreateActionSettled() {
    setIsActionModalVisible(false);
  }

  return (
    <>
      <Button
        variant="tertiary"
        startIcon={<AddCircle />}
        endIcon={<ExpandMoreRounded />}
        sx={{ alignSelf: "start" }}
        onClick={(e) => setAddMenuAnchor(e.currentTarget)}
      >
        Add Item
      </Button>

      <Menu
        anchorEl={addMenuAnchor}
        open={Boolean(addMenuAnchor)}
        onClose={() => setAddMenuAnchor(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={handleAddIssueClicked}>Add Issue</MenuItem>
        <MenuItem onClick={handleAddActionClicked}>Add Action</MenuItem>
      </Menu>

      <CreateIssueModal
        visible={isIssueModelVisible}
        context={props.context}
        onAdded={handleCreateIssueSettled}
        onCancelled={handleCreateIssueSettled}
      />

      <CreateActionToDoModal
        visible={isActionModelVisible}
        context={props.context}
        onAdded={handleCreateActionSettled}
        onCancelled={handleCreateActionSettled}
      />
    </>
  );
};

export { ActionItemSupportingItemAddButton };
