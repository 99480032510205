import { Box, Divider, Popper, Stack, styled, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetPlanConnectionPoints, useGetTeams } from "../../http";
import { IPlanConnectionPoint, ITeamSummary } from "../../models";
import { Autocomplete } from "../Autocomplete";
import { isWhitespace } from "../textEditor";

const MenuItem = styled(Box)`
  &:first-child {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
  }

  &:last-child {
    margin-bottom: 2px;
  }
` as typeof Box;

interface IProps {
  disabled?: boolean;
  defaultContext?: {
    teamSlug: string;
    actionItemGuid: string;
  };
  onSelect: (connectionPoint: IPlanConnectionPoint | null) => void;
}

const ContextPicker = (props: IProps) => {
  const [selectedTeam, setSelectedTeam] = useState<ITeamSummary | null>(null);
  const [selectedConnectionPoint, setSelectedConnectionPoint] = useState<IPlanConnectionPoint | null>(null);
  const { data: teams, isLoading: isLoadingTeams } = useGetTeams(undefined);
  const { data: connectionPoints, isLoading: isLoadingConnectionPoints } = useGetPlanConnectionPoints(
    selectedTeam?.slug
  );
  const theme = useTheme();

  useEffect(() => {
    if (props.defaultContext) {
      setSelectedTeam(teams?.find((x) => x.slug === props.defaultContext?.teamSlug) ?? null);
    } else {
      setSelectedTeam(null);
    }
  }, [props.defaultContext, teams]);

  useEffect(() => {
    if (props.defaultContext) {
      const connectionPoint = connectionPoints?.find((x) => x.guid === props.defaultContext?.actionItemGuid) ?? null;
      setSelectedConnectionPoint(connectionPoint);
      props.onSelect(connectionPoint);
    } else {
      setSelectedConnectionPoint(null);
      props.onSelect(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultContext, connectionPoints]);

  function handleTeamChanged(team: ITeamSummary | null) {
    setSelectedTeam(team);
    setSelectedConnectionPoint(null);
  }

  function handleConnectionPointChanged(connectionPoint: IPlanConnectionPoint | null) {
    setSelectedConnectionPoint(connectionPoint);
    props.onSelect(connectionPoint);
  }

  return (
    <Stack spacing={0.5}>
      <Autocomplete
        isLoading={isLoadingTeams}
        disabled={isLoadingTeams || props.disabled}
        options={teams ?? []}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => (
          <MenuItem {...props} key={option.slug} component="li">
            <Stack>
              <Typography>{option.name}</Typography>
              {option.planGuid === null && <Typography variant="caption">This team has no strategic map</Typography>}
            </Stack>
          </MenuItem>
        )}
        getOptionDisabled={(option) => option.planGuid === null}
        isOptionEqualToValue={(option, value) => option.slug === value.slug}
        renderInput={(params) => (
          <TextField {...params} placeholder="Select a team..." inputProps={{ ...params.inputProps }} />
        )}
        onChange={(_, value) => handleTeamChanged(value)}
        PopperComponent={(props) => (
          <Popper {...props} style={{ ...props.style, margin: 0, padding: 0, minWidth: "300px" }} />
        )}
        value={selectedTeam}
      />

      <Autocomplete
        isLoading={!!selectedTeam && isLoadingConnectionPoints}
        disabled={!selectedTeam || !connectionPoints || props.disabled}
        groupBy={(option) => option.sectionName!}
        options={connectionPoints?.filter((x) => !isWhitespace(x.description)) ?? []}
        getOptionLabel={(option) => option.description}
        renderGroup={(group) => (
          <Box key={group.key} sx={{ pb: 0.25 }}>
            <Stack
              spacing={0.2}
              sx={{ px: 0.5, pt: 0.75, position: "sticky", top: 0, backgroundColor: theme.palette.common.white }}
            >
              <Typography variant="caption">{group.group.toLocaleUpperCase()}</Typography>
              <Divider />
            </Stack>
            {group.children}
          </Box>
        )}
        renderOption={(props, option) => (
          <MenuItem {...props} key={option.guid} component="li">
            <Typography>{option.description}</Typography>
          </MenuItem>
        )}
        isOptionEqualToValue={(option, value) => option.guid === value.guid}
        renderInput={(params) => (
          <TextField {...params} placeholder="Select a strategic map item..." inputProps={{ ...params.inputProps }} />
        )}
        onChange={(_, value) => handleConnectionPointChanged(value)}
        PopperComponent={(props) => (
          <Popper {...props} style={{ ...props.style, margin: 0, padding: 0, minWidth: "300px" }} />
        )}
        value={selectedConnectionPoint}
      />
    </Stack>
  );
};

export { ContextPicker };
