import { MoreVertRounded, OpenInBrowserRounded } from "@mui/icons-material";
import {
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  SxProps,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { matchPath, useLocation, useNavigate } from "react-router";
import {
  ActionItemDrawerContent,
  ContextProperty,
  InlineTextField,
  LinkBehavior,
  StatusPicker,
  isWhitespace,
  useDrawer,
} from "..";
import { DEFAULT_ACTION_ITEM_STATUS, Links, Paths, SupportingItemType } from "../../constants";
import { useBreakpoints, useSession } from "../../hooks";
import { useDeleteActionItem, useEditActionItem, useEditActionItemOwners, useGetActionItemSummary } from "../../http";
import { useDestructiveConfirm } from "../../utilities";
import { ActionItemIcons } from "./ActionItemIcons";

const StyledStack = styled(Stack)<{ $isPastView: boolean }>`
  background-color: ${({ $isPastView }) => ($isPastView ? "transparent" : undefined)};
  transition: ${({ theme }) => theme.transitions.create("background-color")};

  .editMenuIcon {
    opacity: 0;
  }

  &:hover {
    background-color: ${({ $isPastView, theme }) => ($isPastView ? "transparent" : theme.palette.primary.opacity5)};

    .editMenuIcon {
      opacity: 1;
    }
  }
`;

interface IProps {
  actionItemGuid: string;
  planGuid?: string;
  canEditPlan?: boolean;
  canEdit: boolean;
  isPastView?: boolean;
  isFocused: boolean;
  onBlur?: () => void;
  sx?: SxProps;
}

const ActionItem = (props: IProps) => {
  const { showDrawer, closeDrawer } = useDrawer();
  const { data: actionItem, isLoading } = useGetActionItemSummary(props.actionItemGuid);
  const { userId, planGuid: userPlanGuid } = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const { mutate: editActionItem } = useEditActionItem();
  const { mutate: deleteActionItem } = useDeleteActionItem();
  const { mutate: editActionItemOwners } = useEditActionItemOwners();
  const theme = useTheme();
  const confirm = useDestructiveConfirm();
  const ref = useRef<HTMLInputElement>(null);
  const { isMdUp } = useBreakpoints();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (props.isFocused) {
      ref.current?.focus();
    }
  }, [props.isFocused]);

  function handleActionItemClicked() {
    showDrawer({
      title: (
        <Button
          variant="tertiary"
          endIcon={<OpenInBrowserRounded />}
          onClick={() => navigate(Links.ActionItemDetail(props.actionItemGuid))}
        >
          Open Page
        </Button>
      ),
      content: <ActionItemDrawerContent actionItemGuid={props.actionItemGuid} planGuid={props.planGuid} />,
    });
  }

  function handleActionItemBlurred(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (props.canEdit) {
      editActionItem({
        guid: props.actionItemGuid,
        description: e.target.value,
        modifiedByUserId: userId,
      });
    }

    props.onBlur?.();
  }

  async function handleDeleteClicked(e: React.MouseEvent) {
    setMenuAnchor(null);
    e.stopPropagation();

    try {
      await confirm({
        title: "Delete Card",
        content: "Are you sure you want to delete this card?",
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    if (isLoading || actionItem === undefined) {
      return;
    }

    // Close the drawer in case it's open.
    closeDrawer();

    deleteActionItem({
      guid: actionItem.guid,
      modifiedByUserId: userId,
      planGuid: props.planGuid,
      teamSlug: actionItem.team?.teamSlug,
    });
  }

  async function removeSelfFromOwnership(e: React.MouseEvent) {
    setMenuAnchor(null);
    e.stopPropagation();

    if (isLoading || actionItem === undefined) {
      return;
    }

    let resultingOwnerList = actionItem.owners.filter((x) => x.userId !== userId);

    if (resultingOwnerList.length <= 0 && actionItem.teamPlanSection == null) {
      try {
        await confirm({
          dialogActionsProps: {
            sx: {
              justifyContent: "space-between",
            },
          },
          title: "Remove Ownership",
          content: (
            <Typography>
              Removing the last owner from a personal card will delete the card. Are you sure you want to delete this
              card?
            </Typography>
          ),
          confirmationText: "Delete Card",
          confirmationButtonProps: {
            color: "error",
          },
          cancellationText: "Cancel",
        });
      } catch {
        return;
      }

      deleteActionItem({
        guid: actionItem.guid,
        modifiedByUserId: userId,
        planGuid: props.planGuid,
      });
    } else {
      editActionItemOwners({
        guid: actionItem.guid,
        modifiedByUserId: userId,
        owners: resultingOwnerList,
        planGuidToClearItemFrom: userPlanGuid === props.planGuid ? props.planGuid : undefined,
        onSuccess: () => {
          enqueueSnackbar(
            `${
              actionItem.teamPlanSection?.teamName ? actionItem.teamPlanSection?.teamName + " item" : "Card"
            } has been removed from your personal plan.`,
            {
              variant: "success",
            }
          );

          // Close drawer incase it's open.
          closeDrawer();
        },
      });
    }
  }

  const shouldDisplayPropertyIcons =
    actionItem !== undefined &&
    (actionItem.owners.length > 0 ||
      (actionItem.note !== null && !isWhitespace(actionItem.note)) ||
      actionItem.propertiesSummary.supportingItemsCount > 0 ||
      actionItem.propertiesSummary.commentCount > 0);

  const fromAnotherPlan = actionItem?.teamPlanSection != null && actionItem.teamPlanSection.planGuid !== props.planGuid;
  const shouldShowActionToDoChips = actionItem?.team != null && !!matchPath(Paths.UserMap, location.pathname);
  const userIsOwnerOfItem = actionItem != null && actionItem?.owners.some((o) => o.userId === userId);
  const showRemoveOwnershipButton =
    (fromAnotherPlan && userIsOwnerOfItem) || (userIsOwnerOfItem && actionItem.owners.length > 1);

  return (
    <StyledStack
      $isPastView={props.isPastView ?? false}
      direction="row"
      sx={{ alignItems: "center", py: 0, ...props.sx, width: "100%", minWidth: 0 }}
      onClick={() => {
        if (!props.isPastView) {
          handleActionItemClicked();
        }
      }}
    >
      {isLoading || !actionItem ? (
        <Stack sx={{ width: "100%", pl: 1 }}>
          <Skeleton width={"75%"} />
          <Skeleton width={"33%"} />
        </Stack>
      ) : (
        <>
          <Stack spacing={0.2} sx={{ py: 0.25, overflow: "hidden", width: "100%" }}>
            {fromAnotherPlan && !props.isPastView && (
              <Stack direction="row" spacing={0.5} sx={{ pl: 1.75, pt: 0.5 }}>
                <Chip
                  color="primary"
                  component={LinkBehavior}
                  label={actionItem.teamPlanSection!.teamName}
                  href={Links.TeamStrategicMap(actionItem.teamPlanSection!.teamSlug)}
                  onClick={(e: React.MouseEvent) => e.stopPropagation()}
                  clickable
                />
                <Chip label={actionItem.teamPlanSection!.sectionName} />
              </Stack>
            )}

            {shouldShowActionToDoChips && (
              <Stack direction="row" spacing={0.5} sx={{ pl: 1.75, pt: 0.5 }}>
                <Chip
                  color="primary"
                  component={LinkBehavior}
                  label={actionItem.team!.teamName}
                  href={Links.TeamStrategicMap(actionItem.team!.teamSlug)}
                  onClick={(e: React.MouseEvent) => e.stopPropagation()}
                  clickable
                />
                <Chip label={"Actions To Do"} />
              </Stack>
            )}

            <Stack spacing={-0.25} sx={{ overflow: "hidden", width: "100%" }}>
              {actionItem.context && (
                <ContextProperty
                  sx={{ pl: 1.75 }}
                  itemGuid={props.actionItemGuid}
                  itemType={SupportingItemType.ActionItem}
                  context={actionItem.context}
                  condensed
                  isPastView={props.isPastView}
                />
              )}

              <Stack
                direction="row"
                sx={{
                  alignItems: props.isPastView ? "start" : "center",
                  overflow: "hidden",
                  py: props.isPastView ? 0.25 : undefined,
                  width: "100%",
                }}
              >
                <StatusPicker
                  actionItemGuid={actionItem.guid}
                  status={actionItem.currentStatus ?? DEFAULT_ACTION_ITEM_STATUS}
                  planGuid={props.planGuid}
                  teamSlug={actionItem.team?.teamSlug}
                  variant="button"
                  disabled={!props.canEdit}
                  faded={props.isPastView}
                  sx={{ mt: props.isPastView ? "-3px" : undefined }}
                />

                {!props.isPastView ? (
                  <InlineTextField
                    ref={ref}
                    value={actionItem.description}
                    onClick={(e) => props.canEdit && e.stopPropagation()}
                    onBlur={handleActionItemBlurred}
                    placeholder="Enter item..."
                    isEditable={props.canEdit}
                    inputProps={{ maxLength: 512 }}
                  />
                ) : (
                  <Typography sx={{ ml: 0.25 }}>{actionItem.description}</Typography>
                )}
              </Stack>
            </Stack>

            {shouldDisplayPropertyIcons && <ActionItemIcons actionItem={actionItem} sx={{ pl: 1.75, pb: 0.25 }} />}
          </Stack>

          {props.canEditPlan && isMdUp && (
            <IconButton
              className="editMenuIcon"
              size="small"
              onClick={(e) => {
                setMenuAnchor(e.currentTarget);
                e.stopPropagation();
              }}
              sx={{ ml: 0.25 }}
            >
              <MoreVertRounded />
              <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={(e: React.PointerEvent) => {
                  setMenuAnchor(null);
                  e.stopPropagation();
                }}
              >
                {showRemoveOwnershipButton ? (
                  <MenuItem onClick={removeSelfFromOwnership} sx={{ color: theme.palette.error.main }}>
                    Remove Ownership
                  </MenuItem>
                ) : (
                  <MenuItem onClick={handleDeleteClicked} sx={{ color: theme.palette.error.main }}>
                    Delete Card
                  </MenuItem>
                )}
              </Menu>
            </IconButton>
          )}
        </>
      )}
    </StyledStack>
  );
};

export { ActionItem };
