import { Button, Divider, Link, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  ActionItemFeed,
  ActionItemProperties,
  ContextProperty,
  ITeamSection,
  ItemDescriptionField,
  SnackbarContent,
  useDrawer,
} from "..";
import { ActionItemPropertyType, Links, SupportingItemType } from "../../constants";
import { useSession } from "../../hooks";
import { useEditActionItem, useGetActionItemDetail } from "../../http";
import { ITeamSummary } from "../../models";

interface IProps {
  actionItemGuid: string;
  planGuid?: string;
}

const ActionItemDrawerContent = (props: IProps) => {
  const { userId } = useSession();
  const { isLoading, data: actionItem } = useGetActionItemDetail(props.actionItemGuid);
  const { mutate: editActionItem } = useEditActionItem();
  const { closeDrawer } = useDrawer();
  const { enqueueSnackbar } = useSnackbar();

  function handleDescriptionChanged(value: string) {
    if (actionItem === undefined) {
      return;
    }

    editActionItem({
      guid: actionItem.guid,
      description: value,
      modifiedByUserId: userId,
    });
  }

  function handleTeamChanged(selectedTeam: ITeamSummary | null, selectedSection: ITeamSection | null) {
    if (actionItem === undefined) {
      return;
    }

    if (!selectedSection) {
      enqueueSnackbar(<SnackbarContent title="Card removed from team." />, {
        action: (
          <Button href={Links.ActionItemDetail(actionItem.guid)} sx={{ whiteSpace: "nowrap" }}>
            Open Card
          </Button>
        ),
      });
      closeDrawer();
    } else if (
      actionItem.teamPlanSection?.teamSlug !== selectedTeam?.slug ||
      actionItem.team?.teamSlug !== selectedTeam?.slug
    ) {
      enqueueSnackbar(
        <SnackbarContent
          title={
            <>
              Card moved to{" "}
              <Link
                onClick={(e) => e.stopPropagation()}
                href={
                  selectedSection.sectionName === "Actions To Do"
                    ? Links.TeamImplementation(selectedTeam!.slug)
                    : Links.TeamStrategicMap(selectedTeam!.slug)
                }
              >
                {selectedTeam!.name}
              </Link>
              .
            </>
          }
        />,
        {
          action: (
            <Button href={Links.ActionItemDetail(actionItem.guid)} sx={{ whiteSpace: "nowrap" }}>
              Open Card
            </Button>
          ),
        }
      );
      closeDrawer();
    }
  }

  return (
    <>
      <Stack sx={{ px: { xs: 0.5, sm: 1 }, py: 0.5 }}>
        {!isLoading && actionItem && (
          <ContextProperty
            itemGuid={actionItem.guid}
            itemType={SupportingItemType.ActionItem}
            context={actionItem.context}
            isEditable={actionItem.userCanEdit}
          />
        )}

        <ItemDescriptionField
          isLoading={isLoading}
          isEditable={actionItem?.userCanEdit ?? false}
          value={actionItem?.description}
          onChange={handleDescriptionChanged}
          variant={"h5"}
        />
      </Stack>
      <Divider />
      <Stack divider={<Divider flexItem />} sx={{ overflow: "auto" }}>
        <ActionItemProperties
          isLoading={isLoading}
          actionItem={actionItem}
          planGuid={props.planGuid}
          propertyVisibility={{
            [ActionItemPropertyType.Notes]: true,
            [ActionItemPropertyType.Ownership]: true,
            [ActionItemPropertyType.Status]: true,
            [ActionItemPropertyType.SupportingItems]: actionItem?.teamPlanSection != null,
          }}
          onTeamChanged={handleTeamChanged}
        />
        <ActionItemFeed actionItemGuid={props.actionItemGuid} />
      </Stack>
    </>
  );
};

export { ActionItemDrawerContent };
