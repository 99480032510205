import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { CreatePlanDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  slug: string;
  createdByUserId: string;
};

const useCreatePlanRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createPlanRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: CreatePlanDto = {
      createdByUserId: variables.createdByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/teams/${variables.slug}/plan`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { createPlanRequest };
};

const useCreatePlan = () => {
  const { createPlanRequest } = useCreatePlanRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPlanRequest,
    onSuccess: async (_, variables) => {
      await queryClient.refetchQueries({ queryKey: queryKeys.teams.getTeam(variables.slug) });
    },
  });
};

export { useCreatePlan };
