import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { configuration } from "../../../configuration";
import { ActionItemStatus } from "../../../constants";
import {
  EditActionItemStatusDto,
  FetchError,
  GetActionItemDto,
  GetActionItemSummaryDto,
  GetPlanDto,
  GetTeamActionItemsDto,
} from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  statusId: ActionItemStatus;
  userId: string;
  planGuid?: string;
  teamSlug?: string;
};

const useEditActionItemStatusRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editActionItemStatusRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditActionItemStatusDto = {
      modifiedByUserId: variables.userId,
      statusId: variables.statusId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/action-items/${variables.guid}/status`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editActionItemStatusRequest };
};

const useEditActionItemStatus = () => {
  const { editActionItemStatusRequest } = useEditActionItemStatusRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editActionItemStatusRequest,
    onMutate: async (variables) => {
      const cachedSummaryData = await optimisticallyUpdateActionItemSummaryData(queryClient, variables);
      const cachedActionItemDetailsData = await optimisticallyUpdateActionItemDetailsData(queryClient, variables);
      const cachedPlanData = variables.planGuid ? optimisticallyUpdatePlanData(queryClient, variables) : null;
      const cachedTeamData = variables.teamSlug ? optimisticallyUpdateTeamData(queryClient, variables) : null;

      return { cachedSummaryData, cachedActionItemDetailsData, cachedPlanData, cachedTeamData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.actionItems.getActionItemSummary(variables.guid), context?.cachedSummaryData);
      queryClient.setQueryData(
        queryKeys.actionItems.getActionItemDetail(variables.guid),
        context?.cachedActionItemDetailsData
      );

      if (variables.planGuid && context?.cachedPlanData) {
        queryClient.setQueryData(queryKeys.plans.getPlan(variables.planGuid), context?.cachedPlanData);
      }

      if (variables.teamSlug && context?.cachedTeamData) {
        queryClient.setQueryData(queryKeys.teams.getTeamActionItems(variables.teamSlug), context?.cachedTeamData);
      }
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.actionItems.getActionItemSummary(variables.guid));
      queryClient.invalidateQueries(queryKeys.actionItems.getActionItemDetail(variables.guid));
      queryClient.invalidateQueries(queryKeys.actionItems.getActionItemFeed(variables.guid));

      if (variables.planGuid) {
        queryClient.invalidateQueries(queryKeys.plans.getPlan(variables.planGuid));
      }

      if (variables.teamSlug) {
        queryClient.invalidateQueries(queryKeys.teams.getTeamActionItems(variables.teamSlug));
      }
    },
  });
};

async function optimisticallyUpdatePlanData(queryClient: QueryClient, variables: Variables) {
  await queryClient.cancelQueries(queryKeys.plans.getPlan(variables.planGuid!));
  const cachedPlanData = queryClient.getQueryData<GetPlanDto>(queryKeys.plans.getPlan(variables.planGuid!));

  if (cachedPlanData) {
    const optimisticallyUpdatedPlanData: GetPlanDto = {
      ...cachedPlanData,
      blocks: cachedPlanData.blocks.map((block) => ({
        ...block,
        sections: block.sections.map((section) => ({
          ...section,
          actionItems: section.actionItems.map((actionItem) => {
            if (actionItem.guid === variables.guid) {
              return {
                ...actionItem,
                currentStatus: variables.statusId,
                statusLastModifiedDateTimeUtc: DateTime.now().toUTC().toString(),
              };
            }

            return actionItem;
          }),
        })),
      })),
    };

    queryClient.setQueryData(queryKeys.plans.getPlan(variables.planGuid!), optimisticallyUpdatedPlanData);

    return cachedPlanData;
  }
}

async function optimisticallyUpdateTeamData(queryClient: QueryClient, variables: Variables) {
  await queryClient.cancelQueries(queryKeys.teams.getTeamActionItems(variables.teamSlug));
  const cachedData = queryClient.getQueryData<GetTeamActionItemsDto>(
    queryKeys.teams.getTeamActionItems(variables.teamSlug)
  );

  if (cachedData) {
    const optimisticallyUpdatedData: GetTeamActionItemsDto = {
      ...cachedData,
      actionItems: cachedData.actionItems.map((actionItem) => {
        if (actionItem.guid === variables.guid) {
          return {
            ...actionItem,
            currentStatus: variables.statusId,
            statusLastModifiedDateTimeUtc: DateTime.now().toUTC().toString(),
          };
        }

        return actionItem;
      }),
    };

    queryClient.setQueryData(queryKeys.teams.getTeamActionItems(variables.teamSlug), optimisticallyUpdatedData);

    return cachedData;
  }
}

async function optimisticallyUpdateActionItemSummaryData(queryClient: QueryClient, variables: Variables) {
  await queryClient.cancelQueries(queryKeys.actionItems.getActionItemSummary(variables.guid));
  const cachedSummaryData = queryClient.getQueryData<GetActionItemSummaryDto>(
    queryKeys.actionItems.getActionItemSummary(variables.guid)
  );

  if (cachedSummaryData) {
    const optimisticallyUpdatedActionItemSummaryData: GetActionItemSummaryDto = {
      ...cachedSummaryData,
      currentStatus: variables.statusId,
    };

    queryClient.setQueryData(
      queryKeys.actionItems.getActionItemSummary(variables.guid),
      optimisticallyUpdatedActionItemSummaryData
    );

    return cachedSummaryData;
  }
}

async function optimisticallyUpdateActionItemDetailsData(queryClient: QueryClient, variables: Variables) {
  await queryClient.cancelQueries(queryKeys.actionItems.getActionItemDetail(variables.guid));
  const cachedActionItemDetailData = queryClient.getQueryData<GetActionItemDto>(
    queryKeys.actionItems.getActionItemDetail(variables.guid)
  );

  if (cachedActionItemDetailData) {
    const optimisticallyUpdatedActionItemDetailsData: GetActionItemDto = {
      ...cachedActionItemDetailData,
      currentStatus: variables.statusId,
    };

    queryClient.setQueryData(
      queryKeys.actionItems.getActionItemDetail(variables.guid),
      optimisticallyUpdatedActionItemDetailsData
    );

    return cachedActionItemDetailData;
  }
}

export { useEditActionItemStatus };
