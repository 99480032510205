import { Box, Button, IconButton, Link, Skeleton, Stack, SxProps, Tooltip, Typography } from "@mui/material";
import { InlineTextField } from "..";
import {
  ProposalDto,
  useDeleteProposal,
  useEditProposal,
  useRemoveProposalUpvote,
  useUpvoteProposal,
} from "../../http";
import { useSession } from "../../hooks";
import { DeleteOutlineRounded, HowToVote, HowToVoteOutlined } from "@mui/icons-material";
import { Links } from "../../constants";
import { useDestructiveConfirm } from "../../utilities";

interface IProps {
  proposals?: ProposalDto[];
  issueGuid: string;
  isLoading?: boolean;
  sx?: SxProps;
}

const Proposals = (props: IProps) => {
  const user = useSession();
  const confirm = useDestructiveConfirm();

  const { mutate: editProposal } = useEditProposal();
  const { mutate: deleteProposal } = useDeleteProposal();
  const { mutate: upvoteProposal } = useUpvoteProposal();
  const { mutate: removeProposalUpvote } = useRemoveProposalUpvote();

  async function handleDescriptionChanged(proposal: ProposalDto, value: string) {
    editProposal({
      guid: proposal.guid,
      issueGuid: props.issueGuid,
      description: value,
    });
  }

  async function handleDeleteClicked(proposal: ProposalDto) {
    try {
      await confirm({
        title: "Delete Proposal",
        content: "Are you sure you want to delete this proposal?",
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    deleteProposal({
      guid: proposal.guid,
      issueGuid: props.issueGuid,
    });
  }

  async function handleUpvoteClicked(proposal: ProposalDto) {
    if (proposal.upvotes.some((x) => x.userId === user.userId)) {
      removeProposalUpvote({
        guid: proposal.guid,
        issueGuid: props.issueGuid,
        userId: user.userId,
      });
    } else {
      upvoteProposal({
        guid: proposal.guid,
        issueGuid: props.issueGuid,
        user: { userId: user.userId, displayName: user.displayName, enabled: true, profilePhotoSmallUrl: undefined },
      });
    }
  }

  return (
    <Stack spacing={0.5} sx={{ width: "100%", ...props.sx }}>
      {props.isLoading || props.proposals == null ? (
        <Stack>
          {[...Array(3)].map((_, i) => (
            <Skeleton key={i} />
          ))}
        </Stack>
      ) : props.proposals.length === 0 ? (
        <Typography variant="placeholder">No proposals yet.</Typography>
      ) : (
        props.proposals.map((proposal) => {
          return (
            <Stack key={proposal.guid} direction="row" spacing={0.5} sx={{ width: "100%" }} alignItems="baseline">
              <Box minWidth="130px" maxWidth="130px">
                <Tooltip
                  placement="bottom-end"
                  title={
                    proposal.upvotes.length > 0
                      ? proposal.upvotes
                          .sort((a, b) => a.displayName.localeCompare(b.displayName))
                          .map((u, i) => [
                            <Typography key={i} sx={{ display: "inline-block", fontSize: 13, marginRight: "2px" }}>
                              <Link href={Links.User(u.userId)}>{u.displayName}</Link>
                              {i < proposal.upvotes.length - 1 && ", "}
                            </Typography>,
                          ])
                      : null
                  }
                >
                  <Button
                    onClick={(e) => {
                      handleUpvoteClicked(proposal);
                      e.stopPropagation();
                    }}
                    variant="tertiary"
                    sx={{ minWidth: 0, textDecoration: "none !important" }}
                    startIcon={
                      proposal.upvotes.findIndex((x) => x.userId === user.userId) !== -1 ? (
                        <HowToVote />
                      ) : (
                        <HowToVoteOutlined />
                      )
                    }
                  >
                    {`${proposal.upvotes.findIndex((x) => x.userId === user.userId) !== -1 ? "Voted" : "Vote"} (${
                      proposal.upvotes.length
                    })`}
                  </Button>
                </Tooltip>
              </Box>

              <Stack width="100%" spacing={-0.25}>
                <InlineTextField
                  value={proposal.description}
                  isEditable={proposal.createdByUser.userId === user.userId}
                  onBlur={(e) => handleDescriptionChanged(proposal, e.target.value)}
                  placeholder="Enter proposal..."
                  sx={{ flex: 1, alignSelf: "baseline" }}
                />
                <Typography
                  variant="caption"
                  sx={{ pl: "5px" }}
                >{`proposed by ${proposal.createdByUser.displayName}`}</Typography>
              </Stack>

              {proposal.createdByUser.userId === user.userId && (
                <IconButton
                  size="small"
                  sx={{ color: "rgba(0, 0, 0, 0.3)" }}
                  onClick={() => handleDeleteClicked(proposal)}
                >
                  <DeleteOutlineRounded />
                </IconButton>
              )}
            </Stack>
          );
        })
      )}
    </Stack>
  );
};

export { Proposals };
