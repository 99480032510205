import { Select, SelectProps, styled } from "@mui/material";

const StyledSelect = styled(Select)`
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.primary.transparent5};
  transition: ${({ theme }) => theme.transitions.create("background-color")};

  & .MuiSelect-select {
    padding: 3px;
  }

  & .MuiSelect-select span {
    font-size: 0.875rem;
    font-weight: 400;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.transparent15};
  }

  &.Mui-focused {
    background-color: ${({ theme }) => theme.palette.primary.transparent15};
  }
`;

const TertiarySelect = (props: SelectProps) => {
  return <StyledSelect {...props} />;
};

export { TertiarySelect };
