import { useQueryClient } from "@tanstack/react-query";
import { configuration } from "../configuration";
import { queryKeys, useAccessToken } from "../http";
import { ApplicationError, Result } from "../models";

const useTeams = () => {
  const { getAccessToken } = useAccessToken();
  const queryClient = useQueryClient();

  async function followTeam(slug: string, userId: string): Promise<Result<void, ApplicationError>> {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/followers`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
        }),
        method: "post",
      });

      if (response.ok) {
        await queryClient.refetchQueries(queryKeys.teams.getTeams(userId));
        return Result.success(undefined);
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  async function unfollowTeam(slug: string, userId: string): Promise<Result<void, ApplicationError>> {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(`${configuration.apiRootUrl}/teams/${slug}/followers/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "delete",
      });

      if (response.ok) {
        await queryClient.refetchQueries(queryKeys.teams.getTeams(userId));
        return Result.success(undefined);
      } else {
        return Result.error(ApplicationError.fromHttpResponse(response));
      }
    } catch (e: unknown) {
      return Result.error(ApplicationError.fromError(e));
    }
  }

  return {
    followTeam,
    unfollowTeam,
  };
};

export { useTeams };
