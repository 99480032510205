import { useContext } from "react";
import { SignalRClientMethod, SignalRServerMethod } from "../constants";
import { SignalRContext } from "../SignalRContext";
import { SignalRChatContext } from "./SignalRChatContext";

interface IProps {
  children?: React.ReactNode;
}

const SignalRChatProvider = (props: IProps) => {
  const context = useContext(SignalRContext);

  return (
    <SignalRChatContext.Provider
      value={{
        sendChatMessage: (...args) => context.invoke(SignalRServerMethod.SendChatMessage, args),
        onReceiveChatMessage: (handler) => context.registerHandler(SignalRClientMethod.ReceiveChatMessage, handler),
        offReceiveChatMessage: (handler) => context.unregisterHandler(SignalRClientMethod.ReceiveChatMessage, handler),
      }}
    >
      {props.children}
    </SignalRChatContext.Provider>
  );
};

export { SignalRChatProvider };
