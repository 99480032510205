import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { configuration } from "../../../configuration";
import { DEFAULT_ACTION_ITEM_STATUS } from "../../../constants";
import { IUser } from "../../../models";
import { lexorank } from "../../../utilities";
import { ActionItemDto, CreateActionToDoDto, FetchError, GetTeamActionItemsDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  guid: string;
  teamSlug?: string;
  description: string;
  contextActionItemGuid?: string;
  contextActionItemHistoryId?: number;
  note: string;
  owners: IUser[];
  sortOrder?: string;
  createdByDisplayName: string;
};

const useCreateActionToDoRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createActionToDoRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: CreateActionToDoDto = {
      guid: variables.guid,
      teamSlug: variables.teamSlug,
      description: variables.description,
      contextActionItemHistoryId: variables.contextActionItemHistoryId,
      note: variables.note,
      ownerUserIds: variables.owners?.map((x) => x.userId),
      sortOrder: variables.sortOrder,
    };

    const response = await fetch(`${configuration.apiRootUrl}/action-items/to-do`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response;
  }

  return { createActionToDoRequest };
};

const useCreateActionToDo = () => {
  const { createActionToDoRequest } = useCreateActionToDoRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createActionToDoRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.teams.getTeamActionItems(variables.teamSlug));

      const cachedData = queryClient.getQueryData<GetTeamActionItemsDto>(
        queryKeys.teams.getTeamActionItems(variables.teamSlug)
      );

      const newActionItem: ActionItemDto = {
        guid: variables.guid,
        description: variables.description,
        owners: variables.owners,
        currentStatus: DEFAULT_ACTION_ITEM_STATUS,
        statusLastModifiedDateTimeUtc: DateTime.now().toUTC().toString(),
        statusLastModifiedByDisplayName: variables.createdByDisplayName,
        note: variables.note,
        propertiesSummary: { commentCount: 0, supportingItemsCount: 0 },
        sortOrder: variables.sortOrder ?? lexorank.getRankAtIndex(cachedData?.actionItems ?? [], 0),
        userCanEdit: true,
      };

      if (cachedData) {
        const optimisticallyUpdatedData: GetTeamActionItemsDto = {
          ...cachedData,
          actionItems: [newActionItem, ...cachedData.actionItems],
        };

        queryClient.setQueryData(queryKeys.teams.getTeamActionItems(variables.teamSlug), optimisticallyUpdatedData);
        queryClient.setQueryData(queryKeys.actionItems.getActionItemSummary(variables.guid), newActionItem);
      }

      return { cachedData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.teams.getTeamActionItems(variables.teamSlug), context?.cachedData);
      queryClient.removeQueries(queryKeys.actionItems.getActionItemSummary(variables.guid));
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.teams.getTeamActionItems(variables.teamSlug));

      // Update supporting items list
      if (variables.contextActionItemGuid) {
        queryClient.invalidateQueries(queryKeys.actionItems.getActionItemSummary(variables.contextActionItemGuid));
        queryClient.invalidateQueries(queryKeys.actionItems.getActionItemDetail(variables.contextActionItemGuid));
      }
    },
  });
};

export { useCreateActionToDo };
