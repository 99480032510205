const Paths = {
  Default: "/",
  Home: "/",
  Error: "/error",
  NotFound: "/not-found",
  Forbidden: "/forbidden",
  User: "/people/:userId",
  UserMap: "/people/:userId/map",
  UserSettings: "/settings",
  Role: "/roles/:roleGuid",
  Team: "/teams/:slug",
  TeamImplementation: "/teams/:slug/implementation",
  TeamStrategicMap: "/teams/:slug/strategic-map",
  TeamMetrics: "/teams/:slug/metrics",
  TeamResources: "/teams/:slug/resources",
  TeamSettings: "/teams/:slug/settings",
  ActionItemDetails: "action-items/:actionItemGuid",
  IssueDetails: "issues/:issueGuid",
  Meeting: "/meetings/:meetingGuid",
  MetricDetails: "/metrics/:metricGuid",
  SignOut: "/sign-out",
  Search: "/search",
  Chat: "/chat",
  Reports: "/reports",
  OrganizationReport: "/reports/organization-report",
  CoherenceReport: "/reports/coherence-report",
  Surveys: "/surveys",
  Survey: "/surveys/:guid",
  SurveyResponses: "/surveys/:guid/responses",
  SurveyActions: "/surveys/:guid/actions",
  SurveyResponse: "/surveys/:guid/response",
};

export { Paths };
