import { Box, Divider, Skeleton, Stack, SxProps } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { CommentSubject, CommentType } from "../constants";
import { useSession, useSignalR } from "../hooks";
import { queryKeys, useGetUserComments } from "../http";
import { IComment, IUser } from "../models";
import { Comment } from "./Comment";
import { CommentForm } from "./CommentForm";
import { SignalRGroup } from "./signalR";
import { Typography } from "./Typography";

interface IProps {
  subjectType: CommentSubject;
  subjectGuid: string;
  additionalComments?: IComment[];
  autoFocus?: boolean;
  sx?: SxProps;
}

const Comments = (props: IProps) => {
  const { userId } = useSession();
  const { data: userComments, isFetching: isFetchingUserComments } = useGetUserComments(props.subjectGuid);
  const [comments, setComments] = useState<IComment[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const signalR = useSignalR();
  const queryClient = useQueryClient();

  useEffect(() => {
    setIsLoading(true);
  }, [props.subjectGuid]);

  useEffect(() => {
    if (userComments && !isFetchingUserComments) {
      setComments(userComments.concat(props.additionalComments ?? []));
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingUserComments, props.additionalComments]);

  useEffect(() => {
    signalR.joinGroup(SignalRGroup.CommentThread(props.subjectGuid));
    return () => signalR.leaveGroup(SignalRGroup.CommentThread(props.subjectGuid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subjectGuid]);

  useEffect(() => {
    const handleShouldRefetchComments = (threadGuid: string) => {
      queryClient.invalidateQueries(queryKeys.comments.getComments(threadGuid));
    };

    signalR.onShouldRefetchComments(handleShouldRefetchComments);
    return () => signalR.offShouldRefetchComments(handleShouldRefetchComments);
  }, [queryClient, signalR, userId]);

  function handleCommentCreated(commentGuid: string, user: IUser, timestampUtc: DateTime, text: string) {
    setComments([
      {
        commentGuid,
        type: CommentType.Comment,
        user,
        timestampUtc,
        lastModifiedUtc: timestampUtc,
        text,
      },
      ...comments,
    ]);
    signalR.sendRefetchComments(props.subjectGuid);
  }

  function handleCommentEditing(commentGuid: string, lastModifiedUtc: string, text: string) {
    setComments(
      comments.map((comment) => {
        if (comment.commentGuid === commentGuid) {
          return {
            ...comment,
            lastModifiedUtc: DateTime.fromISO(lastModifiedUtc, { zone: "utc" }),
            text,
          };
        }

        return comment;
      })
    );
  }

  function handleCommentEdited() {
    signalR.sendRefetchComments(props.subjectGuid);
  }

  function handleCommentDeleted(commentGuid: string) {
    setComments(comments.filter((x) => x.commentGuid !== commentGuid));
    signalR.sendRefetchComments(props.subjectGuid);
  }

  return (
    <Stack sx={{ overflow: "hidden", ...props.sx }}>
      <CommentForm
        subjectType={props.subjectType}
        subjectGuid={props.subjectGuid}
        disabled={isLoading}
        onCommentCreated={handleCommentCreated}
      />

      <Divider />

      {isLoading && (
        <Stack spacing={1} sx={{ mx: 1, mt: 0.5 }}>
          {[...new Array(3)].map((_, i) => (
            <Stack key={i} direction="row" spacing={1} sx={{ alignItems: "center" }}>
              <Skeleton variant="circular" width={36} height={36} />
              <Box>
                <Skeleton width={100} height={24} />
                <Skeleton width={50} height={24} />
              </Box>
            </Stack>
          ))}
        </Stack>
      )}

      {!isLoading && (
        <Stack sx={{ overflowY: "auto" }}>
          {comments.length === 0 ? (
            <Typography sx={{ p: 1 }}>Nothing here yet.</Typography>
          ) : (
            <>
              {comments
                .sort((a, b) => {
                  // Sort by timestampUtc
                  if (a.timestampUtc < b.timestampUtc) {
                    return 1;
                  } else if (a.timestampUtc > b.timestampUtc) {
                    return -1;
                  }

                  // Then by sortOrder
                  if ((a.sortOrder ?? 0) < (b.sortOrder ?? 0)) {
                    return 1;
                  } else {
                    return -1;
                  }
                })
                .map((comment, i) => (
                  <Comment
                    key={i}
                    comment={comment}
                    onCommentEditing={handleCommentEditing}
                    onCommentEdited={handleCommentEdited}
                    onCommentDeleted={handleCommentDeleted}
                  />
                ))}
            </>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export { Comments };
