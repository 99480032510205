import { useContext } from "react";
import { SignalRContext } from "../components";
import { SignalRActionItemsContext } from "../components/signalR/actionItems";
import { SignalRChatContext } from "../components/signalR/chat";
import { SignalRCommentsContext } from "../components/signalR/comments/SignalRCommentsContext";
import { SignalRIssuesContext } from "../components/signalR/issues";

const useSignalR = () => {
  const signalRContext = useContext(SignalRContext);
  const signalRActionItemsContext = useContext(SignalRActionItemsContext);
  const signalRChatContext = useContext(SignalRChatContext);
  const signalRCommentsContext = useContext(SignalRCommentsContext);
  const signalRIssuesContext = useContext(SignalRIssuesContext);

  return {
    joinGroup: signalRContext.joinGroup,
    joinGroups: signalRContext.joinGroups,
    leaveGroup: signalRContext.leaveGroup,
    leaveGroups: signalRContext.leaveGroups,
    ...signalRActionItemsContext,
    ...signalRChatContext,
    ...signalRCommentsContext,
    ...signalRIssuesContext,
  };
};

export { useSignalR };
