import { Grid, Skeleton, Stack } from "@mui/material";
import { useParams } from "react-router";
import { ViewMetric } from "../../../../components";
import { useGetMetricsForTeam } from "../../../../http";

interface IParams {
  slug: string;
}

const ViewMetricsGrid = () => {
  const { slug } = useParams<keyof IParams>() as IParams;
  const { data, isLoading } = useGetMetricsForTeam(slug);

  return (
    <>
      {isLoading && (
        <Grid container spacing={1} sx={{ p: 1 }}>
          {[...Array(3)].map((_, i) => (
            <Grid item key={i} lg={4} md={6} xs={12}>
              <Stack spacing={1}>
                <Skeleton variant="rectangular" />
                <Skeleton variant="rectangular" height="250px" />
              </Stack>
            </Grid>
          ))}
        </Grid>
      )}

      {!isLoading && data && (
        <Grid container>
          {data.map((metric, index) => (
            <Grid item key={metric.guid} lg={4} md={6} xs={12}>
              <ViewMetric
                key={index}
                metric={metric}
                metricIndex={index}
                sx={{ p: { xs: 0.5, sm: 1 }, height: "100%" }}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export { ViewMetricsGrid };
