import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfirmProvider } from "material-ui-confirm";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Route, Routes as UnstyledRoutes } from "react-router-dom";
import styled from "styled-components";
import { theme } from "./assets/theme";
import { EnvironmentAlert, ErrorBoundary, PrivateRoute, SettingsProvider, SideMenuLayout } from "./components";
import { SnackbarProvider } from "./components/SnackbarProvider";
import { configuration } from "./configuration";
import { Paths } from "./constants";
import { FetchError } from "./http";
import {
  ActionItemDetailsPage,
  ChatPage,
  CoherenceReportPage,
  ForbiddenPage,
  HomePage,
  IssueDetailsPage,
  MeetingPage,
  MetricDetailsPage,
  NotFoundPage,
  OrganizationReportPage,
  ReportsPage,
  RoleDetailsPage,
  SearchPage,
  SignOutPage,
  SurveyActionsPage,
  SurveyPageLayout,
  SurveyPreviewPage,
  SurveyQuestionsPage,
  SurveyResponsesPage,
  SurveysHomePage,
  TeamImplementationPage,
  TeamMetricsPage,
  TeamOverviewPage,
  TeamPageLayout,
  TeamResourcesPage,
  TeamSettingsPage,
  TeamStrategicMapPage,
  UserMapPage,
  UserOverviewPage,
  UserPageLayout,
  UserSettingsPage,
} from "./pages";
import ScrollToTop from "./utilities/scrollToTop";

const Routes = styled(UnstyledRoutes)`
  height: 100%;
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (error instanceof FetchError) {
          return error.status !== 404 && failureCount < 3;
        }

        return failureCount < 3;
      },
      useErrorBoundary: true,
    },
    mutations: {
      useErrorBoundary: true,
    },
  },
});

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import("@tanstack/react-query-devtools/build/lib/index.prod.js").then((d) => ({
    default: d.ReactQueryDevtools,
  }))
);

LicenseInfo.setLicenseKey(
  "374f5ccf066d774dffa00d2c48b9bad4Tz05ODY2OCxFPTE3NTg3Mjg1MzEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg=="
);

const App = () => {
  const [showReactQueryDevtools, setShowReactQueryDevtools] = React.useState(false);
  const isProduction = configuration.environment === "prod";

  React.useEffect(() => {
    // @ts-ignore
    window.toggleReactQueryDevtools = () => setShowReactQueryDevtools((old) => !old);
  }, []);

  console.log(`${process.env.REACT_APP_NAME} is version ${process.env.REACT_APP_VERSION}`);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ErrorBoundary>
            <SnackbarProvider>
              <QueryClientProvider client={queryClient}>
                <SettingsProvider>
                  <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                  {showReactQueryDevtools && (
                    <React.Suspense fallback={null}>
                      <ReactQueryDevtoolsProduction />
                    </React.Suspense>
                  )}
                  <ConfirmProvider
                    defaultOptions={{
                      cancellationButtonProps: {
                        variant: "outlined",
                      },
                      confirmationButtonProps: {
                        variant: "contained",
                      },
                    }}
                  >
                    <Helmet>
                      {/* Google Analytics scripts */}
                      <script
                        async
                        src={`https://www.googletagmanager.com/gtag/js?id=${configuration.googleAnalyticsMeasurementId}`}
                      ></script>
                      <script>
                        {`
                              window.dataLayer = window.dataLayer || [];
                              function gtag(){dataLayer.push(arguments);}
                              gtag('js', new Date());
                              gtag('config', '${configuration.googleAnalyticsMeasurementId}');
                            `}
                      </script>
                    </Helmet>
                    <Routes>
                      <Route path={Paths.SignOut} element={<SignOutPage />} />

                      <Route element={<PrivateRoute />}>
                        <Route element={<SideMenuLayout />}>
                          <Route path={Paths.Home} element={<HomePage />} />
                          <Route path={Paths.Reports} element={<ReportsPage />} />
                          <Route path={Paths.OrganizationReport} element={<OrganizationReportPage />} />
                          <Route path={Paths.CoherenceReport} element={<CoherenceReportPage />} />

                          <Route element={<TeamPageLayout />}>
                            <Route path={Paths.Team} element={<TeamOverviewPage />} />
                            <Route path={Paths.TeamStrategicMap} element={<TeamStrategicMapPage />} />
                            <Route path={Paths.TeamImplementation} element={<TeamImplementationPage />} />
                            <Route path={Paths.TeamMetrics} element={<TeamMetricsPage />} />
                            <Route path={Paths.TeamResources} element={<TeamResourcesPage />} />
                          </Route>
                          <Route path={Paths.TeamSettings} element={<TeamSettingsPage />} />

                          <Route element={<UserPageLayout />}>
                            <Route path={Paths.User} element={<UserOverviewPage />} />
                            <Route path={Paths.UserMap} element={<UserMapPage />} />
                          </Route>
                          <Route path={Paths.UserSettings} element={<UserSettingsPage />} />

                          <Route path={Paths.Role} element={<RoleDetailsPage />} />
                          <Route path={Paths.ActionItemDetails} element={<ActionItemDetailsPage />} />
                          <Route path={Paths.IssueDetails} element={<IssueDetailsPage />} />
                          <Route path={Paths.MetricDetails} element={<MetricDetailsPage />} />
                          <Route path={Paths.Search} element={<SearchPage />} />
                          <Route path={Paths.Chat} element={<ChatPage />} />
                          <Route path={Paths.UserSettings} element={<UserSettingsPage />} />

                          <Route path={Paths.Surveys} element={<SurveysHomePage />} />
                          <Route element={<SurveyPageLayout />}>
                            <Route path={Paths.Survey} element={<SurveyQuestionsPage />} />
                            <Route path={Paths.SurveyResponses} element={<SurveyResponsesPage />} />
                            <Route path={Paths.SurveyActions} element={<SurveyActionsPage />} />
                          </Route>
                        </Route>
                        <Route path={Paths.Forbidden} element={<ForbiddenPage />} />
                        <Route path={Paths.Meeting} element={<MeetingPage />} />
                        <Route path={Paths.SurveyResponse} element={<SurveyPreviewPage />} />
                      </Route>
                      <Route path="*" element={<NotFoundPage />} />
                    </Routes>

                    {!isProduction && <EnvironmentAlert environment={configuration.environment} />}
                  </ConfirmProvider>
                </SettingsProvider>
              </QueryClientProvider>
            </SnackbarProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
};

export { App };
