import { useTheme } from "@mui/material";
import { ConfirmOptions, useConfirm } from "material-ui-confirm";

const useDestructiveConfirm = () => {
  const confirm = useConfirm();
  const theme = useTheme();

  function destructiveConfirm(options?: ConfirmOptions) {
    return confirm({
      ...options,
      dialogActionsProps: {
        sx: {
          justifyContent: "space-between",
        },
      },
      titleProps: {
        sx: {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.common.white,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
      },
      confirmationButtonProps: {
        color: "error",
      },
    });
  }

  return destructiveConfirm;
};

export { useDestructiveConfirm };
