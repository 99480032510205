import { DeleteOutlineRounded, EditOutlined } from "@mui/icons-material";
import { Box, Button, Chip, Divider, Skeleton, Stack } from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Comments, EditMetricModal, HistoryLedgerMapper, LineGraph, LinkBehavior, Typography } from "../../components";
import { CommentSubject, ConnectObjectType, Links } from "../../constants";
import { useBreakpoints, useSession } from "../../hooks";
import { useDeleteMetric, useGetMetric } from "../../http";
import { MetricMapper } from "../../mapping";
import { setTitlePrefix, useDestructiveConfirm } from "../../utilities";
import { DetailPageLayout } from "./DetailPageLayout";
import { MetricHistoryLedgerMappingStrategy } from "./components";

interface UrlParams {
  metricGuid: string;
}

const MetricDetailsPage = () => {
  const { userId } = useSession();
  const { metricGuid } = useParams<keyof UrlParams>() as UrlParams;
  const { data: metric, isLoading } = useGetMetric(metricGuid);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { mutate: deleteMetric } = useDeleteMetric();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const confirm = useDestructiveConfirm();
  const { isSmUp } = useBreakpoints();

  useEffect(() => {
    setTitlePrefix(metric?.title);
  }, [metric?.title]);

  async function handleDeleteClicked() {
    if (metric === undefined) {
      return;
    }

    try {
      await confirm({
        title: `Delete ${ConnectObjectType.Metric}`,
        content: `Are you sure you want to delete this ${ConnectObjectType.Metric}?`,
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    deleteMetric(
      {
        teamSlug: metric.teamSlug,
        guid: metric.guid,
        modifiedByUserId: userId!,
      },
      {
        onSuccess: () => {
          if (metric !== undefined) {
            navigate(Links.TeamMetrics(metric.teamSlug));
            enqueueSnackbar(`Metric has been deleted`);
          }
        },
      }
    );
  }

  return (
    <DetailPageLayout
      actions={
        <Stack direction="row" spacing={{ xs: 0.5, sm: 1 }}>
          <Button
            variant="outlined"
            startIcon={<EditOutlined />}
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            Edit
          </Button>
          <Button variant="outlined" color="error" startIcon={<DeleteOutlineRounded />} onClick={handleDeleteClicked}>
            Delete
          </Button>
        </Stack>
      }
      faded={metric?.archived}
    >
      {isLoading && (
        <Stack>
          <Skeleton sx={{ mx: { xs: 0.5, sm: 1 }, my: 0.5 }} width={"10%"} />
          <Divider />
          <Skeleton sx={{ mx: { xs: 0.5, sm: 1 } }} height={96} />
        </Stack>
      )}

      {!isLoading && metric && (
        <>
          <Stack spacing={{ xs: 0.5, sm: 1 }} sx={{ mt: { xs: 0.5, sm: 1 } }}>
            <Box
              sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", px: { xs: 0.5, sm: 1 } }}
            >
              <Stack direction="row" gap={0.5} flexWrap="wrap">
                <Chip
                  color="primary"
                  label={metric.teamName}
                  component={LinkBehavior}
                  href={Links.TeamMetrics(metric.teamSlug)}
                  clickable
                />
                <Chip label={ConnectObjectType.Metric} />
              </Stack>
            </Box>
            <Divider />
            <Stack spacing={0.5} sx={{ px: { xs: 0.5, sm: 1 }, minHeight: 300, justifyContent: "space-between" }}>
              <Typography variant={isSmUp ? "h3" : "h4"} faded={metric.archived}>
                {metric.title}
              </Typography>

              {metric.description && <Typography faded={metric.archived}>{metric.description}</Typography>}
              {metric.datasets.length > 0 && (
                <>
                  <LineGraph
                    datasets={metric.datasets.map((data) => {
                      return {
                        title: data.title,
                        color: data.color,
                        dotted: data.dotted,
                        data: MetricMapper.toChartData(data),
                      };
                    })}
                    showXAxis
                    showYAxis
                    sx={{ height: { xs: "60vw", sm: "250px" } }}
                  />
                  {metric.dataLastModifiedDateTimeUtc && metric.dataLastModifiedByDisplayName && (
                    <Box>
                      <Typography variant="caption">{`Updated by ${
                        metric.dataLastModifiedByDisplayName
                      } on ${metric.dataLastModifiedDateTimeUtc
                        .toLocal()
                        .toLocaleString(DateTime.DATE_FULL)}`}</Typography>
                    </Box>
                  )}
                </>
              )}
            </Stack>
            <Box>
              <Divider />
              <Comments
                subjectType={CommentSubject.PlanMetric}
                subjectGuid={metricGuid}
                additionalComments={HistoryLedgerMapper.toComments(
                  metric.historyLedger,
                  new MetricHistoryLedgerMappingStrategy()
                )}
              />
            </Box>
          </Stack>
          <EditMetricModal
            isVisible={isModalVisible}
            metric={metric}
            onUpdated={() => setIsModalVisible(false)}
            onCancelled={() => setIsModalVisible(false)}
          />
        </>
      )}
    </DetailPageLayout>
  );
};

export { MetricDetailsPage };
