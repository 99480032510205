import { Link, Typography } from "@mui/material";
import { Links, SystemStatus } from "../../constants";
import { FeedItemDto } from "../../http";

interface IProps {
  feedItem: FeedItemDto;
}

const ProposalPropertyChange = (props: IProps) => {
  if (props.feedItem.propertyChange!.changeType === SystemStatus.Created) {
    return (
      <Typography variant="caption">
        <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
        <span> created proposal </span>
        <strong>{props.feedItem.propertyChange!.value}</strong>
      </Typography>
    );
  }

  if (props.feedItem.propertyChange!.changeType === SystemStatus.Deleted) {
    return (
      <Typography variant="caption">
        <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
        <span> deleted the proposal </span>
        <strong>{props.feedItem.propertyChange!.value}</strong>
      </Typography>
    );
  }

  return (
    <Typography variant="caption">
      <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
      <span> updated proposal to </span>
      <strong>{props.feedItem.propertyChange!.value}</strong>
    </Typography>
  );
};

export { ProposalPropertyChange };
