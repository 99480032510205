import { AddRounded, ExpandMoreRounded, SettingsRounded } from "@mui/icons-material";
import { Button, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import {
  CreateActionToDoModal,
  CreateIssueModal,
  CreateMetricModal,
  CreateRoleModal,
  CreateTeamModal,
} from "../../../../components";
import { Links } from "../../../../constants";
import { useBreakpoints, useSession } from "../../../../hooks";
import { useCreatePlan } from "../../../../http";
import { CreateResourceModal } from "../../TeamResourcesPage/components";
import { useTeamPageLayoutContext } from "../TeamPageLayout";
import { Followers } from "./Followers";

const TeamActions = () => {
  const { team } = useTeamPageLayoutContext();
  const { userId } = useSession();
  const { mutate: createPlan, isLoading: isCreatingPlan } = useCreatePlan();
  const { isMdUp } = useBreakpoints();
  const navigate = useNavigate();

  const [addMenuAnchor, setAddMenuAnchor] = useState<HTMLElement | null>(null);
  const [isTeamModalVisible, setIsTeamModalVisible] = useState(false);
  const [isRoleModalVisible, setIsRoleModalVisible] = useState(false);
  const [isMetricModalVisible, setIsMetricModalVisible] = useState(false);
  const [isResourceModalVisible, setIsResourceModalVisible] = useState(false);
  const [isIssueModalVisible, setIsIssueModalVisible] = useState(false);
  const [isActionModalVisible, setIsActionModalVisible] = useState(false);

  function handleIssueClicked() {
    setAddMenuAnchor(null);
    setIsIssueModalVisible(true);
  }

  function handleActionClicked() {
    setAddMenuAnchor(null);
    setIsActionModalVisible(true);
  }

  function handleResourceClicked() {
    setAddMenuAnchor(null);
    setIsResourceModalVisible(true);
  }

  function handleRoleClicked() {
    setAddMenuAnchor(null);
    setIsRoleModalVisible(true);
  }

  async function handlePlanClicked() {
    if (team === undefined) {
      throw new Error("Team is undefined");
    }

    setAddMenuAnchor(null);

    createPlan({
      slug: team.slug,
      createdByUserId: userId,
    });
  }

  function handleTeamClicked() {
    setAddMenuAnchor(null);
    setIsTeamModalVisible(true);
  }

  function handleMetricClicked() {
    setAddMenuAnchor(null);
    setIsMetricModalVisible(true);
  }

  function handleRoleAdded(guid: string) {
    setIsRoleModalVisible(false);
    navigate(Links.RoleDetail(guid));
  }

  function handleTeamCreated(slug: string) {
    setIsTeamModalVisible(false);
    navigate(Links.Team(slug));
  }

  if (team === undefined) {
    return null;
  }

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        <Followers />
        {(team.isMember || team.isAdministrator) && (
          <Stack direction="row" spacing={isMdUp ? 1 : 0.5} sx={{ alignItems: "center" }}>
            {isMdUp ? (
              <Button
                disabled={isCreatingPlan}
                variant="contained"
                onClick={(e) => setAddMenuAnchor(e.currentTarget)}
                endIcon={<ExpandMoreRounded />}
              >
                Add
              </Button>
            ) : (
              <IconButton
                variant="filled"
                size="small"
                color="primary"
                onClick={(e) => setAddMenuAnchor(e.currentTarget)}
              >
                <AddRounded />
              </IconButton>
            )}

            {team.isAdministrator &&
              (isMdUp ? (
                <Button
                  startIcon={<SettingsRounded />}
                  variant="outlined"
                  onClick={(e) => navigate(Links.TeamSettings(team.slug))}
                >
                  Settings
                </Button>
              ) : (
                <IconButton onClick={(e) => navigate(Links.TeamSettings(team.slug))}>
                  <SettingsRounded />
                </IconButton>
              ))}
          </Stack>
        )}
      </Stack>

      {(team.isMember || team.isAdministrator) && (
        <Menu
          anchorEl={addMenuAnchor}
          open={Boolean(addMenuAnchor)}
          onClose={() => setAddMenuAnchor(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem onClick={handleIssueClicked}>Issue</MenuItem>
          <MenuItem divider onClick={handleActionClicked}>
            Action
          </MenuItem>
          <MenuItem onClick={handleTeamClicked}>Team</MenuItem>
          <MenuItem onClick={handleRoleClicked}>Role</MenuItem>
          <MenuItem disabled={team.planGuid !== null} onClick={handlePlanClicked}>
            Strategic Map
          </MenuItem>
          <MenuItem onClick={handleMetricClicked}>Metric</MenuItem>
          <MenuItem onClick={handleResourceClicked}>Resource</MenuItem>
        </Menu>
      )}

      <CreateTeamModal
        visible={isTeamModalVisible}
        parentTeam={team}
        onCreated={handleTeamCreated}
        onCancel={() => setIsTeamModalVisible(false)}
      />

      <CreateRoleModal
        team={team}
        visible={isRoleModalVisible}
        onAdded={handleRoleAdded}
        onCancelled={() => setIsRoleModalVisible(false)}
      />

      <CreateResourceModal
        visible={isResourceModalVisible}
        onAdded={() => setIsResourceModalVisible(false)}
        onCancelled={() => setIsResourceModalVisible(false)}
      />

      <CreateMetricModal
        visible={isMetricModalVisible}
        onAdded={() => setIsMetricModalVisible(false)}
        onCancelled={() => setIsMetricModalVisible(false)}
      />

      <CreateIssueModal
        visible={isIssueModalVisible}
        onAdded={() => setIsIssueModalVisible(false)}
        onCancelled={() => setIsIssueModalVisible(false)}
      />

      <CreateActionToDoModal
        visible={isActionModalVisible}
        onAdded={() => setIsActionModalVisible(false)}
        onCancelled={() => setIsActionModalVisible(false)}
      />
    </>
  );
};

export { TeamActions };
