import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import React, { useEffect, useRef } from "react";
import { configuration } from "../../configuration";
import { SignalRActionItemsProvider } from "./actionItems";
import { SignalRChatProvider } from "./chat";
import { SignalRCommentsProvider } from "./comments";
import { SignalRServerMethod } from "./constants";
import { SignalRIssuesProvider } from "./issues";
import { SignalRContext } from "./SignalRContext";

interface IProps {
  children?: React.ReactNode;
}

const SignalRProvider = (props: IProps) => {
  const connectionRef = useRef<HubConnection>();
  const groupsRef = useRef<string[]>([]);

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(`${configuration.apiRootUrl}/hub`, {})
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Debug)
      .build();

    connectionRef.current = connection;
    start(connection);

    connection.onreconnected(handleOnReconnected);

    connection.onclose((e) => {
      console.error(`SignalR connection closed due to "${e}".`);
    });

    return () => {
      connection.stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function start(connection: HubConnection) {
    // Automatic reconnects don't retry initial start failures, so we need to handle it manually.
    try {
      await connection.start();
      console.log("SignalR connected.");
    } catch (e) {
      console.log(e);
      setTimeout(() => start(connection), 5000);
    }
  }

  function handleOnReconnected() {
    // We need to manually rejoin whatever groups we were in prior to being disconnected.
    console.log("SignalR reconnected.");
    groupsRef.current.forEach((groupName) => {
      invoke(SignalRServerMethod.JoinGroup, [groupName]);
    });
  }

  async function invoke(methodName: string, args: any[]) {
    if (connectionRef.current?.state === HubConnectionState.Connecting) {
      setTimeout(() => {
        invoke(methodName, args);
      }, 5000);
    } else {
      connectionRef.current?.invoke(methodName, ...args);
    }
  }

  function registerHandler(methodName: string, handler: (...args: any[]) => any) {
    connectionRef.current?.on(methodName, handler);
  }

  function unregisterHandler(methodName: string, handler: (...args: any[]) => any) {
    connectionRef.current?.off(methodName, handler);
  }

  function joinGroup(groupName: string) {
    invoke(SignalRServerMethod.JoinGroup, [groupName]);
    groupsRef.current = [...groupsRef.current, groupName];
  }

  function joinGroups(groupNames: string[]) {
    invoke(SignalRServerMethod.JoinGroups, [groupNames]);
    groupsRef.current = [...groupsRef.current, ...groupNames];
  }

  function leaveGroup(groupName: string) {
    invoke(SignalRServerMethod.LeaveGroup, [groupName]);
    groupsRef.current = [...groupsRef.current.filter((oldGroupName) => oldGroupName !== groupName)];
  }

  function leaveGroups(groupNamesToLeave: string[]) {
    invoke(SignalRServerMethod.LeaveGroups, [groupNamesToLeave]);
    groupsRef.current = [
      ...groupsRef.current.filter(
        (currentGroupName) => !groupNamesToLeave.some((groupNameToLeave) => groupNameToLeave === currentGroupName)
      ),
    ];
  }

  return (
    <SignalRContext.Provider
      value={{
        connection: connectionRef.current,
        invoke,
        registerHandler,
        unregisterHandler,
        joinGroup,
        joinGroups,
        leaveGroup,
        leaveGroups,
      }}
    >
      <SignalRActionItemsProvider>
        <SignalRChatProvider>
          <SignalRCommentsProvider>
            <SignalRIssuesProvider>{props.children}</SignalRIssuesProvider>
          </SignalRCommentsProvider>
        </SignalRChatProvider>
      </SignalRActionItemsProvider>
    </SignalRContext.Provider>
  );
};

export { SignalRProvider };
