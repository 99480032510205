import { Skeleton } from "@mui/material";
import { ViewList } from "../../../../components";
import { useGetRolesForUser } from "../../../../http";
import { IUserRole } from "../../../../models";
import { RoleItem } from "./RoleItem";

interface IProps {
  userId: string;
  faded?: boolean;
}

const RoleList = (props: IProps) => {
  const { data: roles, isLoading } = useGetRolesForUser(props.userId);

  return (
    <ViewList
      dataSource={roles?.sort(
        (a, b) => (a.isPrimaryRole ? -1 : b.isPrimaryRole ? 1 : 0) || a.name.localeCompare(b.name)
      )}
      isLoading={isLoading}
      renderItem={(item: IUserRole, index) => (
        <RoleItem role={item} key={index} faded={props.faded} sx={{ py: { xs: 0.5, sm: 1 } }} />
      )}
      renderSkeleton={(index) => <Skeleton key={index} variant="rectangular" sx={{ m: { xs: 0.5, sm: 1 } }} />}
    />
  );
};

export { RoleList };
