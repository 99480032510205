import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { IPlan } from "../../../models";
import { DeleteSectionDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  planGuid: string;
  guid: string;
  modifiedByUserId: string;
};

const useDeleteSectionRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function deleteSectionRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: DeleteSectionDto = {
      modifiedByUserId: variables.modifiedByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/sections/${variables.guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "delete",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { deleteSectionRequest };
};

const useDeleteSection = () => {
  const { deleteSectionRequest } = useDeleteSectionRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteSectionRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.plans.getPlan(variables.planGuid));

      // Snapshot the current cache value
      const cachedData = queryClient.getQueryData<IPlan>(queryKeys.plans.getPlan(variables.planGuid));

      if (cachedData) {
        // Optimistically update the cache
        const optimisticallyUpdatedData: IPlan = {
          ...cachedData,
          blocks: cachedData.blocks.map((block) => ({
            ...block,
            sections: block.sections.filter((value) => value.guid !== variables.guid),
          })),
        };

        queryClient.setQueryData(queryKeys.plans.getPlan(variables.planGuid), optimisticallyUpdatedData);
      }

      return { cachedData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.plans.getPlan(variables.planGuid), context?.cachedData);
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.plans.getPlan(variables.planGuid));
    },
  });
};

export { useDeleteSection };
