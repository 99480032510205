import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { CommentSubject } from "../../constants";
import { useSignalR } from "../../hooks";
import { queryKeys, useGetIssueFeed } from "../../http";
import { Feed } from "./Feed";

interface IProps {
  issueGuid: string;
}

const IssueFeed = (props: IProps) => {
  const { isLoading, data } = useGetIssueFeed(props.issueGuid);
  const queryClient = useQueryClient();
  const signalR = useSignalR();

  useEffect(() => {
    const handleShouldRefetchComments = (issueGuid: string) => {
      queryClient.invalidateQueries(queryKeys.issues.getIssueFeed(issueGuid));
    };

    signalR.onShouldRefetchComments(handleShouldRefetchComments);
    return () => signalR.offShouldRefetchComments(handleShouldRefetchComments);
  }, [props.issueGuid, queryClient, signalR]);

  return (
    <Feed
      subjectType={CommentSubject.Issue}
      subjectGuid={props.issueGuid}
      isLoading={isLoading}
      items={data?.feed ?? []}
    />
  );
};

export { IssueFeed };
